import * as React from "react";
import { Router, Switch, Route, Link } from "react-router-dom";

import "./App.scss";

import { ArrowUp } from "./items";
import { ReactSVG } from "react-svg";
import { createBrowserHistory } from "history";
import { Provider } from "../store/perfil";
import Index from "../pages";
import CallMenu from "./callMenu";
import Login from "./login";
import Notificaciones from "./Notificaciones";
import PermisosApp from "./permisos.json";
import CuentaCorriente from "../pages/cuentacorriente";
import Suscripciones from "../pages/suscripciones";
import Devoluciones from "../pages/devoluciones";
import Pedidos from "../pages/pedidos";
import PedidosTienda from "../pages/pedidostienda";
import PedidosCargasDevoluciones from "../pages/pedidoscargasdevoluciones";
import RegistrarVenta from "../pages/registrarventa";
import AbrirReclamo from "../pages/abrirReclamo";
import EdicionNovedades from "../pages/edicionNovedades";
import CanjePorPunto from "../pages/canjeporpunto";
import Canje from "../pages/canje";

import brandLogo from "../assets/brand-logo.png";
import brandLogoMenu from "../assets/brand-logo-menu.png";
import cuentacorriente from "../assets/cuentacorriente.svg";
import devoluciones from "../assets/devoluciones.svg";
import distribucion from "../assets/distribucion.svg";
import registrarventa from "../assets/registrarventa.svg";
import suscripciones from "../assets/suscripciones.svg";
import pedidos from "../assets/pedidos.svg";
import tienda from "../assets/tienda.svg";
import urlServer from "../server";
import novedades from "../assets/novedades.svg";
import clubparadaonline from "../assets/clubParadaOnline.svg";
import CanjePuntoHistorial from "./CanjePuntoHistorial";
import { validarVencimientoToken } from "../localStorageManager";

// TRANSFORMA EL LINK EN TÍTULO(en caso de que cambie el nombre del link, cambia el nombre del título)
let textualizeLink = (item) => {
  let tempArray = item.split("");
  let i = 0;
  while (i < tempArray.length) {
    if (tempArray[i] === tempArray[i].toUpperCase() && i !== 0) {
      tempArray.splice(i, 0, " ");
      i++;
    }
    i++;
  }
  let itemTitle = tempArray.join("");
  itemTitle = itemTitle.replace(" De ", " de ");
  return itemTitle;
};

// TRANSFORMA ARRAY DE LINKS EN ARRAY DE OBJETOS CON LINK Y TÍTULO

export default class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenMenu: true,
      hiddenPerfil: true,
      hiddenNotificaciones: true,
      estaEnPerfil: false,
      links: [],
      submenuCategoria: [],
      menuLinkFlag: false,
      sublinks: [],
      path: "",
      perfil: {},
      assets: {
        cuentacorriente,
        devoluciones,
        distribucion,
        suscripciones,
        pedidos,
        registrarventa,
        tienda,
        novedades,
        clubparadaonline,
      },
      incentivoHabilitado: false,
    };
  }

  setStateEstaEnPerfil = () => {
    this.setState({ estaEnPerfil: true });
  };

  setStateNoEstaEnPerfil = () => {
    this.setState({ estaEnPerfil: false });
  };

  obtenerNotificaciones() {
    let data = {
      usuarioId: null,
      notificacionId: null,
    };
    fetch(urlServer + "/api/notificacion/buscar", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((result) => {
        this.setState({
          notificaciones: result,
        });
      })
      .catch((error) => {
        validarVencimientoToken(error.message);
        console.log(error);
      });
  }

  static notifPrivates = [
    {
      message: "Dada de alta",
      seen: false,
      category: "Suscripciones",
    },
    {
      message: "El pedido fue despachado",
      seen: false,
      category: "Pedidos",
    },
    {
      message: "Dada de alta",
      seen: false,
      category: "Suscripciones",
    },
    {
      message: "El pedido fue despachado",
      seen: true,
      category: "Pedidos",
    },
    {
      message: "El pedido fue despachado",
      seen: true,
      category: "Pedidos",
    },
    {
      message: "El pedido fue despachado",
      seen: true,
      category: "Pedidos",
    },
    {
      message: "Dada de alta",
      seen: true,
      category: "Suscripciones",
    },
  ];

  getLinkAndName = (data, Links) => {
    let links = [];
    let sublinks = {};
    let menuCollapse = false;
    for (let index = 0; index < data.length; index++) {
      const link = data[index];
        
      let b = link.replace("KIOSCO.", "");
      if (b.indexOf(".") === -1) {
        //titulo principal
          let itemLink = "/" + b;
        let itemName = textualizeLink(
          b === "Pedidos"
            ? "Pedidos a Distribuidoras"
            : b === "Tienda"
                ? "Pedidos a Tienda"
                : b === "ClubParadaOnline"
                    ? "Club Parada Online"           
                    : b === "Distribucion"
                      ? "Devoluciones"           
                      : b
        );

        let item = {
          link: itemLink,
          name: itemName,
        };
        links = [...links, item];
      } else {
        //subtitulos
        let path = b.slice(0, b.indexOf("."));
        let item = b.slice(b.indexOf(".") + 1, b.length);

        let itemLink = "/" + path + "/" + item;
          let itemTitle = textualizeLink(item);
        itemTitle =
          itemTitle === "Alta Suscripciones"
            ? "Solicitar Suscripcion"
            : itemTitle === "Entregas Devoluciones"
              ? "Entregas y Devoluciones"
              : itemTitle;
        if (itemTitle === "Productos") {
          menuCollapse = true;
        }

        let completeLink = {
          link: itemLink,
          name: itemTitle,
          collapse: menuCollapse,
          items: "",
        };
        menuCollapse = false;
        if (!sublinks[path]) {
          sublinks[path] = [];
        }
        if (item.indexOf(".") === -1) {
          sublinks[path] = [...sublinks[path], completeLink];
        } else {
          let subpath = item.slice(0, item.indexOf("."));
          let subitem = item.slice(item.indexOf(".") + 1, item.length);
          let sublink = "/" + subpath + "/" + subitem;
          let subname = textualizeLink(subitem);

          let finalItem = {
            link: sublink,
            name: subname,
            collapse: menuCollapse,
          };
          if (!sublinks[subpath]) {
            sublinks[subpath] = [];
          }
          sublinks[subpath] = [...sublinks[subpath], finalItem];
        }
      }
    }

    /*actualizamos el link para que canje este ultimo */
    var canjeIndex = null;

    for (var i = 0; i < links.length; i++) {
      if (
        links !== undefined &&
        links[i] !== undefined &&
        links[i].link === "/ClubParadaOnline"
      ) {
        canjeIndex = i;
      }
    }

    if (canjeIndex !== null) {
      links[links.length] = links[canjeIndex];
      //si tiene permiso de tienda y canje por puntos -->agrego canje por punto como un submenu de tienda
      if (sublinks.Tienda !== null) {
        sublinks.Tienda.push({
          link: links[canjeIndex].link,
          name: links[canjeIndex].name,
          items: "",
          collapse: false,
        });
      }
      if (sublinks.ClubParadaOnline !== null) {
        sublinks.ClubParadaOnline.push({
          link: links[canjeIndex].link,
          name: links[canjeIndex].name,
          items: "",
          collapse: false,
        });
      }
      delete links[canjeIndex];
    }

    /*if (!this.state.incentivoHabilitado) {
      // quitar el modulo de canje
      let index = links.findIndex((e) =>  e?.link === "/ClubParadaOnline");
      if (index != -1) links.splice(index, 1);
    }*/

    
    links = links.map((x) => {
      if(x.link === '/Distribucion')
        {
          x.link = '/Distribucion/Devoluciones'
        }
        return x;
    })

    this.setState({
      links: links,
      sublinks: sublinks,
      perfil: JSON.parse(localStorage.infoToken),
      path: window.location.pathname,
      divnotif:
        typeof Links !== "undefined" &&
        Links.filter((e) => e.indexOf("Notificaciones") !== -1).length !== 0
          ? true
          : false,
      shortpath:
        window.location.pathname.indexOf("/", 1) !== -1
          ? window.location.pathname.slice(
              0,
              window.location.pathname.indexOf("/", 1)
            )
          : window.location.pathname,
    });
  };

  // RENDERIZA LA CAMPANA DE NOTIFICACIONES, CON LINK A ESA PÁGINA
  bell = (campananotif) => {
    if (campananotif) {
      return (
        <Link to="/Notificaciones">
          <div
            className={
              "bell" +
              (this.state.path === "/Notificaciones" ? " primarycolor" : "")
            }
            title="Notificaciones"
          >
            
          </div>
        </Link>
      );
    } else {
      return null;
    }
  };

  history = createBrowserHistory();

  // OCULTA O MUESTRA EL MENÚ LATERAL: TRUE OCULTA, FALSE MUESTRA, 'alt' ALTERNA
  hideMenu = (hide = "alt") => {
    if (hide === "alt") {
      const menu = document.getElementById("menu");
      if (menu) {
        menu.style.left = "";
      }
      this.setState({
        hiddenMenu: !this.state.hiddenMenu,
      });
    } else if (hide) {
      this.setState({
        hiddenMenu: true,
      });
    } else if (!hide) {
      this.setState({
        hiddenMenu: false,
      });
    }
    return;
  };

  // OCULTA O MUESTRA EL PERFIL
  hidePerfil = (hide = "alt") => {
    if (hide === "alt") {
      this.setState({
        hiddenPerfil: !this.state.hiddenPerfil,
      });
    }
    return;
  };

  // OCULTA O MUESTRA LAS NOTIFICACIONES
  hideNotificaciones = (hide = "alt") => {
    if (hide === "alt") {
      this.setState({
        hiddenNotificaciones: !this.state.hiddenNotificaciones,
      });
    }
    return;
  };

  // RENDERIZA LOGO INSTITUCIONAL CON LINK A LA HOME
  brandlink = () => {
    return (
      <Link to="/">
        <img
          className="brand-logo navbar-logo"
          src={brandLogo}
          alt="brand"
        ></img>
      </Link>
    );
  };

  // RENDERIZA LOGO INSTITUCIONAL CON LINK A LA HOME
  brandlinkMenu = () => {
    return (
      <Link
        to="/"
        onClick={() => {
          this.removeFlagVolverDeCanje();
          this.restaurarMenuLateral();
        }}
      >
        <img
          className="brand-logo navbar-logo"
          src={brandLogoMenu}
          alt="brand"
        ></img>
      </Link>
    );
  };

  removeFlagVolverDeCanje = () => {
    localStorage.removeItem("flagVolverDeCanje");
  };

  // Se usa par restaurar el icono del menu lateral al salir de editar pedido
  restaurarMenuLateral = () => {
    var menu = document.getElementById("call-menu");
    if (menu) {
      menu.style.display = "block";
    }
  };

  //OBTIENE LINKS DE LO RECUPERADO DEL TOKEN
  getLinks = () => {
    // Se usa par restaurar el icono del menu lateral al salir de editar pedido
    var menu = document.getElementById("call-menu");
    if (menu) {
      menu.style.display = "block";
    }

    const { links, shortpath } = this.state;
    const { menuLinkFlag } = this.state;
    let hidden = [
      <div
        key={0}
        className="icon"
        style={{ cursor: "pointer", color: menuLinkFlag ? "#EA3F3F" : "" }}
        onClick={() =>
          this.setState({ menuLinkFlag: !this.state.menuLinkFlag })
        }
      >
        <i className="fas fa-ellipsis-v"></i>
      </div>,
    ];
    if (links.length > 5) {
      hidden = [
        ...hidden,
        <div
          key={1}
          className="menu-dots"
          style={{ display: menuLinkFlag ? "block" : "none" }}
        >
          {links.map((link, index) => {
            if (index >= 4) {
              return (
                <Link
                  className="d-flex"
                  style={{ height: "40px" }}
                  key={index}
                  onClick={() => {
                    this.hideMenu(true);
                    this.setState({ menuLinkFlag: false });
                  }}
                  to={link.link}
                >
                  <ReactSVG
                    className={
                      "icon " + (shortpath === link.link ? "primarycolor" : "")
                    }
                    style={{ margin: "auto 15px" }}
                    src={
                      this.state.assets[
                        link.link.replace("/", "").toLowerCase()
                      ]
                    }
                  />
                  <div style={{ cursor: "pointer", margin: "auto 0" }}>
                    {link.name}
                  </div>
                </Link>
              );
            }
            return "";
          })}
        </div>,
      ];
    }
    let menuLinks = links.map((link, index) => {
      if (links.length <= 5) {
        return (
          <Link
            key={index}
            onClick={() => {
              this.hideMenu(true);
              this.setState({ menuLinkFlag: false });
            }}
            to={link.link}
          >
            <ReactSVG
              className={
                "icon " + (shortpath === link.link ? "primarycolor" : "")
              }
              src={this.state.assets[link.link.replace("/", "").toLowerCase()]}
            />
          </Link>
        );
      } else {
        if (index < 4) {
          return (
            <Link
              key={index}
              onClick={() => {
                this.hideMenu(true);
                this.setState({ menuLinkFlag: false });
              }}
              to={link.link}
            >
              <ReactSVG
                className={
                  "icon " + (shortpath === link.link ? "primarycolor" : "")
                }
                src={
                  this.state.assets[
                    link.name === "Devoluciones" ? "distribucion" : link.link.replace("/", "").toLowerCase()
                  ]
                }
              />
            </Link>
          );
        } else if (index === 4) {
          return (
            <div key={index} id="hidden-links">
              {" "}
              {hidden}{" "}
            </div>
          );
        }
      }
      return "";
    });
    return menuLinks;
  };

  allowedLinks = (arrLinks) => {
    const Links =
      localStorage.infoToken &&
      JSON.parse(localStorage.infoToken)[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];

    if (Links && typeof Links !== "undefined") {
      const allowedLinks = arrLinks.map((e, index, self) => {
        const { path } = e.props;
        if (path !== "/" && !path.includes("login")) {
          const initSlice = path.indexOf("/") + 1;
          const finSlice = path.indexOf("/", path.indexOf("/") + 1);
          const stringlink = path.slice(
            initSlice,
            finSlice !== -1 ? finSlice : undefined
          );
          const permisosUser = Links.filter((e) => e.includes(stringlink));
          if (permisosUser.length > 0) {
            return e;
          }
        } else {
          return e;
        }
      });
      return allowedLinks;
    }
  };

  async componentDidMount() {
    this.setState({ estaEnPerfil: false });
    this.obtenerNotificaciones();
    this.history.listen(async (location) => {
      await this.setState({
        path: window.location.pathname,
        shortpath:
          window.location.pathname.indexOf("/", 1) !== -1
            ? window.location.pathname.slice(
                0,
                window.location.pathname.indexOf("/", 1)
              )
            : window.location.pathname,
      });
    });
    const Links = JSON.parse(localStorage.infoToken)[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ];
    var LinksApp = [];
    if (typeof Links !== "undefined") {
      LinksApp = Links.filter((e) => PermisosApp.includes(e));
    }

    const filtLinks = LinksApp.filter(
      (e) =>
        e.indexOf("CargarPedidos") === -1 &&
        e.indexOf("EditarPedidos") === -1 &&
        e.indexOf("Notificaciones") === -1
    );

    await this.obtenerConfiguracionIncentivoHabilitado();

    this.getLinkAndName(filtLinks, Links);
  }

  obtenerConfiguracionIncentivoHabilitado = async () => {
    var url = urlServer + "/api/configuracionItem/configuracion/buscarLigeroPorClave";
    var data = {
      clave: "Tienda.Incentivos.PedidoCompra.Habilitado",
      entidadId: null
    };

    await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          incentivoHabilitado: result[0].valor === "1" ? true : false,
        });
      })
      .catch((error) => {
        validarVencimientoToken(error.message);
        console.log(
          "error al obtener la configuracion de incentivo habilitado"
        );
      });
  };

  render() {
    const {
      path,
      shortpath,
      perfil,
      hiddenMenu,
      hiddenPerfil,
      hiddenNotificaciones,
      links,
      sublinks,
      postCuentaCorriente,
      scrollY,
    } = this.state;
    return (
      <Router history={this.history}>
        <div className="position-relative">
          <Provider value={perfil}>
            <div
              className={
                (path === "/" || path === "") &&
                window.location.pathname === "/"
                  ? "backlogin"
                  : "backapp h-100vh"
              }
              style={
                path === "/"
                  ? { minHeight: "calc(100vh - 46px)" }
                  : { minHeight: "calc(100vh - 102px)" }
              }
            >
              <Route
                path="/"
                render={(props) => (
                  <CallMenu
                    path={path}
                    history={this.history}
                    setStateEstaEnPerfil={this.setStateEstaEnPerfil}
                    setStateNoEstaEnPerfil={this.setStateNoEstaEnPerfil}
                    estaEnPerfil={this.state.estaEnPerfil}
                    setLanding={this.props.setLanding}
                    loggingOut={this.props.loggingOut}
                    perfil={perfil}
                    bell={this.state.divnotif}
                    notificaciones={{
                      not1: {
                        message: "Nueva entrega",
                        category: "CuentaCorriente",
                      },
                    }}
                    props={props}
                    hideMenu={this.hideMenu}
                    hiddenMenu={hiddenMenu}
                    hidePerfil={this.hidePerfil}
                    hiddenPerfil={hiddenPerfil}
                    hideNotificaciones={this.hideNotificaciones}
                    hiddenNotificaciones={hiddenNotificaciones}
                    brandlink={this.brandlink}
                    brandlinkMenu={this.brandlinkMenu}
                    linksTienda={sublinks.Tienda}
                    links={
                      sublinks[
                        shortpath
                          ? shortpath.replace("/", "")
                          : path.replace("/", "")
                      ]
                        ? sublinks[
                            shortpath
                              ? shortpath.replace("/", "")
                              : path.replace("/", "")
                          ]
                        : []
                    }
                  />
                )}
              />
              <div
                id="body"
                className={path === "/" || path === "" ? "" : "backapp"}
              >
                <Switch>
                  {this.allowedLinks([
                    <Route
                      key={0}
                      exact
                      path="/"
                      render={() => (
                        <Index
                          key={this.state.estaEnPerfil}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                          links={links}
                          estaEnPerfil={this.state.estaEnPerfil}
                          setStateNoEstaEnPerfil={this.setStateNoEstaEnPerfil}
                        />
                      )}
                    />,
                    <Route
                      key={1}
                      exact
                      path="/login"
                      render={() => (
                        <Login
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={2}
                      exact
                      path="/CuentaCorriente"
                      render={() => (
                        <CuentaCorriente
                          postCuentaCorriente={postCuentaCorriente}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={3}
                      path="/Suscripciones/:route"
                      render={(props) => (
                        <Suscripciones
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={4}
                      exact
                      path="/Suscripciones"
                      render={(props) => (
                        <Suscripciones
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    
                    <Route
                      key={6}
                      path="/Pedidos/:route"
                      render={(props) => (
                        <Pedidos
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={7}
                      exact
                      path="/Pedidos"
                      render={(props) => (
                        <Pedidos
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={8}
                      exact
                      path="/RegistrarVenta"
                      render={(props) => <RegistrarVenta props={props} />}
                    />,
                    <Route
                      key={9}
                      exact
                      path="/Notificaciones"
                      render={() => (
                        <Notificaciones
                          assets={this.state.assets}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                          notificaciones={this.state.notificaciones}
                          history={this.history}
                        />
                      )}
                    />,
                    <Route
                      key={10}
                      path="/Tienda/:route"
                      render={(props) => (
                        <PedidosTienda
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={11}
                      exact
                      path="/Tienda"
                      render={(props) => (
                        <PedidosTienda
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={12}
                      path="/Distribucion/:route"
                      render={(props) => (
                        <PedidosCargasDevoluciones
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={13}
                      exact
                      path="/Distribucion"
                      render={(props) => (
                        <PedidosCargasDevoluciones
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={14}
                      exact
                      path="/Reclamos/AbrirReclamo"
                      render={(props) => (
                        <AbrirReclamo
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={15}
                      exact
                      path="/Novedades"
                      render={(props) => (
                        <EdicionNovedades
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={16}
                      path="/ClubParadaOnline/:route"
                      render={(props) => (
                        <Canje
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={17}
                      exact
                      path="/ClubParadaOnline"
                      render={(props) => (
                        <CanjePorPunto
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                    <Route
                      key={18}
                      exact
                      path="/CanjePorPuntosHistorial"
                      render={(props) => (
                        <CanjePuntoHistorial
                          desdeCanje={false}
                          props={props}
                          hideMenu={this.hideMenu}
                          hiddenMenu={hiddenMenu}
                        />
                      )}
                    />,
                  ])}
                </Switch>
              </div>
            </div>
            <ArrowUp scrollY={scrollY} />
          </Provider>
        </div>
        {(path === "/" || path === "") &&
        window.location.pathname === "/" ? null : (
          <div className={"bottom-nav"}>{this.getLinks()}</div>
        )}
      </Router>
    );
  }
}
