import * as React from "react";
import { Redirect } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Title } from "../components/title";
import pedidos from "../assets/pedidos.svg";
import Spinner from "../components/spinner";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import backArrow from "../assets/backArrow.svg";
import urlServer from "../server";
import { validarVencimientoToken } from "../localStorageManager";
import CargarPedidoPasado from "../components/CargarPedidoPasado";
import CargarPedidoFuturo from '../components/CargarPedidoFuturo';
import CargarPedidoDiario from '../components/CargarPedidoDiario';
import CarritoPedidoDistribuidora from "../components/carritopedidodistribuidora";

export default class CargarPedidoManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: this.props.props.location.state
                ? this.props.props.location.state.producto.ediciones
                    ? {
                          ...this.props.props.location.state.producto,
                          publicacionTipoId: 1,
                      }
                    : this.props.props.location.state.producto
                : null,
            esPedidoFuturo: false,
            loading: false,
            busqueda: "",
            redirect: false,
            flag: true,
            nroSumaRestaFlag: false,
            cargaActualCeroFlag: false,
            backendrows:
                this.props.props.location.state &&
                this.props.props.location.state.producto.ediciones
                    ? this.props.props.location.state.producto.ediciones.map((edicion) => {
                          if (!edicion.cantidad && edicion.cantidad !== 0) {
                              edicion.cantidad = 0;
                          }
                          return edicion;
                      })
                    : [],
            pedido: [],
            successCC: false,
            seleccionadasFlag: false,
            diasSemana: [
                {
                    dia: "Lunes",
                    DiaSemana: 2,
                    cantidad: 0,
                },
                {
                    dia: "Martes",
                    DiaSemana: 3,
                    cantidad: 0,
                },
                {
                    dia: "Miércoles",
                    DiaSemana: 4,
                    cantidad: 0,
                },
                {
                    dia: "Jueves",
                    DiaSemana: 5,
                    cantidad: 0,
                },
                {
                    dia: "Viernes",
                    DiaSemana: 6,
                    cantidad: 0,
                },
                {
                    dia: "Sábado",
                    DiaSemana: 7,
                    cantidad: 0,
                },
                {
                    dia: "Domingo",
                    DiaSemana: 1,
                    cantidad: 0,
                },
            ],
            edicionesDiario: [],
            ultimasrows: [],
            siguientesrows: [],
            nroElementosCarrito: 0,
            edicionesDePedidoFuturoEnCarrito: [],
        };
    }
    history = createBrowserHistory();

    componentDidMount() {
        if (!this.state.backendrows || this.state.backendrows.length < 1) {
            this.ediciones();
        }
        document.title = "Cargar Pedido";
    }

    ediciones = async () => {
        try {
            ReactGA.event({
                category: "Pedidos/CargarPedido",
                action: "Listar Ediciones",
            });

            this.setState({ loading: true });

            if (!this.state.producto) {
                this.setState({ loading: false });
                return;
            }

            if (this.state.producto.publicacionTipoId === 2) {
                await this.obtenerEdicionesDiario();
            } else {
                await this.obtenerEdicionesNoDiario();
            }

            this.setState({ loading: false });
        } catch (error) {
            console.error("Error en ediciones:", error);
            validarVencimientoToken(error.message);
            this.setState({ loading: false });
        }
    };

    obtenerEdicionesNoDiario = async () => {
        try {
            const headers = this.obtenerHeaders();
            const data = {
                productoId: this.state.producto.productoId,
                fechaHasta: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                columnaParaOrdenar: "EDICIONFECHA DESC",
                pageSize: 50,
            };

            const url = `${urlServer}/api/edicion/buscarediciones/crearpedido`;
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers,
            });

            const result = await response.json();
            validarVencimientoToken(result.message);

            let ediciones = result.map((edicion) => ({
                ...edicion,
                cantidad: edicion.cantidad ?? 0,
            }));

            // Obtener ediciones del carrito
            const carrito = await this.obtenerEdicionesCarrito();
            this.setState({ nroElementosCarrito: carrito.length });

            ediciones = this.actualizarEdicionesConCarrito(ediciones, carrito);
            this.setState({ backendrows: ediciones });
        } catch (error) {
            console.error("Error al obtener ediciones no diario:", error);
            validarVencimientoToken(error.message);
        }
    };

    obtenerEdicionesDiario = async () => {
        try {
            const carrito = await this.obtenerEdicionesCarrito();
            this.setState({ nroElementosCarrito: carrito.length });

            const ediciones = this.state.diasSemana.map((dia) => {
                const edicionEnCarrito = carrito.find(
                    (x) =>
                        x.productoId === this.state.producto.productoId &&
                        x.diaSemana === dia.DiaSemana
                );
                return {
                    ...dia,
                    cantidad: edicionEnCarrito ? edicionEnCarrito.cantidad : 0,
                    cantidadBackup: edicionEnCarrito ? edicionEnCarrito.cantidad : 0,
                };
            });

            this.setState({ edicionesDiario: ediciones });
        } catch (error) {
            console.error("Error al obtener ediciones diario:", error);
            validarVencimientoToken(error.message);
        }
    };

    // Función para obtener el carrito
    obtenerEdicionesCarrito = async () => {
        try {
            const url = `${urlServer}/api/pedidopasadofuturo/carrito/obtener`;
            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({ usuarioId: null }),
                headers: this.obtenerHeaders(),
            });

            const result = await response.json();
            validarVencimientoToken(result.message);
            return result;
        } catch (error) {
            console.error("Error al obtener ediciones del carrito:", error);
            validarVencimientoToken(error.message);
            return [];
        }
    };

    // Función para actualizar las ediciones con la información del carrito
    actualizarEdicionesConCarrito = (ediciones, carrito) => {
        let edPedFutCarrito = [...this.state.edicionesDePedidoFuturoEnCarrito];
        let objetivo = {};

        ediciones = ediciones.map((ed) => {
            if (["Ultima", "Primera"].some((pref) => ed.prefijoDescripcion.startsWith(pref))) {
                objetivo = {
                    productoId: this.state.producto.productoId,
                    edicionId: ed.edicionId || 0,
                    ultimaCarga: ed.ultimaCarga || 0,
                };
                this.setState({ ultimaCargadeEdicion: ed.ultimaCarga });
            }

            const edicionEnCarrito = carrito.find(
                (x) =>
                    x.edicionId === ed.edicionId && x.productoId === this.state.producto.productoId
            );

            if (edicionEnCarrito) {
                ed.cantidad = edicionEnCarrito.cantidad;
                if (!this.state.flag) { //Para pedido futuro
                    
                    var edicionIdAux = edicionEnCarrito.edicionId;
                    
                    var index = this.state.edicionesDePedidoFuturoEnCarrito.findIndex(
                        (x) =>
                            x.productoId == this.state.producto.productoId &&
                            x.edicionId == edicionIdAux
                    );

                    if(index < 0)
                    {
                        edPedFutCarrito.push({
                            productoId: this.state.producto.productoId,
                            edicionId: edicionEnCarrito.edicionId,
                            ultimaCarga: objetivo.ultimaCarga,
                        });
                        this.setState({ edicionesDePedidoFuturoEnCarrito: edPedFutCarrito });
                        this.guardarEdPedFutCarritoEnLocalStorage();
                    }
                }
            } else {
                if (
                    edPedFutCarrito.find(
                        (epfc) =>
                            epfc.productoId === objetivo.productoId &&
                            epfc.edicionId === objetivo.edicionId &&
                            ed.cantidad === 0
                    )
                ) {
                    edPedFutCarrito = edPedFutCarrito.filter(
                        (epfc) =>
                            !(
                                epfc.productoId === objetivo.productoId &&
                                epfc.edicionId === objetivo.edicionId
                            )
                    );
                    this.setState({ edicionesDePedidoFuturoEnCarrito: edPedFutCarrito });
                    this.guardarEdPedFutCarritoEnLocalStorage();
                }
                ed.cantidad = 0;
            }

            return { ...ed, cantidadBackup: ed.cantidad };
        });

        return this.actualizarUltimasEdiciones(ediciones);
    };

    // Función para actualizar información de "Última" y "Siguiente" edición
    actualizarUltimasEdiciones = (ediciones) => {
        const indUltimo = ediciones.findIndex((ed) =>
            ["Ultima", "Primera"].some((pref) => ed.prefijoDescripcion.startsWith(pref))
        );
        const indSiguiente = ediciones.findIndex((ed) =>
            ed.prefijoDescripcion.startsWith("Siguiente")
        );

        if (indSiguiente !== -1 && indUltimo !== -1) {
            var encarrito = ediciones.find(
                (x) =>
                    x.edicionId == null &&
                    x.productoId == this.props.props.location.state.producto.productoId
            );
            
            ediciones[indSiguiente] = {
                ...ediciones[indSiguiente],
                ultimaPrefijoDescripcion: ediciones[indUltimo].prefijoDescripcion,
                ultimaDescripcion: ediciones[indUltimo].descripcion,
                ultimaUltimaCarga: ediciones[indUltimo].ultimaCarga,
                cantidad: ediciones[indSiguiente].cantidad == 0 
                            && ediciones[indUltimo].ultimaCarga 
                            && encarrito ? 0 : (ediciones[indSiguiente].cantidadBackup && ediciones[indSiguiente].cantidadBackup > 0) ? ediciones[indSiguiente].cantidadBackup : !encarrito && ediciones[indUltimo].ultimaCarga,
            };
        }

        return ediciones;
    };

    // Función auxiliar para obtener los headers de las peticiones
    obtenerHeaders = () => ({
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.token,
    });

    actualizarCarrito = (edicionId, cantidad, diaSemana, esPedidoFuturo) => {
        const url = urlServer + "/api/pedidopasadofuturo/carrito/actualizar";

        var data = {
            edicionId: edicionId,
            productoId: this.state.producto.productoId,
            cantidad: cantidad,
            esPedidoFuturo: esPedidoFuturo, //1 para pedido futuro, 0 paa pasado
            diaSemana: diaSemana,
        };

        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.token,
            },
        })
            .then((response) => {
                var edPedFutCarrito = this.state.edicionesDePedidoFuturoEnCarrito;

                var objetivo = {
                    productoId: this.state.producto.productoId,
                    edicionId: edicionId ? edicionId : 0,
                    ultimaCarga: this.state.ultimaCargadeEdicion
                        ? this.state.ultimaCargadeEdicion
                        : 0,
                };

                if (edPedFutCarrito.find(
                        (epfc) =>
                            epfc.productoId === objetivo.productoId &&
                            epfc.edicionId === objetivo.edicionId
                    ) && cantidad === -1
                ) {
                    let nuevoArray = edPedFutCarrito.filter(
                        (epfc) =>
                            !(
                                epfc.productoId === objetivo.productoId &&
                                epfc.edicionId === objetivo.edicionId
                            )
                    );
                    this.setState({ edicionesDePedidoFuturoEnCarrito: nuevoArray });
                } else {
                    //edPedFutCarrito.push(objetivo);
                    var existeencarrito = this.state.edicionesDePedidoFuturoEnCarrito.find(
                        (x) =>
                            x.productoId == objetivo.productoId && x.edicionId == objetivo.edicionId
                    );
                    if(!existeencarrito) edPedFutCarrito.push(objetivo);
                    
                    this.setState({ edicionesDePedidoFuturoEnCarrito: edPedFutCarrito });
                    this.guardarEdPedFutCarritoEnLocalStorage();
                }
                return response.json();
            })
            .then((result) => {
                validarVencimientoToken(result.message);
            })
            .catch((error) => {
                validarVencimientoToken(error.message);
                console.log("error ", error);
            });
    };

    goBack = () => {
        this.setState({
            redirect: true,
        });
    };

    redireccionar = () => {
        if (this.state.redirect) {
            if (this.state.successCC) {
                return <Redirect push to={{ pathname: "/Tienda/MisPedidos" }} />;
            }
            return <Redirect push to={{ pathname: "/Pedidos" }} />;
        }
    };

    guardarEdPedFutCarritoEnLocalStorage = () => {
        localStorage.setItem(
            "edPedFutCarrito",
            JSON.stringify(this.state.edicionesDePedidoFuturoEnCarrito)
        );
    };

    obtenerEdPedFutCarritoEnLocalStorage = () => {
        return JSON.parse(localStorage.getItem("edPedFutCarrito"));
    };

    mostrarNroElementoCar = (nro) => {
        this.setState({ nroElementosCarrito: nro });
    };

    volverDeCarrito = (backendrowsCarrito) => {
        var hayPedFut = false;
        // verifica si hay pedido futuro en el carrito.
        if (backendrowsCarrito.length > 0) {
            hayPedFut = backendrowsCarrito.some((ber) => ber.esPedidoFuturo === 1);
        }

        this.setState({ seleccionadasFlag: false });

        if (!hayPedFut) {
            this.setState({ nroSumaRestaFlag: false, edicionesDePedidoFuturoEnCarrito: [] });
            localStorage.removeItem("edPedFutCarrito");
        }

        this.ediciones();
    };

    render() {
        const { loading, producto, seleccionadasFlag } = this.state;

        if (!this.props.props.location.state) {
            return <Redirect to="/Pedidos/MisPedidos" />;
        }

        return (
            <React.Fragment>
                {seleccionadasFlag ? (
                    <CarritoPedidoDistribuidora volverDeCarrito={this.volverDeCarrito} />
                ) : (
                    <React.Fragment>
                        <div
                            id="backarrow"
                            className="position-fixed back-arrow-box"
                            onClick={this.goBack}
                        >
                            <ReactSVG src={backArrow} />
                        </div>
                        <div id="cargarpedido" className="container text-left">
                            <React.Fragment>
                                <div className="d-flex justify-content-between">
                                    <Title
                                        classes=""
                                        title={
                                            seleccionadasFlag
                                                ? "Ediciones seleccionadas"
                                                : "Cargar Pedido"
                                        }
                                    />
                                    <div
                                        className="position-relative"
                                        style={{ marginTop: "53px" }}
                                    >
                                        <ReactSVG
                                            src={pedidos}
                                            style={{
                                                width: "27px",
                                                height: "27px",
                                                color: "#8E95A5",
                                            }}
                                        />
                                        <div
                                            className="position-absolute d-flex justify-content-center align-items-center f-11"
                                            style={{
                                                right: "-8px",
                                                top: "-8px",
                                                background: "#EA3F3F",
                                                color: "white",
                                                borderRadius: "50%",
                                                width: "16px",
                                                height: "16px",
                                            }}
                                        >
                                            {this.state.nroElementosCarrito}
                                        </div>
                                    </div>
                                </div>

                                <div className="titulo-producto">{producto.descripcion}</div>
                            </React.Fragment>
                            {this.redireccionar()}
                            {loading ? (
                                <Spinner style={{ fontSize: "8px" }} />
                            ) : (
                                <React.Fragment>
                                    {this.state.producto.publicacionTipoId !== 2 ? ( // NO DIARIO
                                        <div className="pedido" style={{ paddingBottom: "95px" }}>
                                            {/* Pestañas pedidos */}
                                            {this.state.producto.publicacionTieneRelanzamiento && (
                                                <div
                                                    className="tabs d-flex justify-content-between w-100 "
                                                    style={{
                                                        borderBottom: "1px solid gray",
                                                        marginBottom: "18px",
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            "tablinks col-6 text-center" +
                                                            (this.state.flag ? " active" : "")
                                                        }
                                                        onClick={(e) => {
                                                            this.setState({ flag: true });
                                                        }}
                                                    >
                                                        Últimas Ediciones
                                                    </div>
                                                    <div
                                                        className={
                                                            "tablinks col-6 text-center" +
                                                            (!this.state.flag ? " active" : "")
                                                        }
                                                        onClick={(e) => {
                                                            this.setState({ flag: false });
                                                        }}
                                                    >
                                                        Siguiente Edición
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.flag ? (
                                                <CargarPedidoPasado
                                                    props={this.state}
                                                    mostrarNroElementoCar={
                                                        this.mostrarNroElementoCar
                                                    }
                                                    actualizarCarrito={this.actualizarCarrito}
                                                    hideMenu={this.props.props.hideMenu}
                                                />
                                            ) : (
                                                <CargarPedidoFuturo
                                                    props={this.state}
                                                    mostrarNroElementoCar={
                                                        this.mostrarNroElementoCar
                                                    }
                                                    actualizarCarrito={this.actualizarCarrito}
                                                    hideMenu={this.props.props.hideMenu}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        this.state.edicionesDiario.length > 0 && (
                                            <CargarPedidoDiario
                                                props={this.state}
                                                mostrarNroElementoCar={this.mostrarNroElementoCar}
                                                actualizarCarrito={this.actualizarCarrito}
                                                hideMenu={this.props.props.hideMenu}
                                            />
                                        )
                                    )}
                                    {/*VER CARRITO*/}
                                    {this.state.nroElementosCarrito > 0 && (
                                        <div
                                            id="boton-enviar"
                                            className="d-flex justify-content-center align-items-center barra-enviar"
                                        >
                                            <div
                                                className="d-flex justify-content-center align-items-center"
                                                onClick={() =>
                                                    this.setState({ seleccionadasFlag: true })
                                                }
                                                style={{
                                                    background: "#224372",
                                                    color: "white",
                                                    fontSize: "12px",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    borderRadius: "16px",
                                                    width: "105px",
                                                    height: "33px",
                                                }}
                                            >
                                                Ver Carrito
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
