import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import urlServer from "../server";
import brandLogo from "../assets/brand-logo.png";
import { guardarConexion } from '../guardarConexion';
import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import Login from "./login";
import AppContext from "../context/AppContext";
import { useContext } from 'react';
import { validarVencimientoToken } from "../localStorageManager";

//const MySwal = withReactContent(Swal)

function SolicitudBajaUsuario(props)
{
    const { parentContext, setLogin, setLanding, loggingOut } = useContext(AppContext);

    let { guidSolicitudBajaUsuario } = useParams();
    const [ok, setOk] = useState(null)
    const [mensaje, setMensaje] = useState(null)
    const [procesando, setProcesando] = useState(true)

    useEffect(async () => {
        setProcesando(true)
        document.title = "Solicitud de Baja";

        //comprobacion de token
        try {
        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        const apiBaseUrl =
            urlServer +
            `/api/account/validarToken/?token=${guidSolicitudBajaUsuario}`;
            //la api no requiere permiso
        const response = await fetch(apiBaseUrl, {
            method: "GET",
            headers: myHeaders,
        });

        const result = await response.text();

        const res = JSON.parse(result);

        if (response.status === 200) {
            darDeBajaUsuario();
        } else {
            setOk(false);
            setMensaje("Error. El enlace ha expirado o es Inválido")
            
        }
        setProcesando(false);
        } catch (error) {
            setOk(false);
            setMensaje("Error. Se ha producido un error, por favor, ingrese nuevamente");
            setProcesando(false);
        }

        
    }, []);

    const darDeBajaUsuario = () => {
        const url = urlServer + `/api/account/dardebajausuario/${guidSolicitudBajaUsuario}`
        //la api no requiere permiso
        fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/ json",
                "Authorization": "bearer " + localStorage.token
            }
        }).then((response) => {
            if(response.status === 200)
                response.json();
            else
                throw response
        }).then((result) => {            
            logOut();
        }).catch((err) => {

            if (typeof err.text === 'function') {
                err.text().then(errorMessage => {
                    var msj=JSON.parse(errorMessage)
                setOk(false)
                setMensaje(msj.message);
                })                      
            }


        })

    }

    const logOut = () => {
        if( !localStorage.getItem('infoToken')) // ya cerro sesion
        {
            Swal.fire({
                title: 'La baja de ParadaOnline se realizó con éxito',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
                }).then((result) => {
                if (result.value) {
                    redireccionarInicio();
                }
            })
        }

        const apiUrl = urlServer + '/api/account/logout'
        let myHeaders = new Headers()
        myHeaders.append("Accept", "application/json")
        myHeaders.append("Authorization", "Bearer " + localStorage.token)

        fetch(apiUrl, {
            method: 'POST',
            headers: myHeaders,
            redirect: 'manual'
        }).then(response => response.text())
        .catch(error => {
            console.log('error', error); 
        })
        .then(res => {
            validarVencimientoToken(res.message);
            if (localStorage.getItem('infoToken') != null
                && JSON.parse(localStorage.getItem('infoToken')).usuario_id > 0) {
                let usuarioId = JSON.parse(localStorage.getItem('infoToken')).usuario_id;
                guardarConexion('LOGOUT', usuarioId)
            }
           
            localStorage.removeItem('ddr-auth');
            localStorage.removeItem('ddr-token');
            localStorage.removeItem('is_authenticated');
            localStorage.removeItem('token');
            localStorage.removeItem('infoToken');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('expires_in');
            localStorage.removeItem('expires_at');
            localStorage.removeItem('result');
           
            Swal.fire({
                title: 'La baja de ParadaOnline se realizó con éxito',
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar'
                }).then((result) => {
                if (result.value) {
                    redireccionarInicio();
                }
            })


        })
        .catch(error => {
            console.log('error', error); 
        })
    }

    const redireccionarInicio = () => {
        setLanding([]);
        setLogin(
            <Login
                parentContext={parentContext}
                setLogin={setLogin}
                setLanding={setLanding}
                loggingOut={loggingOut}
            />
        );
        window.location.href = "/";
    }

    return (
        <div>
            <form id="resetpassword" className="backlogin h-100vh w-100">
                <div className="login-title">
                    <img className="brand-logo mb-0" src={brandLogo} alt="brand-logo" />
                </div> 
                <h4 style={{fontSize: '1.5rem'}}>Solicitud de Baja</h4>
                <br />
                <br />
                {procesando === true ? 
                <div>
                    Procesando..
                </div>
                : 
                <div>
                        {ok === false ?
                            <div>
                                {mensaje}
                            </div>
                        :null }
                </div>
                }
                
            </form>
        </div>
    )
} 

export default SolicitudBajaUsuario