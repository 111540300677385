import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import eraser from '../assets/eraser.svg'
//import Spinner from '../components/spinner'
import urlServer from '../server'
import AsyncSelect from 'react-select/async';
import ReactGA from 'react-ga';
import InfiniteScroll from "react-infinite-scroll-component";
import { validarVencimientoToken } from "../localStorageManager";

export default class Maestro extends React.Component {
    constructor(props){
      super(props);
      this.state={
        render:[],
        results: [],
        initPostMaestro: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          publicacionId: null,
          suscriptorId: null,
          fechaNovedadDesde: null,
          fechaNovedadHasta: null,
          limit: 10000,
          offset: 0,
          order: "desc",
          search: "",
          sort: ""
        },
        postMaestro: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          publicacionId: null,
          suscriptorId: null,
          fechaNovedadDesde: null,
          fechaNovedadHasta: null,
          limit: 7,
          offset: 0,
          order: "desc",
          search: "",
              sort: ""
          },
        productoSelected: { value: null, label: 'Seleccione una publicación' },
        suscriptorSelected: { value: null, label: 'Seleccione un suscriptor' },
        busqueda: "",
        loading: false,
        rows: [],
        accordion: false,
      }
    }
    
    setLinks = this.props.setLinks
    
    renderMaestroInfiniteScroll = () => {
      const { rows } = this.state
      return(
        <div className="cards " style={{paddingBottom: '100px'}} >
          { rows.length > 0 ?
              <div className='movimientos-contenedor'>
                <InfiniteScroll
                  dataLength={this.state.rows.length}
                  next={this.reqMaestro}
                  hasMore={true}
                >
                  {
                    rows.filter(a => JSON.stringify(Object.values(a)).toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1)
                      .map((card, index) => {
                        return(
                          <div key={index} className="box" style={{color: "#8E95A5"}} >
                            <div className="d-flex justify-content-between" style={{marginBottom: '12px'}} >
                              <div style={{color: '#EA3F3F', fontWeight: '500', fontSize: '13px'}} >
                                {card.productoCombinacionDescripcion}
                                <div style={{color: '#EA3F3F', fontWeight: '500', fontSize: '13px'}} >
                                    {card.ofertaDias}
                                </div>
                              </div>
                              <div className="d-flex" style={{color: '#343435', fontSize: '13px',fontWeight: '500', marginTop: '-2px'}} >                         
                                {card.fechaCombinada ? <React.Fragment>
                                                          {card.fechaCombinada.indexOf('<') >= 0 ? card.fechaCombinada.slice(0, card.fechaCombinada.indexOf('<')): card.fechaCombinada} < br/>
                                                          {card.fechaCombinada.indexOf('>') >= 0 ? card.fechaCombinada.slice(card.fechaCombinada.indexOf('>') + 1): ""}                        
                                                        </React.Fragment> : ""}
                              </div>
                            </div>
                            <div className="d-flex" >
                              <div className="w-50 suscdesc">
                                SUS:
                                <span className="ml-1 suscvalor">
                                  {card.nroSuscriptorEditor + "-" + card.nroSuscriptorSDDRA}<br/>
                                  {card.suscriptorDescripcion}
                                </span>
                              </div>
                              <div className="w-50 suscdesc">
                                DIR:
                                <span className="ml-1 suscvalor">
                                  {card.domicilioCalle ? card.domicilioCalle : ""}
                                  {card.domicilioAltura ?  " " + card.domicilioAltura : ""}
                                  {card.localidadDescripcion ? " " + card.localidadDescripcion : "" }
                                </span>
                              </div>  
                            </div>
                          </div>)

                      })
                  }
                </InfiniteScroll>
              </div>
          :
              <div className="not-found" >
                No se encontraron suscripciones
              </div>
        }
      </div>
      )
    }
    
    filtrarMaestro = async (data) => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }      
      this.setState({
        loading: true
      })

      var payload = {
        ...data,
        pageSize: this.state.rows.length
      }

      const respuesta = await fetch(urlServer + '/api/suscripciones/Buscar', {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(payload),
        headers
        })
        .then(response => response.text())
        .catch(error => {
          console.log('error', error)
          this.setState({
            loading: false
          })
        })
        .then(result => {
          const res = JSON.parse(result)
          validarVencimientoToken(res.message);
          this.setState({
            rows: (JSON.parse(result)).rows,
            loading: false
          })
        })
        .catch(error => {
          console.log('error', error)
          validarVencimientoToken(error.message);
          this.setState({
            loading: false
          })
        });

        return respuesta;
    }

    clearFilter = () => {
      const fechaNovedadDesde = document.getElementById('fecha-desde') ? document.getElementById('fecha-desde') : null
      const fechaNovedadHasta = document.getElementById('fecha-hasta') ? document.getElementById('fecha-hasta') : null

      fechaNovedadDesde.value = fechaNovedadDesde ? '' : null
      fechaNovedadHasta.value = fechaNovedadHasta ? '' : null

        this.setState({
            productoSelected: { value: null, label: 'Seleccione una publicación' },
            suscriptorSelected: { value: null, label: 'Seleccione un suscriptor' },
            postMaestro: this.state.initPostMaestro
      })
    }

    ocultarFiltros = () => {
      const div = document.getElementById('filtro')
      let divMarginTop = div.getElementsByClassName('filter')[0]
      const marginTop = divMarginTop.offsetHeight
      if (div.className.indexOf('overflow-hidden') !== -1) {
        divMarginTop.style.marginTop = "0"
        setTimeout(() => {div.className = div.className.replace('overflow-hidden', '')}, 550)
      } else {
        div.className += 'overflow-hidden'
        divMarginTop.style.marginTop = `${-marginTop - 3}px`
      }
    }

    handleChangePub = async (newValue) => {
        await this.setState({
            productoSelected: newValue,
            postMaestro: {
              ...this.state.postMaestro,
              publicacionId: newValue.value
            }
      });
      return newValue;
    };

    handleChangeSuscriptor = async (newValue) => {
        await this.setState({
            suscriptorSelected:newValue,
            postMaestro: {
                ...this.state.postMaestro,
                suscriptorId: newValue.value
            }
        });
      return newValue;
    };

    componentDidMount() {
      this.reqMaestro();
    }

    componentWillUnmount() {
      this.props.hideMenu(true)
    }

    reqPublicaciones = async (string) => {
      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }

      if(string) 
      {
        const data = {
          palabraABuscar: string
        }

        const url = urlServer + "/api/producto/buscarPublicaciones"

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(data),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {         

          validarVencimientoToken(result.message);
          let options = result.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return respuesta
      } else {
        const url = urlServer + "/api/producto/Publicaciones"
        
        const dataPub = {
          palabraABuscar: null          
        }

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(dataPub),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {
          validarVencimientoToken(result.mensaje);
          let options = result.rows.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return await respuesta
      }
    }

    reqMaestro = async () => {
      ReactGA.event({
        category: 'Suscripciones/MaestroSuscripciones',
        action: 'Consultar Maestro de Suscripciones'
      });

      const response = await this.filtrarMaestro(this.state.postMaestro)
      return await response
    }

    reqSuscriptores = async (string) => 
    {

      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }

      const data = {
        palabraABuscar: string,
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id
      }

        const url = urlServer + "/api/suscripciones/Suscriptores"

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(data),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {
          validarVencimientoToken(result.message);
          let options = result.rows.map(pub => {
            return {value: pub.suscriptorId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione un suscriptor'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return await respuesta
     }

    render(){
      //const { rows, loading } = this.state

return ( <div className="maestro container text-left">
                <Title 
                  title='Maestro de Suscripciones'
                  classes=''
                  accordion={this.state.accordion}
                  alterAccordion={() => {this.setState({accordion: !this.state.accordion}); this.ocultarFiltros();}}
                />
                <div id="filtro" className={"row overflow-hidden"}>
                  <div className={"filter"}> 
                    <div className=" d-flex justify-content-between" style={{marginBottom: '10px'}}>
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Desde
                        </div>
                        <div>
                          <input id="fecha-desde" className="form-control filter-input" type="date" onChange={e => {
                            let fechaHasta = document.getElementById('fecha-hasta')
                            fechaHasta.min = e.target.value
                            this.setState({
                              postMaestro: {
                                ...this.state.postMaestro, 
                                fechaNovedadDesde: e.target.value,
                                fechaNovedadHasta: fechaHasta.value ? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : e.target.value
                              }
                            })
                            fechaHasta.value = fechaHasta.value ? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : e.target.value
                          }} />
                        </div>
                      </div>
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Hasta
                        </div>
                        <div>
                          <input id="fecha-hasta" className="form-control filter-input" type="date" onChange={e => this.setState({postMaestro: {...this.state.postMaestro, fechaNovedadHasta: e.target.value}})} />
                        </div>
                      </div>
                      <div style={{width: '24%'}} >
                        <div className="eraser" onClick={() => this.clearFilter()}>
                          LIMPIAR
                          <ReactSVG src={eraser} style={{width: '16px'}} />
                        </div>
                        <div style={{width: '100%' }} className="btn button-theme " onClick={() => {this.reqMaestro()}}> Filtrar </div>
                      </div>
                    </div>
                    <div className="w-100 " style={{marginBottom: '10px'}}>
                          <AsyncSelect 
                            id="publicacionId"
                            value={this.state.productoSelected}
                            cacheOptions
                            loadOptions={this.reqPublicaciones}
                            defaultOptions
                            onChange={this.handleChangePub}
                            noOptionsMessage={() => "Seleccione una publicación"}
                            placeholder={"Seleccione una publicación"}
                          />
                    </div>
                    <div className="w-100 " style={{marginBottom: '5px'}}>
                          <AsyncSelect 
                            id="suscriptorId"
                            value={this.state.suscriptorSelected}
                            cacheOptions
                            loadOptions={this.reqSuscriptores}
                            defaultOptions
                            onChange={this.handleChangeSuscriptor}
                            noOptionsMessage={() => "Seleccione un suscriptor"}
                            placeholder={"Seleccione un suscriptor"}
                          />
                    </div>
                  </div>
                </div>
                <div className="w-100 ">
                  <input className="w-100 form-control" type="text" placeholder="Buscar" onChange={e => this.setState({busqueda: e.target.value})}/>
                </div>

               
                {this.renderMaestroInfiniteScroll()}
                {/* // <div className="cards " style={{paddingBottom: '100px'}} >
                //   {rows.length > 0 ? rows.filter(a => JSON.stringify(Object.values(a)).toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((card, index) => {
                //     return(
                //       <div key={index} className="box" style={{color: "#8E95A5"}} >
                //         <div className="d-flex justify-content-between" style={{marginBottom: '12px'}} >
                //           <div style={{color: '#EA3F3F', fontWeight: '500', fontSize: '13px'}} >
                //             {card.productoCombinacionDescripcion}
                //             <div style={{color: '#EA3F3F', fontWeight: '500', fontSize: '13px'}} >
                //                  {card.ofertaDias}
                //             </div>
                //           </div>
                //           <div className="d-flex" style={{color: '#343435', fontSize: '13px',fontWeight: '500', marginTop: '-2px'}} >                         
                //             {card.fechaCombinada ? <React.Fragment>
                //                                       {card.fechaCombinada.indexOf('<') >= 0 ? card.fechaCombinada.slice(0, card.fechaCombinada.indexOf('<')): card.fechaCombinada} < br/>
                //                                       {card.fechaCombinada.indexOf('>') >= 0 ? card.fechaCombinada.slice(card.fechaCombinada.indexOf('>') + 1): ""}                        
                //                                     </React.Fragment> : ""}
                //           </div>
                //         </div>
                //         <div className="d-flex" >
                //            <div className="w-50 suscdesc">
                //             SUS:
                //             <span className="ml-1 suscvalor">
                //               {card.nroSuscriptorEditor + "-" + card.nroSuscriptorSDDRA}<br/>
                //               {card.suscriptorDescripcion}
                //             </span>
                //           </div>
                //           <div className="w-50 suscdesc">
                //             DIR:
                //             <span className="ml-1 suscvalor">
                //               {card.domicilioCalle ? card.domicilioCalle : ""}
                //               {card.domicilioAltura ?  " " + card.domicilioAltura : ""}
                //               {card.localidadDescripcion ? " " + card.localidadDescripcion : "" }
                //             </span>
                //           </div>  
                //         </div>
                //       </div>)

                //   })
                //   :                   
                //   <div className="not-found" >
                //     No se encontraron resultados
                //   </div>
                //   }
                // </div> */}
              
              </div>
        )
    }
}