import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import eraser from '../assets/eraser.svg'
//import Spinner from '../components/spinner'
import urlServer from '../server'
import AsyncSelect from 'react-select/async';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { validarVencimientoToken } from "../localStorageManager";

const DateFormatter = (value) => { if (value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1') } else { return "" } };

export default class MisSolicitudes extends React.Component {
    constructor(props){
      super(props);
      this.state={
        render:[],
        results: [],
        initPostMisSolicitudes: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          fechaNovedadDesde:  new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0, 10),
          fechaNovedadHasta: new Date().toISOString().slice(0, 10),
          productoId: null,
          suscriptorId: null,
          limit: 10000,
          offset: 0,
          order: "desc",
          search: "",
          sort: ""
        },
        postMisSolicitudes: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          fechaNovedadDesde: new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0, 10),
          fechaNovedadHasta: new Date().toISOString().slice(0, 10),
          productoId: null,
          suscriptorId: null,
          limit: 7,
          offset: 0,
          order: "desc",
          search: "",
              sort: ""
          },
        productoSelected: { value: null, label: 'Seleccione una publicación' },
        suscriptorSelected: { value: null, label: 'Seleccione un suscriptor' },
        busqueda: "",
        loading: false,
        rows: [],
        accordion: false,
        AgregarSolicitudOpen: false,
        cargarSolicitud: [],
      }
    }
   
    setLinks = this.props.setLinks
        
    renderMisSolicitudesInfiniteScroll = () => {
        const { rows } = this.state        
      return(
          <div className="cards " style={{paddingBottom: '100px' }} >
              { rows.length > 0 ?
              <div className='movimientos-contenedor'>
                <InfiniteScroll
                  dataLength={this.state.rows.length}
                  next={this.reqMisSolicitudes}
                  hasMore={true}>
                  {
                    rows.filter(a => JSON.stringify(Object.values(a)).toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1)
                      .map((card, index) => {
                        return(
                            <div key={index} className="box" style={{ color: "#8E95A5" }} >
                                <div className="d-flex justify-content-between" style={{ marginBottom: '2px' }} >
                                    <div style={{ color: '#EA3F3F', fontWeight: '500', fontSize: '13px' }} >
                                        {card.productoDescripcion}
                                    </div>
                                    <div className="d-flex" style={{ color: '#343435', fontSize: '13px', fontWeight: '500', marginTop: '0px' }} >
                                        <div className="w-100 suscdesc">
                                            N° EJEM:
                                            <span className="ml-1 suscvalor">
                                                {card.nroEjemplares}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between" style={{marginBottom: '5px'}} >
                                    <div style={{color: 'black'}} >
                                        <div className="w-100 suscvalor">
                                        {card.ofertaDias}
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex justify-content-between" style={{ marginBottom: '2px' }}  >
                                    <div className="suscdesc">
                                        N° SOL:
                                        <span className="ml-1 suscvalor">
                                            {card.suscripcionOfertaId}
                                        </span>
                                    </div>
                                    <div className="suscdesc">
                                        FECHA:
                                        <span className="ml-1 suscvalor">
                                            {card.fecha ? card.fecha : ""}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex" style={{ marginBottom: '2px' }}  >
                                    <div className="w-100 suscdesc">
                                        SUSC:
                                        <span className="ml-1 suscvalor">
                                            {card.suscriptorDescripcion}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex" style={{ marginBottom: '2px' }} >
                                  <div className="w-100 suscdesc">
                                    DIR:
                                    <span className="ml-1 suscvalor">
                                        {card.domicilioDescripcion ? card.domicilioDescripcion : ""}
                                    </span>
                                  </div>  
                                </div>
                          </div>)

                      })
                  }
                </InfiniteScroll>
              </div>
          :
              <div className="not-found" >
                      No se encontraron solicitudes de suscripciones para la fecha entre {this.state.fechaDesdeFiltrada} y {this.state.fechaHastaFiltrada}
                  </div>
               
              }
              
              <div style={{ paddingBottom: '25px' }}></div>
              <div className="d-flex justify-content-center" style={{ position: "fixed", bottom: "29px", left: "0", right: "0" }}>
                  <div className="agregar-pedido" onClick={() => this.setState({ AgregarSolicitudOpen: true })}>+</div>
              </div>
          </div>
       
      )
    }
    
    filtrarMisSolicitudes = async (data) => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }      
      this.setState({
          loading: true,
          fechaDesdeFiltrada: DateFormatter(data.fechaNovedadDesde).slice(0, 10),
          fechaHastaFiltrada: DateFormatter(data.fechaNovedadHasta).slice(0, 10),
      })
      var payload = {
        ...data,
          pageSize: this.state.rows ? this.state.rows.length: 0
      }

        const respuesta = await fetch(urlServer + '/api/suscripciones/Solicitudes/Listado', {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(payload),
        headers
        })
            .then(response => response.text())
        .catch(error => {
          console.log('error', error)
          this.setState({
            loading: false
          })
        })
            .then(result => {
          const res = JSON.parse(result)
          validarVencimientoToken(res.message);
          this.setState({
            rows: res,
            loading: false
          })
        })
        .catch(error => {
          console.log('error', error)
          validarVencimientoToken(error.message);
          this.setState({
            loading: false
          })
        });

        return respuesta;
    }

    clearFilter = () => {
        const fechaNovedadDesde = document.getElementById('fecha-desde')
        const fechaNovedadHasta = document.getElementById('fecha-hasta')

        fechaNovedadDesde.value = new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0, 10)
        fechaNovedadHasta.value = new Date().toISOString().slice(0, 10)
        fechaNovedadHasta.min = fechaNovedadDesde.value

        this.setState({
            productoSelected: { value: null, label: 'Seleccione una publicación' },
            suscriptorSelected: { value: null, label: 'Seleccione un suscriptor' },
            postMisSolicitudes: this.state.initPostMisSolicitudes,
            fechaNovedadDesde: fechaNovedadDesde.value ? fechaNovedadDesde.value : new Date().toISOString().slice(0, 10),
            fechaNovedadHasta: fechaNovedadHasta.value ? fechaNovedadHasta.value : new Date().toISOString().slice(0, 10)
      })
    }

    ocultarFiltros = () => {

      let fechaNovedadDesde = document.getElementById('fecha-desde')
      let fechaNovedadHasta = document.getElementById('fecha-hasta')
        fechaNovedadHasta.min = fechaNovedadDesde.value

      this.setState({
            postMisSolicitudes: {
                ...this.state.postMisSolicitudes,
              fechaNovedadDesde: fechaNovedadDesde.value ? fechaNovedadDesde.value : new Date().toISOString().slice(0, 10),
              fechaNovedadHasta: fechaNovedadHasta.value ? fechaNovedadHasta.value : new Date().toISOString().slice(0, 10)
            }
      });
        fechaNovedadHasta.value = fechaNovedadHasta.value ? fechaNovedadHasta.value : new Date().toISOString().slice(0, 10)

      const div = document.getElementById('filtro')
      let divMarginTop = div.getElementsByClassName('filter')[0]
      const marginTop = divMarginTop.offsetHeight
      if (div.className.indexOf('overflow-hidden') !== -1) {
        divMarginTop.style.marginTop = "0"
        setTimeout(() => {div.className = div.className.replace('overflow-hidden', '')}, 550)
      } else {
        div.className += 'overflow-hidden'
        divMarginTop.style.marginTop = `${-marginTop - 3}px`
      }
    }

    handleChangePub = async (newValue) => {
        await this.setState({
            productoSelected: newValue,
            postMisSolicitudes: {
              ...this.state.postMisSolicitudes,
                productoId: newValue.value
            }
      });
      return newValue;
    };

    handleChangeSuscriptor = async (newValue) => {

        await this.setState({
            suscriptorSelected:newValue,
            postMisSolicitudes: {
                ...this.state.postMisSolicitudes,
                suscriptorId: newValue.value
            }
        });
      return newValue;
    };

    componentDidMount() {
      this.reqMisSolicitudes();
    }

    componentWillUnmount() {
        this.props.hideMenu(!this.props.hiddenMenu)
    }

    cargarSolicitud = () => {
        if (this.state.AgregarSolicitudOpen) {
            return <Redirect push to={{
                pathname: '/Suscripciones/SolicitarSuscripcion'
            }} />
        }
    }

    reqPublicaciones = async (string) => {
       
      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }

      if(string) 
      {
        const data = {
          palabraABuscar: string
        }

        const url = urlServer + "/api/producto/buscarPublicaciones"

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(data),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {         

          validarVencimientoToken(result.message);
          let options = result.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return respuesta
      } else {
        const url = urlServer + "/api/producto/Publicaciones"
        
        const dataPub = {
          palabraABuscar: null          
        }

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(dataPub),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {
          validarVencimientoToken(result.mensaje);
          let options = result.rows.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return await respuesta
      }
    }

    reqMisSolicitudes = async () => {
      ReactGA.event({
        category: 'Suscripciones/MisSolicitudes',
        action: 'Consultar las Solicitudes de Suscripciones realizadas'
      });

      const response = await this.filtrarMisSolicitudes(this.state.postMisSolicitudes)
      return await response
    }

    
    reqSuscriptores = async (string) => 
    {
      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }
      const data = {
        palabrasABuscar: string,
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id
      }

        const url = urlServer + "/api/suscripciones/suscriptor/Listado"

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(data),
          headers: headers
        })
        .then(response => response.json())
        .then(result => {
          validarVencimientoToken(result.message);
          let options = result.map(susc => {
              return { value: susc.suscriptorId, label: susc.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione un suscriptor'},
            ...options
          ]
          this.setState({
            publicaciones: result,
            options
          })
          return options
        })
        .catch(error => {
          validarVencimientoToken(error.message);
          console.log('error', error)
        });
        return await respuesta
     }
     
    render(){
      //const { rows, loading } = this.state

return ( <div className="MisSolicitudes container text-left">
                <Title 
                  title='Mis Solicitudes'
                  classes=''
                  accordion={this.state.accordion}
                  alterAccordion={() => {this.setState({accordion: !this.state.accordion}); this.ocultarFiltros();}}
                />
                <div id="filtro" className={"row overflow-hidden"}>
                  <div className={"filter"}> 
                    <div className=" d-flex justify-content-between" style={{marginBottom: '10px'}}>
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Desde
                        </div>
                        <div>
                        <input id="fecha-desde"
                            className="form-control filter-input"
                            type="date"
                            value={this.state.postMisSolicitudes.fechaNovedadDesde}
                            onChange={e => {
                            let fechaHasta = document.getElementById('fecha-hasta')
                            fechaHasta.min = e.target.value
                            this.setState({
                              postMisSolicitudes: {
                                ...this.state.postMisSolicitudes, 
                                fechaNovedadDesde: e.target.value,
                                fechaNovedadHasta: fechaHasta.value ? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : e.target.value
                              }
                            })
                            fechaHasta.value = fechaHasta.value ? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : e.target.value
                          }} />
                        </div>
                      </div>
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Hasta
                        </div>
                        <div>
                          <input id="fecha-hasta" className="form-control filter-input" type="date" onChange={e => this.setState({postMisSolicitudes: {...this.state.postMisSolicitudes, fechaNovedadHasta: e.target.value}})} />
                        </div>
                      </div>
                      <div style={{width: '24%'}} >
                        <div className="eraser" onClick={() => this.clearFilter()}>
                          LIMPIAR
                          <ReactSVG src={eraser} style={{width: '16px'}} />
                        </div>
                        <div style={{width: '100%' }} className="btn button-theme " onClick={() => {this.reqMisSolicitudes()}}> Filtrar </div>
                      </div>
                    </div>
                    <div className="w-100 " style={{marginBottom: '10px'}}>
                          <AsyncSelect 
                            id="productoId"
                            value={this.state.productoSelected}
                            cacheOptions
                            loadOptions={this.reqPublicaciones}
                            defaultOptions
                            onChange={this.handleChangePub}
                            noOptionsMessage={() => "Seleccione una publicación"}
                            placeholder={"Seleccione una publicación"}
                          />
                    </div>
                
                    <div className="w-100 " style={{marginBottom: '5px'}}>
                          <AsyncSelect 
                            id="suscriptorId"
                            value={this.state.suscriptorSelected}
                            cacheOptions
                            loadOptions={this.reqSuscriptores}
                            defaultOptions
                            onChange={this.handleChangeSuscriptor}
                            noOptionsMessage={() => "Seleccione un suscriptor"}
                            placeholder={"Seleccione un suscriptor"}
                          />
                    </div>
                   
                  </div>
                </div>
                <div className="w-100 ">
                  <input className="w-100 form-control" type="text" placeholder="Buscar" onChange={e => this.setState({busqueda: e.target.value})}/>
                </div>

                {this.cargarSolicitud()}
                {this.renderMisSolicitudesInfiniteScroll()}
                          
              </div>
        )
    }
}