import * as React from "react";
import { Redirect } from "react-router-dom";
import { ReactSVG } from "react-svg";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import { createBrowserHistory } from "history";
export default class CargarPedidoPasado extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.props,
        };
    }

    history = createBrowserHistory();


    // funciones input inicio
    definirBackGround = (edicion) => {
        if (edicion.cantidad === null) {
            return "hsla(14, 100%, 53%, 0.6)";
        } else {
            return "white";
        }
    };

    handleValueInput = (cantidadActual) => {
        if (cantidadActual === "") {
            return "";
        } else {
            return cantidadActual;
        }
    };

    handleInputOnBlurUltimasEdiciones = (e, edicion) => {
        let { backendrows, nroElementosCarrito } = this.state;
        
        // Limpiar el valor ingresado eliminando caracteres no numéricos y ceros iniciales
        e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(/^0+/g, "");
        
        // Si el input está vacío, asignamos 0
        edicion.cantidad = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
    
        // Determinar el cambio en `nroElementosCarrito`
        if (edicion.cantidad !== edicion.cantidadBackup) {
            nroElementosCarrito = 
                edicion.cantidad === 0 && edicion.cantidadBackup > 0 
                    ? nroElementosCarrito - 1
                    : edicion.cantidad > 0 && (!edicion.cantidadBackup || edicion.cantidadBackup === 0)
                    ? nroElementosCarrito + 1
                    : nroElementosCarrito;
    
            this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null);
    
            edicion.cantidadBackup = edicion.cantidad;
        }
    
        const updatedBackendRows = backendrows.map(item =>
            item.edicionId === edicion.edicionId ? { ...edicion } : item
        );
    
        this.setState({
            backendrows: updatedBackendRows,
            nroElementosCarrito
        });
    
        this.props.mostrarNroElementoCar(nroElementosCarrito);
    };
    
    // funciones input fin

    restarElementosPedidoPasado = (edicion) => {
        let nroElementosCarrito = this.state.nroElementosCarrito;
        edicion.cantidad--;
        edicion.cantidadBackup = edicion.cantidad;
        let elemento = document.getElementById(edicion.edicionId);

        if (elemento) {
            elemento.value = edicion.cantidad;
        } else {
            elemento = document.getElementById("PF");
            elemento.value = edicion.cantidad;
        }

        let { backendrows } = this.state;
        let ind = backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
        backendrows[ind] = edicion;

        if (edicion.cantidad === 0) {
            this.props.actualizarCarrito(edicion.edicionId, -1, null, 0);
        } else {
            this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null, 0);
        }
        nroElementosCarrito =
            edicion.cantidad === 0 ? nroElementosCarrito - 1 : nroElementosCarrito;

        this.setState({
            backendrows,
            nroElementosCarrito: nroElementosCarrito,
        });

        this.props.mostrarNroElementoCar(nroElementosCarrito);
    };

    sumarElementosPedidoPasado = (edicion) => {
        let nroElementosCarrito = this.state.nroElementosCarrito;
        edicion.cantidad++;
        edicion.cantidadBackup = edicion.cantidad;

        let elemento = document.getElementById(edicion.edicionId);
        if (elemento) {
            elemento.value = edicion.cantidad;
        } else {
            elemento = document.getElementById("PF");
            elemento.value = edicion.cantidad;
        }

        let { backendrows } = this.state;
        let ind = backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
        backendrows[ind] = edicion;

        this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null, 0);
        nroElementosCarrito =
            edicion.cantidad === 1 ? nroElementosCarrito + 1 : nroElementosCarrito;
        this.setState({
            backendrows,
            nroElementosCarrito: nroElementosCarrito,
        });

        this.props.mostrarNroElementoCar(nroElementosCarrito);
    };

    
    render() {
        const { backendrows } = this.state;
        
        return (
            <React.Fragment>
                {/* Campo de busqueda para pedidos */}
                <React.Fragment>
                    <div className="w-100" style={{ marginBottom: "18px" }}>
                        <input
                            className="w-100 form-control"
                            type="text"
                            placeholder="Buscar"
                            onChange={(e) => {
                                this.setState({
                                    busqueda: e.target.value
                                        .normalize("NFD")
                                        .replace(/\p{Diacritic}/gu, "")
                                        .replace(/\./g, ""),
                                    busquedaTextoMostrarEnInput: e.target.value,
                                });
                            }}
                            value={this.state.busquedaTextoMostrarEnInput}
                        />
                    </div>
                </React.Fragment>
                {/* Pedidos ultimas ediciones */}
                {backendrows
                    .filter(
                        (e) => !e.prefijoDescripcion || e.prefijoDescripcion.startsWith("Ultima")
                    )
                    .filter(
                        (a) =>
                            JSON.stringify(Object.values(a))
                                .normalize("NFD")
                                .replace(/\p{Diacritic}/gu, "")
                                .toLowerCase()
                                .indexOf(this.state.busqueda.toLowerCase()) !== -1
                    )
                    .map((edicion, index) => {
                        return (
                            <div
                                key={index}
                                className="box justify-content-between align-items-center days"
                                style={{ padding: "10px", marginTop: "10px" }}
                            >
                                {this.state.flag ? (
                                    <div
                                        className="f-13-5 fw-500"
                                        style={{
                                            color: "#343435",
                                            textAlign: "justify",
                                        }}
                                    >
                                        {edicion.descripcion +
                                            (edicion.descripcion.indexOf(edicion.edicionNumeroFecha) !== -1
                                                ? ""
                                                : " " + (edicion.edicionNumeroFecha
                                                          ? edicion.edicionNumeroFecha
                                                          : ""))
                                        }
                                    </div>
                                ) : (
                                    this.mostrarSiguienteEdicion(edicion)
                                )}
                                {/*CONTENEDOR*/}
                                <div
                                    className="w-100 footerItem align-items-center d-flex"
                                    style={{
                                        justifyContent: "right",
                                        paddingTop: "10px",
                                    }}
                                >
                                    <div
                                        className={`d-flex justify-content-between align-items-center ${"elemento-botones-input"}`}
                                    >
                                        {/*RESTAR*/}
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                cursor: "pointer",
                                                background: !edicion.cantidad
                                                    ? "#FCFCFC"
                                                    : "#F4F4F4",
                                                width: "26px",
                                                height: "26px",
                                                borderRadius: "50%",
                                                padding: "5px",
                                            }}
                                            onClick={() => {
                                                if (edicion.cantidad > 0) {
                                                    this.restarElementosPedidoPasado(edicion);
                                                }
                                            }}
                                        >
                                            <ReactSVG
                                                src={restar}
                                                style={{
                                                    color: !edicion.cantidad
                                                        ? "#EAEAEA"
                                                        : "#8E95A5",
                                                    width: "11px",
                                                }}
                                                title="Restar"
                                            />
                                        </div>
                                        {/*INPUT*/}
                                        <div
                                            className="f-13-5"
                                            style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            <input
                                                style={{
                                                    backgroundColor:
                                                        this.definirBackGround(edicion),
                                                    border: "#343435 solid 1px",
                                                }}
                                                type="number"
                                                autoComplete="off"
                                                className="input-of-cantidades text-center"
                                                maxLength="3"
                                                id={edicion.edicionId ? edicion.edicionId : "PF"}
                                                key={edicion.edicionId}
                                                value={edicion.cantidad}
                                                onFocus={(e) => {
                                                    e.target.select();
                                                }}
                                                min={"0"}
                                                max={"100000"}
                                                onInput={(e) =>
                                                    (e.target.value = e.target.value.slice(0, 6))
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.currentTarget.blur();
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let regex = /[^0-9]/g;
                                                    if (regex.test(e.target.value)) {
                                                        e.target.value = e.target.value.replace(
                                                            regex,
                                                            ""
                                                        );
                                                    }
                                                    e.target.value =
                                                        e.target.value === "0"
                                                            ? e.target.value
                                                            : e.target.value.replace(/^(0+)/g, "");
                                                    edicion.cantidad =
                                                        e.target.value === "" || e.target.value < 0
                                                            ? null
                                                            : parseInt(e.target.value);
                                                    let { backendrows } = this.state;
                                                    let ind = backendrows.findIndex(
                                                        (e) => e.edicionId === edicion.edicionId
                                                    );
                                                    backendrows[ind] = edicion;
                                                    this.setState({
                                                        backendrows,
                                                    });
                                                }}
                                                onBlur={(e) => {
                                                    this.handleInputOnBlurUltimasEdiciones(
                                                        e,
                                                        edicion
                                                    );
                                                }}
                                            />
                                        </div>
                                        {/*SUMAR*/}
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                cursor: "pointer",
                                                background:
                                                    edicion.cantidad === 100000
                                                        ? "#FCFCFC"
                                                        : "#F4F4F4",
                                                width: "26px",
                                                height: "26px",
                                                borderRadius: "50%",
                                                padding: "5px",
                                                pointerEvents:
                                                    edicion.cantidad >= 100000 ? "none" : "",
                                            }}
                                            onClick={() => {
                                                this.sumarElementosPedidoPasado(edicion);
                                            }}
                                        >
                                            <ReactSVG
                                                src={sumar}
                                                style={{
                                                    width: "11px",
                                                    height: "18px",
                                                    color: "#8E95A5",
                                                }}
                                                title="Sumar"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </React.Fragment>
        );
    }
}
