import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom'
import './index.css'
import cuentacorriente from '../assets/cuentacorriente.svg'
import devoluciones from '../assets/devoluciones.svg'
import distribuciondevoluciones from '../assets/distribucion.svg'
import suscripciones from '../assets/suscripciones.svg'
import pedidos from '../assets/pedidos.svg'
import registrarventa from '../assets/registrarventa.svg'
import tienda from '../assets/tienda.svg'
import clubparadaonline from '../assets/clubParadaOnline.svg'
import { version } from '../../package.json'
import novedades from '../assets/novedades.svg'
import urlServer from '../server';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Spinner from '../components/spinner';
import { validarVencimientoToken } from "../localStorageManager";

const MySwal = withReactContent(Swal)
const versionActual = version.replace(/\./g, '')

export default class Index extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            cuentacorriente,
            devoluciones,
            distribuciondevoluciones,
            suscripciones,
            pedidos,
            registrarventa,
            tienda,
            novedades,
            clubparadaonline,
            descripcionesNov: [],
            estaEnPerfil: false,
            cantidadPermisos: 0
        }
    }
           
    listadoNovedades = async () => {
        const headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            Authorization: 'Bearer ' + localStorage.token
        }

        const data = {
            NombreTienda: "TIENDA AGEA"
        }

        const url = urlServer + "/api/novedades/novedadesVigentes/listar"

        const respuesta = await fetch(url,{
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers: headers
        })
        .then(response => response.json())
        .then(result => {
            validarVencimientoToken(result.message);
            this.setState({ descripcionesNov: result.rows })
        })
        .catch(error => {
            validarVencimientoToken(error.message); 
            console.log('error', error) 
        })

        return respuesta
    }

    renderNotifications() {
        const { descripcionesNov } = this.state

        return (
            <React.Fragment>
                {this.state.descripcionesNov ?
                   descripcionesNov.length === 0 ? null :  <span id='notification' className=""> {descripcionesNov.length} </span> 
                : null}
            </React.Fragment>
        )
    }
    
    isWithNotif = (name) => {
        if (name === 'Novedades' ) {
            return this.state.descripcionesNov.length !== 0 ? '10px' : '0px'
        } else {
            return '0px'
        }
    }

    styleForNov = {
        position: 'relative',
        bottom: this.isWithNotif()
    }

    componentDidMount() {
        this.listadoNovedades()
        this.props.setStateNoEstaEnPerfil()
    }

    renderIndex = () => {
        const { links } = this.props

        return (
            <React.Fragment>
                <div className="index-menu">
                    <div style={{display: 'grid', gridGap: '10px', gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))", justifyItems: 'center'}}>
                        {links ? (links.map((link, index) => {
                            return  <div key={index} className="index-menu-box d-flex align-items-center justify-content-center flex-column position-relative box-content" >
                                        {link.name === 'Novedades' ? this.renderNotifications() : ''}
                                        <Link className={link.name === 'Novedades' && (this.state.descripcionesNov) ? (this.state.descripcionesNov.length !== 0 && !this.state.estaEnPerfil ? 'withNotif' : 'noNotif'): 'noNotif'}  onClick={() => {this.props.hideMenu(true)}} to={link.link}>
                                            <ReactSVG style={{color: '#8E95A5', width: '28px', height: '32px', position:'relative', left: '0', right: '0', bottom: '0px', margin: 'auto'}} src={this.state[link.link.replace("/", "").replace("/", "").toLowerCase()]}/>
                                            <span>
                                                {link.name}
                                            </span>
                                        </Link>
                                    </div>
                        }))
                        : null}
                    </div>
                </div>
                <div className="version mb-1" >v{version}</div>
            </React.Fragment>
            
        )
    }

    render(){
        const {loading} = this.state
        return (
            
            loading ? <div className="index"><Spinner style={{ fontSize: '8px' }} /></div>
                :
                <div className="index">
                    { this.renderIndex() }
                </div>
                )
    }
}