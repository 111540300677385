import * as React from "react";
import { ReactSVG } from "react-svg";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import { createBrowserHistory } from "history";

export default class CargarPedidoDiario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.props,
        };
    }

    history = createBrowserHistory();

    // funciones input inicio
    definirBackGround = (dia) => {
        if (dia.cantidad === false) {
            return "hsla(14, 100%, 53%, 0.6)";
        } else {
            return "white";
        }
    };

    handleInputOnBlurPedidoConDias = (e, dia, edicionesDiario, index) => {
        let nroElementosCarrito = this.state.nroElementosCarrito;

        let regex = /[^0-9]/g;
        // Limpiar caracteres no numéricos
        if (regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(regex, "");
        }

        if (e.target.value === "") {
            dia.cantidad = 0;

            if (dia.cantidad !== dia.cantidadBackup) {
                nroElementosCarrito =
                    (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0
                        ? this.state.nroElementosCarrito - 1
                        : dia.cantidad > 0 &&
                          (dia.cantidadBackup === 0 || dia.cantidadBackup === null)
                        ? this.state.nroElementosCarrito + 1
                        : this.state.nroElementosCarrito;

                dia.cantidadBackup = 0;
                this.props.actualizarCarrito(null, dia.cantidad, dia.DiaSemana);
            }

            edicionesDiario[index] = dia;
            this.setState({
                edicionesDiario,
                nroElementosCarrito,
            });
        } else {
            nroElementosCarrito =
                (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0
                    ? this.state.nroElementosCarrito - 1
                    : dia.cantidad > 0 && (dia.cantidadBackup === 0 || dia.cantidadBackup === null)
                    ? this.state.nroElementosCarrito + 1
                    : this.state.nroElementosCarrito;

            e.target.value =
                e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, "");
            dia.cantidad =
                e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);

            if (dia.cantidad !== dia.cantidadBackup) {
                dia.cantidadBackup = dia.cantidad;
                this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana);
                edicionesDiario[index] = dia;

                this.setState({
                    edicionesDiario,
                    nroElementosCarrito,
                });
            }
        }
        this.props.mostrarNroElementoCar(nroElementosCarrito);
    };
    

    handleOnClickPedidoDiarioInput = (dia, index) => {
        let nroElementosCarrito = this.state.nroElementosCarrito;

        if (dia.cantidad > 0) {
            let { edicionesDiario } = this.state;
            edicionesDiario[index].cantidad--;
            edicionesDiario[index].cantidadBackup--;
            let elemento = document.getElementById(
                `dia-${dia.DiaSemana}`
            );
            
            if (elemento) {
                elemento.value = dia.cantidad;
            }

            this.props.actualizarCarrito(null, dia.cantidad, dia.DiaSemana, 1);
            
            var nroElementosCarritoTemp =
                dia.cantidad === 0
                    ? nroElementosCarrito - 1
                    : nroElementosCarrito;

            this.setState({
                edicionesDiario,
                nroElementosCarrito: nroElementosCarritoTemp,
            });

            this.props.mostrarNroElementoCar(
                nroElementosCarritoTemp
            );
        }
    };

    handleOnChangePedidoDiarioInput = (e, dia, edicionesDiario, index) => {
        let regex = /[^0-9]/g;
        if (regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(regex, "");
        }

        e.target.value =
            e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, "");

        dia.cantidad =
            e.target.value === "" || e.target.value < 0 ? false : parseInt(e.target.value);

        edicionesDiario[index].cantidad = dia.cantidad;

        this.setState({
            edicionesDiario,
        });
    };
    // funciones input fin

    handleOnClickPedidoDiarioSumar = (dia, index) => {
        let { edicionesDiario, nroElementosCarrito } = this.state;
        edicionesDiario[index].cantidad++;
        edicionesDiario[index].cantidadBackup++;

        let elemento = document.getElementById(
            `dia-${dia.DiaSemana}`
        );

        if (elemento) {
            elemento.value = dia.cantidad;
        }

        this.props.actualizarCarrito(
            null,
            dia.cantidad,
            dia.DiaSemana,
            1
        );

        var nroElementosCarritoTemp =
            dia.cantidad === 1
                ? nroElementosCarrito + 1
                : nroElementosCarrito;

        this.setState({
            edicionesDiario,
            nroElementosCarrito: nroElementosCarritoTemp,
        });

        this.props.mostrarNroElementoCar(nroElementosCarritoTemp);
    }

    render() {
        const { edicionesDiario } = this.state;

        return (
            <React.Fragment>
                <div className="pedido" style={{ paddingBottom: "95px" }}>
                    <div className="f-16 fw-400 text-center" style={{ marginBottom: "30px" }}>
                        Pedido futuro
                    </div>
                    {edicionesDiario?.map((dia, index) => {
                        return (
                            <div
                                key={index}
                                className="d-flex justify-content-between align-items-center days"
                            >
                                <div
                                    className="f-13-5 fw-400"
                                    style={{
                                        color: "#343435",
                                        maxWidth: "66%",
                                    }}
                                >
                                    {dia.dia}
                                </div>
                                {/*CONTENEDOR*/}
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ width: "88px" }}
                                >
                                    {/*RESTAR*/}
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            cursor: "pointer",
                                            background: dia.cantidad === 0 ? "#FCFCFC" : "#F4F4F4",
                                            width: "26px",
                                            height: "26px",
                                            borderRadius: "50%",
                                        }}
                                        onClick={() => this.handleOnClickPedidoDiarioInput(dia, index)}
                                    >
                                        <ReactSVG
                                            src={restar}
                                            style={{
                                                color: dia.cantidad === 0 ? "#EAEAEA" : "#8E95A5",
                                                width: "11px",
                                            }}
                                        />
                                    </div>
                                    {/*INPUT*/}
                                    <div className="f-13-5">
                                        <input
                                            style={{
                                                backgroundColor: this.definirBackGround(dia),
                                                border: "#343435 solid 1px",
                                            }}
                                            type="number"
                                            autoComplete="off"
                                            className="input-of-cantidades text-center"
                                            maxLength="3"
                                            id={`dia-${dia.DiaSemana}`}
                                            value={dia.cantidad}
                                            onFocus={(e) => {
                                                e.target.select();
                                            }}
                                            min={"0"}
                                            max={"100000"}
                                            onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.currentTarget.blur();
                                                }
                                            }}
                                            onChange={(e) => this.handleOnChangePedidoDiarioInput(e, dia, edicionesDiario, index)}
                                            onBlur={(e) => { this.handleInputOnBlurPedidoConDias(e, dia, edicionesDiario)}}
                                        />
                                    </div>
                                    {/*SUMAR*/}
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            cursor: "pointer",
                                            background:
                                                dia.cantidad === 100000 ? "#FCFCFC" : "#F4F4F4",
                                            width: "26px",
                                            height: "26px",
                                            borderRadius: "50%",
                                            pointerEvents: dia.cantidad >= 100000 ? "none" : "",
                                        }}
                                        onClick={() => this.handleOnClickPedidoDiarioSumar(dia, index)}
                                    >
                                        <ReactSVG
                                            src={sumar}
                                            style={{
                                                width: "11px",
                                                height: "18px",
                                                color: "#8E95A5",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </React.Fragment>
        );
    }
}
