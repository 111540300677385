import * as React from "react";
import { ReactSVG } from "react-svg";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import eliminar from "../assets/eliminar.svg";
import tick from "../assets/tick.svg";
import urlServer from "../server";
import { createBrowserHistory } from "history";
import { validarVencimientoToken } from "../localStorageManager";

export default class CargarPedidoFuturo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.props,
        };
    }

    history = createBrowserHistory();

    // funciones input inicio
    definirBackGround = (edicion) => {
        if (edicion.cantidad === null && edicion.ultimaPrefijoDescripcion != "Primera edición o sin carga en ediciones anteriores") {
            return "hsla(14, 100%, 53%, 0.6)";
        } else {
            return "white";
        }
    };

    handleValueInput = (cantidadActual) => {
        if (cantidadActual === "") {
            return "";
        } else {
            return cantidadActual;
        }
    };

    handleOnClickPedidoFuturoConfirmar = (edicion) => {
        if(!edicion.yaPedido) {
            var nroElementosCarrito = this.state.nroElementosCarrito;
            edicion.cantidadBackup = edicion.cantidad;
            let elemento = document.getElementById(edicion.edicionId);

            if (elemento) {
                elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById("PF");
                elemento.value = edicion.cantidad;
            }

            let {backendrows} = this.state;
            let ind = backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
            
            backendrows[ind] = edicion;
            this.props.actualizarCarrito(
                edicion.edicionId,
                edicion.cantidad,
                null,
                1
            );

            var nroElementosCarritoTemp =
                edicion.cantidad === edicion.ultimaUltimaCarga &&
                !this.verificarEdicionEnCarrito(edicion.edicionId)
                    ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                    : nroElementosCarrito;

            this.setState({
                backendrows,
                nroElementosCarrito: nroElementosCarritoTemp,
            });

            this.props.mostrarNroElementoCar(nroElementosCarritoTemp);
        }
    }

    handlePedidoSinEdicionAnterior = (edicion) => {
        return edicion.cantidad === 0 &&
                (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null)
                ? this.state.nroElementosCarrito
                : edicion.cantidad === 0 && edicion.cantidadBackup > 0
                    ? this.state.nroElementosCarrito - 1
                    : edicion.cantidad > 0 &&
                        (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null)
                        ? this.state.nroElementosCarrito + 1
                        : this.state.nroElementosCarrito;
    };

    handlePedidoConEdicionAnterior = (edicion) => {
        return edicion.cantidad === 0 &&
                edicion.cantidadBackup > 0 &&
                edicion.ultimaUltimaCarga === 0 &&
                this.state.nroSumaRestaFlag
                    ? this.restarElementoCarritoYFlag(this.state.nroElementosCarrito, edicion.edicionId)
                    : edicion.cantidad > 0 &&
                    (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null)
                        ? this.sumarElementoCarritoYFlag(this.state.nroElementosCarrito, edicion.edicionId)
                        : edicion.cantidad === 0 &&
                            edicion.ultimaUltimaCarga > 0 &&
                            !this.state.cargaActualCeroFlag
                            ? this.sumarElementoCarritoYFlag(this.state.nroElementosCarrito, edicion.edicionId)
                            : edicion.cantidad > 0 &&
                                edicion.ultimaUltimaCarga === 0 &&
                                !this.state.cargaActualCeroFlag
                                ? this.sumarElementoCarritoYFlag(this.state.nroElementosCarrito, edicion.edicionId)
                                : edicion.cantidad > 0 &&
                                    edicion.ultimaUltimaCarga > 0 &&
                                    this.state.nroSumaRestaFlag
                                    ? this.sumarElementoCarritoYFlag(this.state.nroElementosCarrito, edicion.edicionId)
                                    : this.state.nroElementosCarrito;
    };

    handleInputOnBlurEdiciones = (e, edicion) => {
        let regex = /[^0-9]/g
        // Eliminar caracteres no numéricos
        if (regex.test(e.target.value)) {
            e.target.value = e.target.value.replace(regex, '')
        }

        var nroElementosCarrito = this.state.nroElementosCarrito;
        
        if (e.target.value === "") {
            edicion.cantidad = edicion.ultimaUltimaCarga ? edicion.ultimaUltimaCarga : 0;

            if (edicion.cantidad !== edicion.cantidadBackup) {
                nroElementosCarrito = (edicion.cantidad === 0 || edicion.cantidad === null) 
                                        && edicion.cantidadBackup > 0 && nroElementosCarrito > 0
                                            ? this.state.nroElementosCarrito - 1 
                                            : this.state.nroElementosCarrito

                edicion.cantidadBackup = edicion.cantidad;
                if(edicion.ultimaUltimaCarga === 0) {
                    
                    this.setState({ nroSumaRestaFlag: false })
                } 
            }

            let { backendrows } = this.state;
            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
            backendrows[ind] = edicion
            this.setState({
                backendrows,
                nroElementosCarrito
            })
        }
        else {
            let cargaActualCeroFlag = this.state.cargaActualCeroFlag;
            if (edicion.cantidad === 0) {
                cargaActualCeroFlag = true;
            }
            e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
            edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);

            nroElementosCarrito = (edicion.ultimaUltimaCarga != null 
                                    ? this.handlePedidoConEdicionAnterior(edicion) 
                                    : this.handlePedidoSinEdicionAnterior(edicion)
                                )           
                                 
            if((edicion.cantidad != edicion.cantidadBackup)
                ||  (edicion.cantidadBackup === 0 && edicion.ultimaUltimaCarga > 0)){
                if (edicion.cantidad > 0 || 
                    (edicion.cantidad === 0 && 
                        edicion.ultimaUltimaCarga > 0 && 
                        cargaActualCeroFlag
                    )
                ) {
                    this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null, 1);
                    edicion.cantidadBackup = edicion.cantidad;
                    this.setState({ nroSumaRestaFlag: true });
                    
                } else if (edicion.cantidad === 0) {
                    
                    if (edicion.ultimaUltimaCarga === null || edicion.ultimaUltimaCarga === 0) {
                        this.props.actualizarCarrito(edicion.edicionId, -1, null, 1); // Quitar del carrito
                        edicion.cantidadBackup = 0;
                    } else if(edicion.ultimaUltimaCarga > 0) {
                        edicion.cantidadBackup = edicion.cantidad;
                        
                        this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null, 1);
                    } 
                }                
            }
            
            let { backendrows } = this.state;
            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
            backendrows[ind] = edicion
            
            this.setState({
                backendrows,
                nroElementosCarrito,
                cargaActualCeroFlag
            })
        }

        this.props.mostrarNroElementoCar(nroElementosCarrito);
    }
    // funciones input fin

    mostrarSiguienteEdicion = (edicion) => {
        return (
            <div style={{ marginBottom: "-30px" }}>
                <div className="d-flex flex-column flex-sm-row" style={{ paddingBottom: "20px" }}>
                    <div
                        className="d-flex flex-column f-13-5 fw-500"
                        style={{ color: "#343435", paddingRight: "20px", paddingBottom: "5px" }}
                    >
                        {edicion.prefijoDescripcion + " "}
                    </div>
                    <div
                        className="d-flex flex-column flex-sm-row f-13-5 fw-500"
                        style={{ color: "#f40000" }}
                    >
                        {edicion.descripcion +
                            (edicion.descripcion.indexOf(edicion.edicionNumeroFecha) !== -1
                                ? ""
                                : " " +
                                  (edicion.edicionNumeroFecha ? edicion.edicionNumeroFecha : ""))}
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                    <div
                        className="d-flex flex-column f-13-5 fw-500"
                        style={{ color: "#343435", paddingRight: "20px", paddingBottom: "5px" }}
                    >
                        {edicion.ultimaPrefijoDescripcion + " "}
                    </div>
                    <div
                        className="flex-column flex-sm-row f-13-5 fw-500"
                        style={{ color: "#343435", paddingRight: "20px" }}
                    >
                        {edicion.ultimaDescripcion +
                            (edicion.ultimaDescripcion.indexOf(edicion.ultimaEdicionNumeroFecha) !==
                            -1
                                ? ""
                                : " " +
                                  (edicion.ultimaEdicionNumeroFecha
                                      ? edicion.ultimaEdicionNumeroFecha
                                      : ""))}
                    </div>
                    {edicion.ultimaUltimaCarga !== null && edicion.ultimaUltimaCarga >= 0 && (
                        <div
                            className="flex-column flex-sm-row f-13-5 fw-500"
                            style={{ color: "#343435" }}
                        >
                            {"Carga: " + edicion.ultimaUltimaCarga}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    guardarEdPedFutCarritoEnLocalStorage = () => {
        localStorage.setItem(
            "edPedFutCarrito",
            JSON.stringify(this.state.edicionesDePedidoFuturoEnCarrito)
        );
    };

    obtenerEdPedFutCarritoEnLocalStorage = () => {
        return JSON.parse(localStorage.getItem("edPedFutCarrito"));
    };

    sumarElementoCarritoYFlag = (nroElemCarrito, edicionId) => {
        if (this.verificarEdicionEnCarrito(edicionId)) {
            this.setState({ nroSumaRestaFlag: true });
        }
        var nroElemCarritoTemp = nroElemCarrito;

        if (!this.state.nroSumaRestaFlag) {
            nroElemCarritoTemp = nroElemCarrito + 1;
            this.setState({ nroSumaRestaFlag: true });
        }
        
        return nroElemCarritoTemp;
    };

    restarElementoCarritoYFlag = (nroElemCarrito, edicionId) => {
        if (this.verificarEdicionEnCarrito(edicionId)) {
            this.setState({ nroSumaRestaFlag: true });
        }
        var nroElemCarritoTemp = nroElemCarrito;

        if (this.state.nroSumaRestaFlag) {
            nroElemCarritoTemp = nroElemCarrito - 1;
            this.setState({ nroSumaRestaFlag: false });
        }

        return nroElemCarritoTemp;
    };

    restarElementoConEdicionAnterior = (edicion, nroElementosCarrito) => {
        if (edicion.cantidad > 0) {
            edicion.cantidad--;
            edicion.cantidadBackup = edicion.cantidad;

            let elemento = document.getElementById(edicion.edicionId);
            if (elemento) {
                elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById("PF");
                elemento.value = edicion.cantidad;
            }
            let { backendrows } = this.state;
            let ind = backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
            backendrows[ind] = edicion;
            if (edicion.cantidad === 0 && edicion.ultimaUltimaCarga === 0) {
                this.props.actualizarCarrito(edicion.edicionId, -1, null, 1);
            } else {
                this.props.actualizarCarrito(edicion.edicionId, edicion.cantidad, null, 1);
            }

            nroElementosCarrito = edicion.cantidad === edicion.ultimaUltimaCarga - 1
            ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
            : edicion.cantidad === 0 && edicion.ultimaUltimaCarga === 0
                ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                : nroElementosCarrito

            this.setState({
                backendrows,
                nroElementosCarrito,
            });

            this.props.mostrarNroElementoCar(nroElementosCarrito);
        }
    };

    restarElementoSinEdicionAnterior = (edicion, nroElementosCarrito) => {
        if (edicion.cantidad > 0) {
            edicion.cantidad--;
            edicion.cantidadBackup = edicion.cantidad;

            let elemento = document.getElementById(edicion.edicionId);
            if (elemento) {
                elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById("PF");
                elemento.value = edicion.cantidad;
            }

            let { backendrows } = this.state;
            let ind = backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
            
            backendrows[ind] = edicion;
            nroElementosCarrito = edicion.cantidad === 0
                                    ? this.restarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                                    : nroElementosCarrito

            this.setState({
                backendrows,
                nroElementosCarrito,
            });

            let valorActualizarCarrito = edicion.cantidad === 0 ? -1 : edicion.cantidad;

            this.props.actualizarCarrito(edicion.edicionId, valorActualizarCarrito, null, 1);
            this.props.mostrarNroElementoCar(nroElementosCarrito);
        }
    };

    verificarEdicionEnCarrito = (edicionId) => {
        var objetivo = {
            productoId: this.state.producto.productoId,
            edicionId: edicionId ? edicionId : 0,
        };

        var edsEnCarrito = this.state.edicionesDePedidoFuturoEnCarrito
            ? this.state.edicionesDePedidoFuturoEnCarrito
            : [];

        var res = edsEnCarrito.find(
            (epfc) =>
                epfc?.productoId === objetivo.productoId && epfc?.edicionId === objetivo.edicionId
        );

        return res;
    };

    eliminarItemCarrito = (edicionId, productoId, diaSemana, usurioId) => {
            var url = urlServer + '/api/pedidopasadofuturo/carrito/eliminaritem'
            var data = {
                edicionId: edicionId,
                productoId: productoId,
                diaSemana: diaSemana,
                usuarioId: null
            }
    
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "bearer " + localStorage.token
                    }
            }).then((response) => {
                return response
            }).then((result) => {
                if(edicionId == null) edicionId = 0;
                var index = this.state.edicionesDePedidoFuturoEnCarrito.findIndex(x => x.productoId = productoId && x.edicionId == edicionId);
                if(index >= 0)
                {
                    this.state.edicionesDePedidoFuturoEnCarrito.splice(index, 1);
                    this.setState({edicionesDePedidoFuturoEnCarrito: this.state.edicionesDePedidoFuturoEnCarrito.length == 0 ? [] : this.state.edicionesDePedidoFuturoEnCarrito})
                    this.setState({nroElementosCarrito: this.state.edicionesDePedidoFuturoEnCarrito.length})
                }
                validarVencimientoToken(result.message);            
            }).catch((error) => {
                validarVencimientoToken(error.message);
                console.log('error al quitar item del carrito ', error);
            });

            localStorage.removeItem('edPedFutCarrito');
    }

    handleOnChangePedidoFuturoInput = (e, edicion) => {
        let regex = /[^0-9]/g;
        if (regex.test(e.target.value)) {
            e.target.value = 
            e.target.value.replace(regex, "");
        }

        e.target.value = 
            e.target.value === "0"
                ? e.target.value
                : e.target.value.replace(/^(0+)/g, "");

        edicion.cantidad =
            e.target.value === "" || e.target.value < 0
                ? null
                : parseInt(e.target.value);

        let { backendrows } = this.state;
        let ind =
            backendrows.findIndex((e) => e.edicionId === edicion.edicionId);

        backendrows[ind] = edicion;
        this.setState({backendrows});
    }

    handleOnClickPedidoFuturoSumar = (edicion) => {
        if(!edicion.yaPedido) {
            var nroElementosCarrito = this.state.nroElementosCarrito;

            edicion.cantidad++;
            edicion.cantidadBackup = edicion.cantidad;

            let elemento = document.getElementById(edicion.edicionId);
            if (elemento) { elemento.value = edicion.cantidad;
            } else {
                elemento = document.getElementById("PF");
                elemento.value = edicion.cantidad;
            }

            let { backendrows } = this.state;
            let ind =
                backendrows.findIndex((e) => e.edicionId === edicion.edicionId);
            backendrows[ind] = edicion;

            this.props.actualizarCarrito(
                edicion.edicionId,
                edicion.cantidad,
                null,
                1
            );

            var nroElementosCarritoTemp = 
                    edicion.cantidad === edicion.ultimaUltimaCarga + 1 || edicion.cantidad === 1
                    ? this.sumarElementoCarritoYFlag(nroElementosCarrito, edicion.edicionId)
                    : nroElementosCarrito

            this.setState({
                backendrows,
                nroElementosCarrito: nroElementosCarritoTemp,
            });

            this.props.mostrarNroElementoCar(nroElementosCarritoTemp);
        };
    }

    handleOnClickQuitarDelCarrito = (edicion) => {
        var nroElementosCarrito = this.state.nroElementosCarrito;
        edicion.cantidadBackup = edicion.cantidad;
        
        let elemento = document.getElementById(edicion.edicionId);
        if (elemento) {
            elemento.value = edicion.cantidad;
        } else {
            elemento =
                document.getElementById("PF");
            elemento.value = edicion.cantidad;
        }

        let {
            backendrows,
        } = this.state;
        
        let ind = backendrows.findIndex(
                (e) => e.edicionId === edicion.edicionId 
                        && e.productoId === this.state.producto.productoId);

        backendrows[ind] = edicion;
        var nroElementosCarritoTemp = this.verificarEdicionEnCarrito(edicion.edicionId)
                                ? this.restarElementoCarritoYFlag(
                                    nroElementosCarrito,
                                    edicion.edicionId
                                    )
                                : nroElementosCarrito;

        this.setState({
            backendrows,
            nroElementosCarrito: nroElementosCarritoTemp,
        });

        edicion.cantidad = 
            edicion.ultimaUltimaCarga
                ? edicion.ultimaUltimaCarga
                : 0;

        elemento.value = edicion.cantidad;
        this.eliminarItemCarrito(edicion.edicionId, 
            this.state.producto.productoId, 
            edicion.diaSemana, null)

        this.props.mostrarNroElementoCar(nroElementosCarritoTemp);
    }

    render() {
        const {
            backendrows,
        } = this.state;

        return (
            <React.Fragment>
                <div className="pedido" style={{ paddingBottom: "95px" }}>
                    <div>
                        {backendrows
                            .filter((e) => e.prefijoDescripcion.startsWith("Siguiente"))
                            .filter(
                                (a) =>
                                    JSON.stringify(Object.values(a))
                                        .normalize("NFD")
                                        .replace(/\p{Diacritic}/gu, "")
                                        .toLowerCase()
                                        .indexOf(
                                            this.state.busqueda.toLowerCase()
                                        ) !== -1
                            )
                            .map((edicion, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="box justify-content-between align-items-center days"
                                        style={{
                                            padding: "10px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        {this.mostrarSiguienteEdicion(edicion)}
                                        {/*CONTENEDOR*/}
                                        <div
                                            className="w-100 footerItem align-items-center d-flex"
                                            style={{
                                                justifyContent: "right",
                                                paddingTop: "10px",
                                            }}
                                        >
                                            <div
                                                className={`d-flex justify-content-between align-items-center 
                                                            ${"elemento-botones-input-siguiente-edicion"}`
                                                }
                                            >
                                                {/*RESTAR*/}
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        cursor: edicion.yaPedido ? "" : "pointer",
                                                        background: !edicion.cantidad || edicion.yaPedido
                                                                        ? "#FCFCFC"
                                                                        : "#F4F4F4",
                                                        width: "26px",
                                                        height: "26px",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                    }}
                                                    onClick={() => {
                                                        if(edicion.yaPedido) return;

                                                        if (edicion.cantidad >= 0) {
                                                            edicion.ultimaUltimaCarga == null
                                                            ? this.restarElementoSinEdicionAnterior(
                                                                edicion,
                                                                this.state.nroElementosCarrito
                                                                )
                                                            : this.restarElementoConEdicionAnterior(
                                                                edicion,
                                                                this.state.nroElementosCarrito
                                                                );
                                                        }
                                                    }}
                                                >
                                                    <ReactSVG
                                                        src={restar}
                                                        style={{
                                                            color: !edicion.cantidad || edicion.yaPedido
                                                                        ? "#EAEAEA"
                                                                        : "#8E95A5",
                                                            width: "11px",
                                                        }}
                                                        title="Restar"
                                                    />
                                                </div>
                                                {/*INPUT*/}
                                                <div
                                                    className="f-13-5"
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    <input
                                                        style={{
                                                            backgroundColor: this.definirBackGround(edicion),
                                                            border: "#343435 solid 1px",
                                                        }}
                                                        type="number"
                                                        autoComplete="off"
                                                        className="input-of-cantidades text-center"
                                                        maxLength="3"
                                                        id={edicion.edicionId
                                                                ? edicion.edicionId
                                                                : "PF"
                                                        }
                                                        key={edicion.edicionId}
                                                        value={edicion.cantidad == null && edicion.ultimaPrefijoDescripcion === "Primera edición o sin carga en ediciones anteriores" ? 0 : edicion.cantidad }
                                                        disabled={edicion.yaPedido ? true : false}
                                                        onFocus={(e) => {e.target.select();}}
                                                        min={"0"}
                                                        max={"100000"}
                                                        onInput={(e) => (e.target.value = e.target.value.slice(0,6))}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.currentTarget.blur();
                                                            }
                                                        }}
                                                        onChange={(e) => this.handleOnChangePedidoFuturoInput(e, edicion)}
                                                        onBlur={(e) => this.handleInputOnBlurEdiciones(e, edicion)}
                                                    />
                                                </div>
                                                {/*SUMAR*/}
                                                <div
                                                    className="d-flex justify-content-center align-items-center"
                                                    style={{
                                                        cursor: edicion.yaPedido ? "" : "pointer",
                                                        background: edicion.cantidad === 100000 || edicion.yaPedido
                                                                    ? "#FCFCFC"
                                                                    : "#F4F4F4",
                                                        width: "26px",
                                                        height: "26px",
                                                        borderRadius: "50%",
                                                        padding: "5px",
                                                        pointerEvents: edicion.cantidad >= 100000
                                                                        ? "none"
                                                                        : "",
                                                    }}
                                                    onClick={() =>  this.handleOnClickPedidoFuturoSumar(edicion)}
                                                >
                                                    <ReactSVG
                                                        src={sumar}
                                                        style={{
                                                            width: "11px",
                                                            height: "18px",
                                                            color: edicion.yaPedido ? '#EAEAEA' : '#8E95A5',
                                                        }}
                                                        title="Sumar"
                                                    />
                                                </div>
                                                {
                                                    // Confirmar
                                                    edicion.cantidad != null && // porque si borran lo que hay en el input, edicion.cantidad es igual a edicion.ultimaUltimaCarga para los casos de publicaciones sin ediciones anteriores.
                                                    edicion.cantidad === edicion.ultimaUltimaCarga &&
                                                    //!this.verificarEdicionEnCarrito(edicion.edicionId) &&
                                                    this.state.nroElementosCarrito == 0 &&
                                                    edicion.ultimaUltimaCarga != 0 
                                                    ? (
                                                        <div
                                                            className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                cursor: edicion.yaPedido ? "" : "pointer",
                                                                background:
                                                                    "#C7E6F9",
                                                                width: "26px",
                                                                height: "26px",
                                                                borderRadius: "50%",
                                                                padding: "5px",
                                                                pointerEvents: "",
                                                                margin: "3px",
                                                                marginLeft: "10px",
                                                            }}
                                                            onClick={() => this.handleOnClickPedidoFuturoConfirmar(edicion)}
                                                        >
                                                            <ReactSVG
                                                                src={tick}
                                                                style={{
                                                                    width: "11px",
                                                                    height: "18px",
                                                                    color: edicion.yaPedido ? '#EAEAEA' : '#4e80f5'
                                                                }}
                                                                title="Confirmar Carga"
                                                            />
                                                        </div>
                                                    ) 
                                                    : this.state.nroElementosCarrito > 0 &&
                                                        this.verificarEdicionEnCarrito(edicion.edicionId) 
                                                        ? (
                                                        <div className="d-flex align-items-center">
                                                            <ReactSVG
                                                                src={eliminar}
                                                                style={{
                                                                    cursor: edicion.yaPedido ? "" : "pointer",
                                                                    margin: "3px",
                                                                    width: "26px",
                                                                    height: "26px",
                                                                    color: "#224372",
                                                                    background:
                                                                        "#C7E6F9",
                                                                    borderRadius:
                                                                        "50%",
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                                title="Quitar del Carrito"
                                                                onClick={() => this.handleOnClickQuitarDelCarrito(edicion)}
                                                            />
                                                        </div>
                                                        ) 
                                                        : (
                                                            <div className="d-flex align-items-center">
                                                                <ReactSVG
                                                                    src={eliminar}
                                                                    style={{
                                                                        margin: "3px",
                                                                        width: "26px",
                                                                        height: "26px",
                                                                        color: "grey",
                                                                        background: "lightgrey",
                                                                        borderRadius: "50%",
                                                                        marginLeft: "10px",
                                                                    }}
                                                                />
                                                            </div>  
                                                        )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div> 
            </React.Fragment>
        );
    }
}
