import * as React from "react";
import { Title } from "../components/title";
import Notificacion from "./notif";
import urlServer from "../server";
import * as timeago from "timeago.js";
//import TimeAgo from "timeago-react";
import es from "timeago.js/lib/lang/es";
import ReactGA from "react-ga";
import backArrow from "../assets/backArrow.svg";
import NotificacionPopUp from "./NotificacionPopUp";
import { ReactSVG } from "react-svg";
import "./Notificaciones.css";
import { validarVencimientoToken } from "../localStorageManager";

timeago.register("es", es);

/*const DateInitFormatter = (value) => {
  if (value) {
    return value
      .replace(
        /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/,
        "$3/$2/$1 $4:$5:$6"
      )
      .slice(0, -14);
  } else {
    return "";
  }
};
*/

export default class Notificaciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarNotifLeidas: false,
      busqueda: "",
      busquedaPorNotifLeidas: "",
      notificaciones: [],
      notificacionesLeidas: [],
      notificacionesNoLeidas: [],
      verNotif: false,
      currentNotif: {},
    };
  }

   
    setNotif = (verNotif, notif) => {
        this.setState({
            verNotif: verNotif,
            currentNotif: {...notif}
        })
    }

  setVerNotif = (verNotif) => {
    this.setState({ verNotif: verNotif });
  };

  traerNotificacionesAlLeer = (notif) => {
    this.setState(
      {
        notificaciones: [...notif],
      },
      () => {
        this.setearNuevasNotif(notif);
      }
    );
  };

  setCurrentNotif = (notif) => {
    this.setState({ currentNotif: { ...notif } });
  };
  // inicio life cycle methods -----------------------------------------------------------------------------------

  componentDidMount() {
    document.title = "Notificaciones";

    ReactGA.event({
      category: "Notificaciones",
      action: "Mostrar Notificaciones",
    });

    this.obtenerNotificaciones();
  }

  // fin life cycle methods -----------------------------------------------------------------------------------

  // inicio UI --------------------------------------------------------------------- funciones que renderizan html

  renderTitleInputPestañas = () => {
    return (
      <div className="">
        <Title title="Notificaciones" />

        <div className="w-100">
          <input
            className="w-100 form-control"
            type="text"
            placeholder="Buscar"
            onChange={(e) => {
              this.setState({ busquedaPorNotifLeidas: e.target.value });
              this.setState({ busqueda: e.target.value });
            }}
            value={this.state.busqueda}
          />
        </div>

        <div
          className="tabs d-flex justify-content-between  "
          style={{ borderBottom: "1px solid gray", marginBottom: "18px" }}
        >
          <div
            className={
              "tablinks col-6 text-center" +
              (!this.state.mostrarNotifLeidas ? " active" : "")
            }
            onClick={(e) => {
              this.setState({ mostrarNotifLeidas: false });
            }}
          >
            Nuevas Notificaciones
          </div>
          <div
            className={
              "tablinks col-6 text-center" +
              (this.state.mostrarNotifLeidas ? " active" : "")
            }
            onClick={(e) => {
              this.setState({ mostrarNotifLeidas: true });
            }}
          >
            Todas las notificaciones
          </div>
        </div>
      </div>
    );
  };

  renderNotificaciones = () => {
    const { mostrarNotifLeidas, todasLasNotif, notificacionesNoLeidas } =
      this.state;
    return (
      <div style={{ paddingBottom: "50px" }}>
        {this.renderTitleInputPestañas()}
        {(!mostrarNotifLeidas ? notificacionesNoLeidas : todasLasNotif)?.map(
          (notificacion) => {
            return (
              <Notificacion
                setNotif={this.setNotif}
                notificacion={notificacion}
                key={notificacion.notificacionId}
              />
            );
          }
        )}
      </div>
    );
  };

  // fin UI --------------------------------------------------------------------- funciones que renderizan html

  // inicio consulta informacion ------------------------------------------------ funciones que obtienen datos

  setearNuevasNotif(notificaciones) {
    let todasLasNotif = [...notificaciones];
    let notificacionesNoLeidas = notificaciones.filter(
      (notificacion) => notificacion.nuevaNotificacion
    );
    this.setState({
      todasLasNotif: [...todasLasNotif],
      notificacionesNoLeidas: [...notificacionesNoLeidas],
    });
  }

    obtenerNotificaciones() {
        let data = {
            puntoVentaId: null,
            notificacionId: null
        }
        fetch(urlServer + '/api/notificacion/buscar', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + localStorage.token
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                throw response;
            }
        }).then((result) => {
            this.setState({
                notificaciones: result
            }, () => {this.setearNuevasNotif(result)})
            
            
        }).catch((error) => {
            validarVencimientoToken(error.message);
            console.log(error);
        })
    }

  visualizarNotificacion() {
    const { verNotif, currentNotif } = this.state;
    if (verNotif) {
      return (
        <NotificacionPopUp
          notificacion={currentNotif}
          setVerNotif={this.setVerNotif}
          history={this.props.history}
          traerNotificacionesAlLeer={this.traerNotificacionesAlLeer}
          updateNumeritoCampanaAlLeerNotif={
            this.props.history.location.data
              ? this.props.history.location.data
                  .updateNumeritoCampanaAlLeerNotif
              : null
          }
        />
      );
    }
  }

  // fin consulta informacion ------------------------------------------------ funciones que obtienen datos

  render() {
    return (
      <div
        id="notificaciones"
        className="container"
        style={{ marginBottom: "15px" }}
      >
        <div
          id="backarrow"
          className="position-fixed back-arrow-box-notif"
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          <ReactSVG src={backArrow} />
        </div>
        {this.renderNotificaciones()}
        {this.visualizarNotificacion()}
      </div>
    );
  }
}
