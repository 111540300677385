import React from "react";
import { ReactSVG } from "react-svg";
import { IntlProvider, FormattedDate } from "react-intl";
//import urlServer from "../server";
import eyeSolidNotif from "../assets/eyeSolidNotif.svg";
import { validarVencimientoToken } from "../localStorageManager";

const Notificacion = (props) => {
  const { notificacion } = props;

  // hooks fin --------------------------------------------------------------------------------------------------------------------------

    /*const marcarComoLeidaNotificacion = async  (notificacionId) => {
        const headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            Authorization: 'Bearer ' + localStorage.token
        }
    
        const data = {
            puntoVentaId: null,
            notificacionId
        }
    
        const url = urlServer + "/api/notificacion/leer"
    
        const respuesta = await fetch(url , {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers: headers
        })
        .then(response => response.json() )
        .then(result => console.log(result))
        .catch(error => {
            validarVencimientoToken(error.message);
            console.log('error', error);
            return;
        })
    
        return respuesta
    }
*/
  var fechaDesdeFormateada = new Date(notificacion.fechaUltimaModificacion);
  return (
    <div
      key={notificacion.notificacionId}
      className="container daysNotif itemNovedadVigente mb-4"
    >
      <div className="span-item-nov pl-1 pr-1 d-flex text-justify">
        {notificacion.titulo}
      </div>
      <div className=" d-flex justify-content-between align-items-left">
        <div
          className="col-8 col-md-10 text-left pl-1"
          style={{ paddingRight: "0px", paddingBottom: "3px", display: "flex", alignItems: "end", fontWeight: 500 }}
        >
          Fecha: 
          <IntlProvider locale="en-US">
            <FormattedDate
              value={fechaDesdeFormateada}
              year="numeric"
              month="numeric"
              day="numeric"
              hour="numeric"
              minute="numeric"
              hour24={true}              
              pattern="dd/mm/yyyy hh:mm"
            />
          </IntlProvider>
        </div>
        <div className="col-4 col-md-2 d-flex justify-content-between align-items-center p-0">
          <div
            className="f-13-5"
            style={{
              fontWeight: 500,
              color: notificacion.leida ? "rgb(34, 67, 114)" : "#ea3f3f",
            }}
          >
            {notificacion.leida ? "Leida" : "No Leida"}
          </div>
          <div>
            <ReactSVG
              style={{
                cursor: "pointer",
                margin: "3px",
                width: "23px",
                height: "23px",
                color: "#224372",
                background: "#C7E6F9",
                borderRadius: "50%",
              }}
              src={eyeSolidNotif}
              onClick={() => {
                // escribir codigo para levantar un quick view de la notificacion.
                props.setNotif(true, notificacion);
                // props.setCurrentNotif(notificacion)
                // props.setVerNotif(true)
                // marcarComoLeidaNotificacion(notificacion.notificacionId)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notificacion;
