import * as React from 'react'
import urlServer from '../server'
import { Title } from './title'
import ReactGA from 'react-ga';
import InfiniteScroll from "react-infinite-scroll-component";
import ModalSingleButton from '../components/modalsinglebutton';
import { validarVencimientoToken } from "../localStorageManager";

export default class CangePorPuntosHistorial extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            historialCanje: [],
            terminosCondiciones: '',
            busqueda: null,
            hasMore: true,
            puntos: null,
            verTerminosCondiciones: false,
            margen: 0,
            width: 0,
           // incentivoHabilitado: false,
            incentivoPedidoCompraHabilitado: false,
            canjeHabilitado: false,
        }
    }


    componentDidMount = () => {
        this.historialCanjeDePuntos();
        this.obtenerTerminosyCondiciones();
        this.puntos();

        // Para evitar que la pantalla se alinea a izquierda al abrir modal de terminos y condiciones
        var widthDiv = document.getElementsByClassName('tienda container')[0].clientWidth;
        var widthScreen = window.innerWidth;
        var margen = (widthScreen - widthDiv) / 2;
        this.setState({margen: margen, width: widthDiv});
        const self = this;
        window.addEventListener('resize', function(event) {
            if(document.getElementsByClassName('tienda container')[0])
            {
                var widthDiv = document.getElementsByClassName('tienda container')[0].clientWidth;
                var widthScreen = window.innerWidth;
                var margen = (widthScreen - widthDiv) / 2;
                self.setState({margen: margen, width: widthDiv});
            }
        }, true);

        this.obtenerConfiguracionIncentivoHabilitado()
        this.obtenerConfiguracionCanjeHabilitado()
    }

    obtenerConfiguracionIncentivoHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Incentivos.PedidoCompra.Habilitado',
            entidadId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        })
            .then((result) => {
                validarVencimientoToken(result.message);
                this.setState({ incentivoPedidoCompraHabilitado: result[0].valor === "1" ? true : false })
            }).catch((error) => {
                validarVencimientoToken(error.message);
                console.log('error al obtener la configuracion de incentivo habilitado')
            })
    }

    obtenerConfiguracionCanjeHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Canje.Habilitado',
            entidadId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        })
            .then((result) => {
                validarVencimientoToken(result.message);
                this.setState({ canjeHabilitado: result[0].valor === "1" ? true : false })
            }).catch((error) => {
                validarVencimientoToken(error.message);
                console.log('error al obtener la configuracion de incentivo habilitado')
            })
    }
    mostrarCanje = () => {
        var result = 'none';
        //muesto si sumo cuando compra en pesos o si puedo hacer canje
        if (this.state.incentivoPedidoCompraHabilitado || this.state.canjeHabilitado) {
            result = 'block';
        }
        return result;
    }

    historialCanjeDePuntos = () => {
        ReactGA.event({
            category: 'HistorialCanje',
            action: 'Obtener Historial de Compras y Canje'
        });

        var nroFilas = this.state.historialCanje.length;
        var url = urlServer + '/api/incentivo/historial'
        var data = {
            puntoVentaId:  null,
            palabraBuscar: this.props.desdeCanje ? this.props.busqueda: this.state.busqueda,
            pageSize: nroFilas
        }
        fetch(url,{
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            validarVencimientoToken(result.message);
            if(result.length === 0)
            {
                this.setState({historialCanje: []});
            }
            else{
                this.setState({historialCanje: result});
            }
        }).catch((error) => {
            validarVencimientoToken(error.message);
            console.log(error, ' error al obtener historial de canje');
        })
    }

    obtenerTerminosyCondiciones = () => {
        ReactGA.event({
            category: 'HistorialCanje',
            action: 'Obtener Terminos y Condiciones'
        });

        var url = urlServer + '/api/configuracionItem/consultar/configuracion'
        var data = {
            entornoId: 5,
            ambitoId: 4,
            clave: 'Incentivos.Terminos.Condiciones',
            entidadId: null,
            incluirItemsSinConfigurar: true
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            validarVencimientoToken(result.message);
            this.setState({terminosCondiciones: result[0].valor})
        }).catch((error) => {
            validarVencimientoToken(error.message);
            console.log('error ', error);
        })

    }

    puntos = async () => {
       
        this.setState({
            loading: true
        })

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token
        }

        const data = {
            nombreTienda: "Tienda Agea",
            puntoVentaId:null
        }

        const url = urlServer + "/api/incentivo/puntosacumulados/obtener"
        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(resultPuntos => {
                validarVencimientoToken(resultPuntos.message);

                var disponible = resultPuntos[0].disponible;
                var diasRestantes = resultPuntos[0].diasRestantes;
                var darAviso = resultPuntos[0].darAviso

                this.setState({
                    puntos: disponible,
                    diasRestantes: diasRestantes,
                    darAviso: darAviso,
                    loading: false
                })
            })
            .catch(error => {
                validarVencimientoToken(error.message);
                console.log('error', error)
                this.setState({
                    loading: false
                })
            });
        return respuesta
    }

    showAviso = () => {
        const { darAviso} = this.state;
        
       return darAviso ? 'flex' : 'none'
    }

    render() {
        const { historialCanje, verTerminosCondiciones } = this.state
        
        return (
            <div id='historial-puntos' style={{position: verTerminosCondiciones ? 'fixed': '', width: this.props.desdeCanje && verTerminosCondiciones ?  'calc(100% - 25%)' : '100%'}}>
                <div className={!this.props.desdeCanje ? "tienda container text-left" : "w-100"} 
                            style={{paddingBottom: !this.props.desdeCanje ? '25px': '0px'}}
                            id='historial-punto-id'  >
                    <div style={{display: this.props.desdeCanje ? "none":"block"}}>
                        <Title classes="" title={"Historial de Puntos y Canje"} />
                    </div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#224372', fontSize:'16px', paddingBottom:'5px', display: this.props.desdeCanje ? "none":"block"}}>
                        <div style={{ display: this.mostrarCanje() }}>
                            <span>Puntos: {this.state.puntos}</span><br />
                            <div className='alerta-puntos' style={{display: this.showAviso()}}> 
                                <span>Tus puntos se venceran en {this.state.diasRestantes} {this.state.diasRestantes === 1 ? 'dia' : 'dias'}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="w-100" style={{ marginBottom: '23px', display: this.props.desdeCanje ? "none":"block" }}>
                                                <input className="w-100 form-control"
                                                    type="text"
                                                    placeholder="Buscar"
                                                    onChange={(e) => {
                                                        this.setState({ busqueda: e.target.value }, () => {
                                                            this.historialCanjeDePuntos();
                                                        });
                                                    }}

                                                    value={this.state.busqueda} />
                                            </div>

                    <div className="cards container w-100" style={{backgroundColor:'#F4F4F4', height:'100%', marginBottom: '100px', paddingLeft: '0px', paddingRight: '0px'}}>
                        {<div className="box" style={{ backgroundColor: '#e0dede' }}>
                            <div className="d-flex justify-content-between w-400">
                                <div className="desc-reclamo">
                                    <span className="ml-1" style={{ fontWeight: 'bold' }}>Fecha</span>
                                </div>
                                <div className="desc-reclamo">
                                    <span className="ml-1" style={{ fontWeight: 'bold' }}>Movimientos</span>
                                </div>
                                <div className="desc-reclamo">
                                    <span className="ml-1" style={{ fontWeight: 'bold' }}>Puntos</span>
                                </div>
                            </div>
                        </div>}
                                                    
                        
                        <div className='movimientos-contenedor pb-4' >
                            <InfiniteScroll
                                dataLength={this.state.historialCanje.length}
                                next={this.historialCanjeDePuntos}
                                hasMore={this.state.hasMore}
                                >
                                
                                {historialCanje.map((row, index) => (
                                    <div key={index} className="box" style={{ paddingLeft: '2px', paddingRight: '8px' }}>
                                        <div className="d-flex justify-content-between w-400" >
                                            <div className="desc-reclamo" >
                                                <span className="ml-1" style={{ color: row.incentivoTipoId === 9 ? 'black' : ( row.puntos >= 1 ? 'green' : 'red' ) }}>
                                                    {row.fechaIncentivo}
                                                </span>
                                            </div>
                                            <div className="desc-reclamo" style={{ paddingLeft: '15px', paddingRight: '15px', textAlign: 'center' }}>
                                                <span className="ml-1" style={{ color: row.incentivoTipoId === 9 ? 'black' : ( row.puntos >= 1 ? 'green' : 'red' ) }}>
                                                    {row.movimiento}
                                                </span>
                                            </div>
                                            {row.puntos > 1 || row.puntos < -1 ?
                                            <div className="desc-reclamo">
                                                <div className="ml-1" style={{ color: row.incentivoTipoId === 9 ? 'black' : ( row.puntos >= 1 ? 'green' : 'red' ) }}>
                                                    {row.puntos} <br /> {'Puntos'}
                                                </div>
                                            </div>
                                            :
                                            <div className="desc-reclamo">
                                                <div className="ml-1" style={{ color: row.incentivoTipoId === 9 ? 'black' : ( row.puntos >= 1 ? 'green' : 'red' ) }}>
                                                    {row.puntos} <br /> {'Punto'}&nbsp;
                                                </div>
                                            </div>
                                            }
                                        </div>

                                    </div>
                                ))}
                            </InfiniteScroll>
                        </div>
                        
                        
                    </div>
                </div>
                <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-terminos-condiciones">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        onClick={() => {
                            this.setState({verTerminosCondiciones: true})

                        }}
                        style={{
                          color: "#d33",
                          fontSize: "12px",
                          textAlign: "center",
                          cursor: "pointer",
                          borderRadius: "16px",
                          width: "190px",
                          height: "33px",
                          fontWeight: 'bold'
                        }}
                      >
                        Ver t&eacute;rminos y condiciones
                      </div>
                </div>

                <ModalSingleButton verModal = {verTerminosCondiciones} 
                            titulo = "T&eacute;rminos y Condiciones"
                            accion ={() => {
                                this.setState({verTerminosCondiciones: false})
                            }}
                            textoBoton = 'Cerrar'>
                    <div className="text-justify" style={{fontSize:'11px'}} dangerouslySetInnerHTML={{__html: this.state.terminosCondiciones}}></div>
                </ModalSingleButton>
            </div>
        )
    }
}