import * as React from 'react';
import { Title } from "../components/title";
import Select from 'react-select';
import { ReactSVG } from 'react-svg';
import eraser from '../assets/eraser.svg';
import urlServer from '../server';
import Spinner from "../components/spinner";
import ReactGA from 'react-ga';
import InfiniteScroll from "react-infinite-scroll-component";
import { loadImageArray } from 'quagga';
import { validarVencimientoToken } from "../localStorageManager";

export default class CargasDevoluciones extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            accordion: false,
            initPedidos: {
                "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 7)).toISOString().slice(0,10),
                "fechaHasta": new Date().toISOString().slice(0,10),
                "puntoVentaId": JSON.parse(localStorage.infoToken).entidad_id,
                "productoId": null,
                "edicionId": null,
              },
            postPedidos: {
                "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 7)).toISOString().slice(0,10),
                "fechaHasta": new Date().toISOString().slice(0,10),
                "puntoVentaId": JSON.parse(localStorage.infoToken).entidad_id,
                "productoId": null,
                "edicionId": null,
              },
            productoSelected: { value: null, label: 'Seleccione una publicación' },
            selectedOption: { value: null, label: "seleccione una edición" },
            ediciones: [],
            options: [],
            edicionesResultado: [],
            hasMore: true
        }
    }

    componentDidMount = () => {
        this.publicaciones();
        this.filtrar();
    }

    publicaciones = async () => {
        const headers = {
          "Content-Type": "application/json",
          "Accept": "application/json",
          Authorization: 'Bearer ' + localStorage.token,
        }
        
        const data = {
            puntoVentaId: null,
            fechaDesde: this.state.postPedidos.fechaDesde,
            fechaHasta: this.state.postPedidos.fechaHasta
        }
        const url = urlServer + "/api/distribucion/consultaCargaDevolucion/producto"

        const respuesta = await fetch(url, {
          method: 'POST',
          redirect: 'manual',
          body: JSON.stringify(data),
          headers
        })
        .then(response => response.json())
        .then(result => {
          validarVencimientoToken(result.message);
          let options = result.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
          })
          options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
          ]
          this.setState({
            options
          })
          validarVencimientoToken(result.message);
        }).catch(error => {
          console.log('error', error)
          validarVencimientoToken(error.message);
        });
    }

    clearFilter = () => {
        const fechaDesde = document.getElementById('fecha-desde')
        const fechaHasta = document.getElementById('fecha-hasta')

        fechaDesde.value = new Date((new Date()).setDate((new Date()).getDate() - 7)).toISOString().slice(0, 10)
        fechaHasta.value = new Date().toISOString().slice(0, 10)
        fechaHasta.min = fechaDesde.value

        this.setState({
            productoSelected: { value: null, label: 'Seleccione una publicación' },
            selectedOption: { value: null, label: "seleccione una edición" },
            ediciones:[],
            postPedidos: this.state.initPedidos,
            fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
            fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
        }, () => this.publicaciones())
    }

    handleChange = async (selected) => {
        await this.setState({
            productoSelected: selected,
            postPedidos: {
                ...this.state.postPedidos,
                productoId: selected.value
            }
        });
        this.setState({selectedOption: { value: null, label: "seleccione una edición" }})
        this.setState({postPedidos: {... this.state.postPedidos, edicionId: null }})
        this.ediciones()
    };

    handleEdicionChange = (selected) => {
      console.log(selected)
      this.setState({
        selectedOption: selected,
        postPedidos: {
          ...this.state.postPedidos,
          edicionId: selected.value
        }
      })
    }

    ediciones = async () => {
        if(this.state.postPedidos.productoId) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: 'Bearer ' + localStorage.token,
            }
            const data = {
                productoId: this.state.postPedidos.productoId,
                puntoVentaId: null,
                fechaDesde: this.state.postPedidos.fechaDesde,
                fechaHasta: this.state.postPedidos.fechaHasta
            }
            const url = urlServer + "/api/distribucion/consultaCargaDevolucion/edicion"
  
            const respuesta = await fetch(url, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            })
            .then(response => response.json())
            .then(result => {
              validarVencimientoToken(result.message);
              let options = result.map(ed => {
                return {value: ed.edicionId, label: ed.descripcion}
              })
              options = [
                {value: null, label: 'Seleccione una edición'},
                ...options
              ]
              this.setState({
                ediciones: options
              })
              validarVencimientoToken(result.message);

            }).catch(error => {
                console.log('error', error)
                validarVencimientoToken(error.message);
            });
            return respuesta
        }
    }

    ocultarFiltros = () => {
        let fechaHasta = document.getElementById('fecha-hasta')
        let fechaDesde = document.getElementById('fecha-desde')
        fechaHasta.min = fechaDesde.value

        this.setState({
            filtrosMarginTop: false,
            postPedidos: {
                ...this.state.postPedidos,
                fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
                fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
            }
        })
        fechaHasta.value = fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)

      const div = document.getElementById('filtro')
      let divMarginTop = div.getElementsByClassName('filter')[0]
      const marginTop = divMarginTop.offsetHeight
      if (div.className.indexOf('overflow-hidden') !== -1) {
        divMarginTop.style.marginTop = "0"
        setTimeout(() => {div.className = div.className.replace('overflow-hidden', '')}, 550)
      } else {
        div.className += 'overflow-hidden'
        divMarginTop.style.marginTop = `${-marginTop - 3}px`
        }
    }

    filtrar = async () => {
        ReactGA.event({
            category: 'Cargas/Devoluciones',
            action: 'Cargar grilla'
          });
        
        var nroFilas = this.state.edicionesResultado.length;
        var url = urlServer + '/api/distribucion/consultaCargaDevolucion';
        var data = {
            productoId: this.state.postPedidos.productoId,
            edicionId: this.state.postPedidos.edicionId,
            puntoVentaId: null,
            fechaDesde: this.state.postPedidos.fechaDesde,
            fechaHasta: this.state.postPedidos.fechaHasta,
            pageSize: nroFilas
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'Bearer ' + localStorage.token,
                'Content-Type': 'application/json' 
            }
        }).then((response) => {
            return response.json();
        }).then(result => {
            this.setState({edicionesResultado: result})
            this.setState({loading: false})
            validarVencimientoToken(result.message);

        }).catch((error) => {
            console.log('error al cargar grilla', error);
            validarVencimientoToken(error.message);
            this.setState({loading: false})
        });
    }

    exportar = async (reporteTipo) => {
        ReactGA.event({
            category: 'Cargas/Devoluciones',
            action: 'Exportar'
          });
    
        document.getElementById('open-modal').click()

        var url = urlServer + '/api/distribucion/consultaCargaDevolucion/exportar'
        var data = {
            productoId: this.state.postPedidos.productoId,
            edicionId: this.state.postPedidos.edicionId,
            puntoVentaId: null,
            fechaDesde: this.state.postPedidos.fechaDesde,
            fechaHasta: this.state.postPedidos.fechaHasta,
            reporteTipo: reporteTipo
        };
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.arrayBuffer();
        }).then((result) => {
            document.getElementById('close-modal').click();
            var resultcontent = result;
            var byteArray = new Uint8Array(resultcontent);
            var mimeType = '';
            if(reporteTipo === 'pdf')
                mimeType = 'application/pdf';
            else
                mimeType = 'application/vnd.ms-excel';
            const newBlob = new Blob([byteArray], {type: mimeType });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            if (window.__POIsNativeMode) {
                if(reporteTipo === 'pdf')
                    link.href = `blobDownload/EntregaDevolucion.pdf/${byteArray}`;
                else
                    link.href = `blobDownload/EntregaDevolucion.xls/${byteArray}`;
            } else {
                link.href = data;
                if(reporteTipo === 'pdf')
                    link.download = `EntregaDevolucion.pdf`;
                else    
                    link.download = `EntregaDevolucion.xls`;
                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }
            link.click();
            validarVencimientoToken(result.message);
        }).catch((error) => {
            document.getElementById('close-modal').click();
            console.log('error al exportar', error);
            validarVencimientoToken(error.message);
        })
    }

    render() {
        const { loading, postPedidos, edicionesResultado} = this.state

        return (
            <div id='cargasdevoluciones' className="container text-left">
                <div>
                    <Title 
                        classes=""
                        title="Entregas y Devoluciones"
                        accordion={this.state.accordion}
                        alterAccordion={() => {this.setState({accordion: !this.state.accordion}); this.ocultarFiltros();}} 
                    />
                    <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#loader" style={{display: 'none'}}>
                    </button>
                    <div className="modal" id="loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content text-center">
                            <div className="modal-body">
                            <Spinner />
                            </div>
                            Exportando...
                            <div className="modal-footer" style={{display: 'none'}}>
                            <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div id="filtro" className={"row overflow-hidden"}>
                        <div className={"filter"}> 
                        <div className=" d-flex justify-content-between" style={{marginBottom: '10px'}}>
                            <div style={{width: '33%'}} >
                            <div style={{marginBottom: '12px'}} >
                                Desde
                            </div>
                            <div>
                                <input id="fecha-desde"
                                    className="form-control filter-input"
                                    value={this.state.postPedidos.fechaDesde}
                                    type="date"
                                    onChange={e => {
                                                let fechaHasta = document.getElementById('fecha-hasta')
                                                fechaHasta.min = e.target.value
                                                this.setState({
                                                postPedidos: {
                                                    ...this.state.postPedidos, 
                                                    fechaDesde: e.target.value,
                                                    fechaHasta: fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                                }
                                                }, () => {
                                                    this.publicaciones();
                                                })
                                                fechaHasta.value = fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                    }} />
                            </div>
                            </div>
                            <div style={{width: '33%'}} >
                            <div style={{marginBottom: '12px'}} >
                                Hasta
                            </div>
                            <div>
                                <input id="fecha-hasta"  className="form-control filter-input" type="date" onChange={e => {this.setState({postPedidos: { ...this.state.postPedidos, fechaHasta: e.target.value} }, () => {this.publicaciones();}); }} />
                            </div>
                            </div>
                            <div style={{width: '24%'}} >
                            <div className="eraser" onClick={() => this.clearFilter()}>
                                LIMPIAR
                                <ReactSVG src={eraser} style={{width: '16px'}} />
                            </div>
                            <div style={{width: '100%' }} className="btn button-theme " 
                                onClick={() => {
                                    this.setState({loading: true})
                                    this.filtrar();
                            }}> Filtrar </div>
                            </div>
                        </div>                      
                        
                            <div className="w-100 " style={{marginBottom: '10px'}}>
                                <Select
                                    value={this.state.productoSelected}
                                    placeholder={'producto'}
                                    onChange={this.handleChange}
                                    options={this.state.options}
                                />
                        </div>
                        {this.state.postPedidos.productoId ?
                        <div className="w-100 ">
                            <Select
                            value={this.state.selectedOption}
                            onChange={this.handleEdicionChange}
                            options={this.state.ediciones}
                            />
                        </div>: null}
                        </div>
                    </div>
                    {loading ? (<Spinner style={{ fontSize: "8px" }} />)
                    :
                    (
                        <div className='cards' style={{paddingBottom: '160px'}}>
                            <InfiniteScroll
                                dataLength={this.state.edicionesResultado.length}
                                next={this.filtrar}
                                hasMore={this.state.hasMore}
                            >
                            {edicionesResultado.map((edicion, index) => (
                                <div key={index} className='box'>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div style={{
                                                    color: "#EA3F3F",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    textAlign: "justify",
                                                }}>
                                                {edicion.productoDescripcion}
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="desc-reclamo" style={{ marginTop: "5px" }}>
                                            ED:
                                            <span className="ml-1">
                                                {edicion.edicionDescripcion
                                                    .replace("Ed. ", "")
                                                    .replace("Ed.", "")}
                                            </span>
                                        </div>
                                        <div>
                                            &nbsp;
                                        </div>
                                        
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="desc-reclamo" style={{ marginTop: "5px" }}>
                                            PRECIO DE TAPA:
                                            <span className="ml-1">
                                                ${edicion.precioTapa}
                                            </span>
                                        </div>
                                        <div className="desc-reclamo" style={{ marginTop: "5px" }}>
                                            PRECIO DE VENTA:
                                            <span className="ml-1">
                                                ${edicion.precioVenta}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="desc-reclamo">
                                            CARGA:
                                            <span className="ml-1">{edicion.carga}</span>
                                        </div>                                                    
                                        <div className="desc-reclamo">
                                            DEVOLUCIONES:
                                            <span className="ml-1">{edicion.devoluciones}</span>
                                        </div> 
                                    </div>
                                </div>
                            ))}
                            </InfiniteScroll>
                        </div>
                    )}
                </div>
                <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                    <div className="d-flex justify-content-center align-items-center" 
                        style={{background: "#224372", 
                            color: "white", 
                            fontSize: "12px", 
                            textAlign: "center", 
                            cursor: "pointer", 
                            borderRadius: "16px", 
                            width: "98px", 
                            height: "33px"}} onClick={() => {
                                this.exportar('pdf')
                            }}>
                        Exportar PDF
                    </div>
                    &nbsp;
                    <div className="d-flex justify-content-center align-items-center" 
                        style={{background: "#224372", 
                            color: "white", 
                            fontSize: "12px", 
                            textAlign: "center", 
                            cursor: "pointer", 
                            borderRadius: "16px", 
                            width: "98px", 
                            height: "33px"}} onClick={() => {
                                this.exportar('excel')
                            }}>
                        Exportar EXCEL
                    </div>
                </div>
            </div>
        )
    }
}
