const getNombreYapellido = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const nombreYappelido = {
        nombre: infoToken.nombre,
        apellido: infoToken.apellido
    };
    return nombreYappelido
}

const getTelefono = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const telefono = infoToken.telefono;

    return telefono
}

const getUsuarioId = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const usuario_id = infoToken.usuario_id;

    return usuario_id
}

const getPuntoVentaIdId = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const entidad_id = infoToken.entidad_id;

    return entidad_id
}

const getEmail = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const email = infoToken.sub

    return email
}

const getPermisos = () => {
    let infoToken = JSON.parse(localStorage.getItem('infoToken'));
    const permisos = infoToken ? infoToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] : []

    return permisos
}

const getExpiracionToken = () => {
    let expiracion = localStorage.getItem('expires_at')

    return expiracion    
}

const getUltimoModuloAlmacenado = () => {
    let ultimoModuloAlmacenado = localStorage.getItem('UltimoModulo');

    return ultimoModuloAlmacenado
}

const getUrlImagenesPO = () => {
    let ultimoModuloAlmacenado = localStorage.getItem('UrlImagenesPO');

    return ultimoModuloAlmacenado != "undefined" ? ultimoModuloAlmacenado : 'https://paradaonline.agea.com.ar/s/img/tapas';
}

const setUltimoModuloAlmacenado = () => {
    var modulo = window.location.pathname;
    localStorage.setItem('UltimoModulo', modulo);
}

const setUrlImagenesPO = (urlNueva) => {
    if (getUrlImagenesPO() != urlNueva) {
        localStorage.setItem('UrlImagenesPO', urlNueva);
    }
    
}
const validarVencimientoToken =(mensaje)=> {
    if (mensaje) {
        if ((mensaje.indexOf("Authorization has been denied for this request.") !== -1 || mensaje.indexOf("Unexpected token") !== -1)) {
            
            //let fechaActual = new Date();
            //let fechaExpires = new Date(localStorage.expires_at);
            //if (mensaje.indexOf("interceptadoXSW") == -1 || fechaExpires < fechaActual) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            //}
        }
    }
    return;
}

export { getTelefono, getNombreYapellido, getEmail, getUsuarioId, getPuntoVentaIdId, getPermisos, getExpiracionToken, getUltimoModuloAlmacenado, setUltimoModuloAlmacenado, getUrlImagenesPO, setUrlImagenesPO, validarVencimientoToken }