import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import eraser from '../assets/eraser.svg'
import Spinner from '../components/spinner';
import urlServer from '../server'
import AsyncSelect from 'react-select/async';
import backArrow from '../assets/backArrow.svg';
import withReactContent from 'sweetalert2-react-content'
import {Modal} from '@material-ui/core';
import ReactGA from 'react-ga';
import { guardarConexion } from '../guardarConexion';
import Swal from 'sweetalert2';
//import mic from '../assets/mic.svg'
//import { getPuntoVentaIdId } from '../localStorageManager'
//import Select from 'react-select';
//import { CardTravel } from '@material-ui/icons';
import { validarVencimientoToken } from "../localStorageManager";

const MySwal = withReactContent(Swal)

const DateFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} else { return "" } };

const DateApiFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, '$3/$2/$1 $4:$5:$6').slice(0, -9)} else { return "" } };

//const EstadoActivoDDRCloud = 1 /* antes ==1, si es ddrcloud muestra el n ped y el estado en una linea en otra el boton abrir reclamo*/

export default class MisPedidosTienda extends React.Component {
    constructor(props){
      super(props);
      this.state = {
          loading: false,
          accordion: false,
          busqueda: "",
          busquedaTextoMostrarEnInput: "",
          pedidos: [],
          ediciones: [],
          options: [],
          inputValue: "",
          //selectedOption: null,
          PedidoModalOpen: false,
          AgregarPedidoOpen: false,
          publicacionSelectedOption: { value: null, label: 'Seleccione una publicación' },
          selectedOption: { value: null, label: "seleccione una edición" },
          initPedidos: {
            "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0,10),
            "fechaHasta": new Date().toISOString().slice(0,10),
            "puntoVentaId": JSON.parse(localStorage.infoToken).entidad_id,
            "productoId": null,
            "edicionId": null,
            "nombreTienda": this.props.props.location.pathname.includes("Tienda") || this.props.props.location.pathname.includes("ClubParadaOnline")  ? "Tienda Agea" : null
          },
          postPedidos: {
            "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0,10),
            "fechaHasta": new Date().toISOString().slice(0,10),
            "puntoVentaId": JSON.parse(localStorage.infoToken).entidad_id,
            "productoId": null,
            "edicionId": null,
            "nombreTienda": this.props.props.location.pathname.includes("Tienda") || this.props.props.location.pathname.includes("ClubParadaOnline") ? "Tienda Agea" : null
          },
          publicaciones: [],
          opcionOtros: false,
          opcionOtrosTexto: "",
          motivoValue: null,
          motivoLabel: "",
          pedidoId: null,
          pedidosReclamados: [],
          pedidosAnulados: [],
          puedeGenerarReclamo: false,
          open: false,
          mensajeErrorMotivos: "",
          verPantalla: 1,
          verFiltros: false,
          filtrosMarginTop: false,
          reclamo: null,
          pedidoSeleccionado:[],
          sapHabilitado: false
      }
    }

    // state verPantalla adopta los siguientes valores:
    //  1: Pantalla Mis pedidos
    //  2: Pantalla de Seleccion de motivos de reclamo
    //  3: Pantalla Detalle de reclamo

    
    mostrarMisPedidos = () => {
        this.setState({verPantalla: 1});
        this.setState({accordion: false});
        this.props.hideMenu(true);
    }

    mostrarMotivosReclamo = (pedido) => {
        this.setState({ verPantalla: 2, pedidoSeleccionado:pedido});
        this.props.hideMenu(true);
    }

    mostrarDetalleReclamo = (reclamoId) => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token
      }

      const filtro = {
          reclamoId: reclamoId
      };

      const respuesta = fetch(urlServer + '/api/tienda/reclamo/buscar', {
          method: 'POST',
          body: JSON.stringify(filtro),
          headers
      }).then(response => response.text())
      .catch(error => {console.log('error', error); 
      })
      .then(result => {

        const res = JSON.parse(result);
        validarVencimientoToken(res.message);

        this.setState({reclamo: res});
        this.setState({verPantalla: 3});
        this.props.hideMenu(true);

        return res
      })
      .catch(error => {
        validarVencimientoToken(error.message);
        console.log('error', error); 
        })
      return respuesta
    }

    pedidos = async () => {
      const response = await this.filtrarPedidos(this.state.postPedidos)
      return await response
    }

    clearFilter = () => {
        const fechaDesde = document.getElementById('fecha-desde')
        const fechaHasta = document.getElementById('fecha-hasta')
       
        fechaDesde.value = new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0, 10)
        fechaHasta.value = new Date().toISOString().slice(0, 10)
        fechaHasta.min = fechaDesde.value

        this.setState({
            publicacionSelectedOption: { value: null, label: 'Seleccione una publicación' },
            selectedOption: { value: null, label: "seleccione una edición" },
            ediciones: [],
            postPedidos: this.state.initPedidos,
            fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
            fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
        })
    }
  
    filtrarPedidos = async (data) => {
      
      ReactGA.event({
        category: 'Tienda/MisPedidos',
        action: 'Listar mis pedidos a tienda'
      });
      
      this.setState({
        loading: true,
        fechaDesdeFiltrada: DateFormatter(data.fechaDesde).slice(0, 10),
        fechaHastaFiltrada: DateFormatter(data.fechaHasta).slice(0, 10),
        pedidosReclamados: []
      })
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }
      const respuesta = await fetch(urlServer + '/api/pedidopasadofuturo/Buscar', {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(data),
        headers
      }).then(response => response.text())
      .catch(error => {console.log('error', error); 
      this.setState({
        loading: false
      })})
      .then(result => {

        const res = JSON.parse(result)
        validarVencimientoToken(result.message);
                
        this.setState({
          pedidos: res,
          loading: false
        })
        return res
      })
      .catch(error => {
        console.log('error', error); 
        validarVencimientoToken(error.message);
        this.setState({
            loading: false
        })})
      return respuesta
    }

    publicaciones = () => {
        if (this.state.AgregarPedidoOpen) {
            localStorage.setItem('flagRecargarTienda',true);
            return <Redirect push to={{
                pathname: '/Tienda/CargarPedido'
            }} />
      }
    }

    reqMotivosDeRespuesta = async () => {
        const { pedidoSeleccionado } = this.state
      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token
      }
      const url = urlServer + "/api/tienda/motivosreclamo";
      const respuesta = await fetch(url, {
        method: 'POST',
        redirect: 'manual',
        headers
      }).then(response => response.json())
      .then(result => {

        validarVencimientoToken(result.message);
          let options = (result.filter(pedido => pedido.esCanje === pedidoSeleccionado.esCanje)).map(pub => {
                  return { value: pub.motivoReclamoId, label: pub.descripcionMotivo, requiereMotivo: pub.requiereMotivo }
        })
        
        return options
      })
      .catch(error => {
        validarVencimientoToken(error.message);
        console.log('error', error)
      });
      return respuesta
    }

    handleOpcionOtros = async (motivo) => {
      this.setState({opcionOtrosTexto: ""});
      this.setState({motivoValue: motivo.value});
      this.setState({motivoLabel: motivo.label})
      if(motivo.requiereMotivo === true )
       {
          this.setState({opcionOtros: true})
       }
       else
       {
          this.setState({opcionOtros: false})
       }
    }

    enviarReclamo = async () => {
        if(this.state.motivoValue === null)
        {
          this.setState({mensajeErrorMotivos: "* Debes seleccionar un motivo de reclamo"});
          this.handleOpen();
          return;
        }
        
        if( this.state.opcionOtros && this.state.opcionOtrosTexto === "")
        {
          this.setState({mensajeErrorMotivos: "* Debes indicar la descripción para el motivo del reclamo"});
          this.handleOpen();
          return;
        }

        const headers = {
          "Content-Type": "application/ json",
          Authorization: "Bearer " + localStorage.token
        }
        const data = {
          motivoReclamoId: this.state.motivoValue,
          descripcionMotivo: this.state.opcionOtros  ? this.state.opcionOtrosTexto : this.state.motivoLabel,
          pedidoPasadoId: this.state.pedidoId,
          usuarioId: null
        }

        const url = urlServer + "/api/tienda/generarreclamo";
        const respuesta = await fetch(url, {
          method: "post",
          redirect: 'manual',
          body: JSON.stringify(data),
          headers
        })
        .then(response => 
          {
            if (parseInt(response.status) !== 200) {throw response }

            if(parseInt(response.status) === 200) {
                return response.json()
            } 
          })
        .then(result => {
            let reclamoId = result;
            let usuarioId = JSON.parse(localStorage.getItem('infoToken')).usuario_id;
            guardarConexion('RECLAMOPEDIDO', usuarioId, { reclamoTiendaId: reclamoId  } );
            let pedido = {
              "pedidoId": this.state.pedidoId,
              "nroreclamo": reclamoId
            } 
            // Marco pedido como reclamado
            this.state.pedidosReclamados.push(pedido);

            // Se limpian las variables
            this.setState({motivoValue: null});
            this.setState({opcionOtrosTexto: ""});
            this.setState({opcionOtros: false});
            this.setState({motivoLabel: ""});

            // Cerrar modal
            ReactGA.event({
              category: 'Tienda/Reclamos',
              action: 'Abrir Reclamo'
            });

            MySwal.fire({
                title: "Se ha creado con éxito el reclamo N° " + reclamoId + "!",
                icon: "success",
                confirmButtonText: "Aceptar"
            });

            this.mostrarMisPedidos();
        })
        .catch(err => {
            console.log('Error al intentar enviar reclamo: ', respuesta);
            if (typeof err.text === 'function') {
              err.text().then(errorMessage => {
                var msj=JSON.parse(errorMessage)
                MySwal.fire({
                    icon: 'error',
                    title: msj.message,
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: 'swalIconCustom'
                })})                      
            } else {
                console.log(err)
                MySwal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error.',
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
                })
            }
            validarVencimientoToken(err.message);
        });
    }

    reqPublicaciones = async (string) => {
        const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
        }
      
        const data = {
        nombreTienda: 'TIENDA AGEA',
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
        palabrasABuscar: string,
        columnaAOrdenar: 'DESCRIPCION ASC',
        pageIndex: 1,
        pageSize: 1999999,
        }
      
        const url = urlServer + "/api/producto/mispedidos/filtropublicacion"

        const respuesta = await fetch(url, {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(data),
        headers
        })
        .then(response => response.json())
        .then(result => {
        validarVencimientoToken(result.message);
        let options = result.map(pub => {
            return {value: pub.productoId, label: pub.descripcion}
        })
        options = [
            {value: null, label: 'Seleccione una publicación'},
            ...options
        ]
        this.setState({
            publicaciones: result,
            options
        })
        return options
        })
        .catch(error => {
        validarVencimientoToken(error.message);
        console.log('error', error)
        });
        return await respuesta
      
    }

    ediciones = async (string) => {
        if(this.state.postPedidos.productoId) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Authorization: 'Bearer ' + localStorage.token,
            }
            const data = {
                nombreTienda: 'TIENDA AGEA',
                puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
                productoId: this.state.postPedidos.productoId,
                palabrasABuscar: string,
                columnaAOrdenar: 'EDICIONFECHA DESC',
                pageIndex: 1,
                pageSize: 12
            }
            const url = urlServer + "/api/edicion/mispedidos/filtroedicion"

            const respuesta = await fetch(url, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            })
            .then(response => response.json())
            .then(result => {
            validarVencimientoToken(result.message);
            let options = result.map(ed => {
                return {value: ed.edicionId, label: ed.descripcion}
            })
            options = [
                {value: null, label: 'Seleccione una edición'},
                ...options
            ]
            this.setState({
                ediciones: options
            })
            return options
            })
            .catch(error => {
                validarVencimientoToken(error.message);            
                console.log('error', error)
            });
            return respuesta
        }
    }

    handleChange = async (newValue) => {
        await this.setState({
            publicacionSelectedOption: newValue,
            selectedOption: { value: null, label: "seleccione una edición" },
            postPedidos: {
              ...this.state.postPedidos,
              productoId: newValue.value
            }
      });
     
      this.ediciones('')
      return newValue;
    };

    handleEdicionChange = (selected) => {
      this.setState({
        selectedOption: selected,
        postPedidos: {
          ...this.state.postPedidos,
          edicionId: selected.value
        }
      })
    }

    ocultarFiltros = () => {
        let fechaHasta = document.getElementById('fecha-hasta')
        let fechaDesde = document.getElementById('fecha-desde')
        fechaHasta.min = fechaDesde.value
        this.setState({
            filtrosMarginTop: false,
            postPedidos: {
                ...this.state.postPedidos,
                fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
                fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
            }
        })
        fechaHasta.value = fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
             
      const div = document.getElementById('filtro')
      let divMarginTop = div.getElementsByClassName('filter')[0]
      const marginTop = divMarginTop.offsetHeight
      if (div.className.indexOf('overflow-hidden') !== -1) {
        divMarginTop.style.marginTop = "0"
        setTimeout(() => {div.className = div.className.replace('overflow-hidden', '')}, 550)
        this.setState({verFiltros: true})
      } else {
        div.className += 'overflow-hidden'
        divMarginTop.style.marginTop = `${-marginTop - 3}px`
        this.setState({verFiltros: false})

      }
    }

    componentDidMount() {
      document.title = "Mis Pedidos a Tienda"
      this.pedidos();
      
    }

    handleOpen = () => {
      this.setState({open: true});
    } 

    anularPedido = async (pedidoId) => {
      ReactGA.event({
        category: 'Tienda/MisPedidos',
        action: 'Anular Pedido'
      });
      
      
      var url = urlServer + '/api/tienda/anularpedido';
      const data = {
          kioscoPedidoPasadoId: pedidoId,
          usuarioId: null
      }

      await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Authorization": "bearer " + localStorage.token
          }
      }).then((response) => {
          if (parseInt(response.status) !== 200) {throw response }

          if(parseInt(response.status) === 200) {
              return response.json()
          } 
      }).then((result) => {
        if (result.noSePuedeDevolver) {
          MySwal.fire({
            icon: "error",
            title: result.message,
            showConfirmButton: false,
            timer: 2000,
            customClass: "swalIconCustom",
          });
        } else {
          let pedido = {
            pedidoId: pedidoId
          }
          this.state.pedidosAnulados.push(pedido);
          this.setState({pedidosAnulados: this.state.pedidosAnulados})

          MySwal.fire({
            title: "¡Anulado!",
            text: "El pedido ha sido anulado.",
            icon: "success",
            confirmButtonText: "Aceptar"
          });
    
        }
      }).catch((err) => {
        
        if (typeof err.text === 'function') {
          err.text().then(errorMessage => {
             var msj=JSON.parse(errorMessage)
             
            MySwal.fire({
                icon: 'error',
                title: msj.message,
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
            })})                      
        }
          validarVencimientoToken(err.message);
      });

    }

    
    render(){
        const { loading, pedidos, postPedidos, verPantalla, reclamo } = this.state
        
        const handleClose = () => {
            this.setState({open: false})
        };

        return (<div id='pedidos' className="container text-left">
                  {/* Ver pantalla de detalle de reclamo */} 
                  {verPantalla === 3 ?
                  <div>
                      <div id="backarrow" className="position-fixed back-arrow-box" onClick={() => {
                          this.mostrarMisPedidos();
                          this.setState({filtrosMarginTop: true})

                      }}>
                           <ReactSVG style={{position: 'fixed', left:'15px',  width: '13px'}} src={backArrow} />
                      </div>
                          <Title 
                          classes=""
                          title='Detalle del Reclamo'
                          
                        />
                      <div className="box">
                      <   div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                  <span style={{color: '#EA3F3F', fontWeight: '500', fontSize: '14px'}}>
                                      {reclamo.publicacion}
                                  </span>
                                </div>
                                <div className="desc-reclamo">
                                      ED: 
                                      <span className="ml-1" >
                                        {reclamo.edicion.replace('Ed.', '')}
                                      </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                    N° RECLAMO:
                                    <span className="ml-1" >
                                        {reclamo.reclamoId}
                                    </span>
                                </div>
                                <div className="desc-reclamo">
                                    FECHA:
                                    <span className="ml-1" >
                                        {DateApiFormatter(reclamo.fechaReclamo)}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                    ESTADO:
                                    <span className="ml-1" >
                                        {reclamo.estadoReclamoTienda}
                                    </span>
                                </div>
                                <div className="desc-reclamo">
                                    
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                    MOTIVO: 
                                    <span className="ml-1" >
                                        {reclamo.motivoReclamoTienda}
                                    </span>
                                </div>
                                <div className="desc-reclamo">
                                    
                                </div>
                            </div>
                        {reclamo.esCanje === true?
                                <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '10px' }}>
                                <div className="desc-reclamo">
                                    PEDIDO:
                                    <span className="ml-1" style={{ color: "green", fontWeight: "bold" }}>
                                        Canje por Puntos
                                    </span>
                                    </div>
                                </div>
                                : null
                            }
                            <div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                    RESPUESTA: 
                                    <span className="ml-1" >
                                        {reclamo.respuestaReclamoTienda}
                                    </span>
                                </div>
                                <div className="desc-reclamo">
                                    
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-400" style={{marginBottom: '10px'}}>
                                <div className="desc-reclamo">
                                    NOTA: 
                                    <span className="ml-1" >
                                        {reclamo.respuestaDescripcion}
                                    </span>
                                </div>
                                <div className="desc-reclamo">
                                    
                                </div>
                            </div>
                            
                        </div>
                  </div>
                  :null }

                  {/* Pantalla de seleccion de motivos de reclamo */}
                  {verPantalla === 2 ?
                  <div>
                      <div id="backarrow" className="position-fixed back-arrow-box" onClick={() => {
                          this.mostrarMisPedidos();
                          this.setState({filtrosMarginTop: true})
                      }}>
                           <ReactSVG style={{position: 'fixed', left:'15px',  width: '13px'}} src={backArrow} />
                      </div>
                    <div className="d-flex justify-content-between header" >
                      <div className="title">
                          Mis Pedidos - Abrir Reclamo
                      </div>
                    </div>

                    <div>
                        <div classes="w-100">
                              <AsyncSelect 
                                cacheOptions
                                loadOptions={this.reqMotivosDeRespuesta}
                                defaultOptions
                                onChange={this.handleOpcionOtros}
                                placeholder = {"Seleccione un Motivo"}
                              />
                          </div>  
                          {this.state.opcionOtros ? 
                            <div className="w-100 mt-2">
                                <input className="w-100 form-control" type="text" placeholder="Ingresar Motivo" maxlength="255" onChange={e => {
                                  const regex = /[^a-zA-Z0-9\-_#$%\\ ]/g;
                                  e.target.value = e.target.value.replace(regex, '');
                                  this.setState({opcionOtrosTexto: e.target.value})    
                                }}/>
                            </div>
                          :null }
                                
                    </div>
                     
                     <div className="d-flex justify-content-center align-items-center barra-enviar">
                        <div className="d-flex justify-content-center align-items-center" 
                          style={{background: "#224372", color: "white", fontSize: "12px", textAlign: "center", cursor: "pointer", borderRadius: "16px", width: "90px", height: "33px"}}
                          onClick={() => {
                              this.setState({motivoValue: null});
                              this.setState({opcionOtrosTexto: ""});
                              this.setState({opcionOtros: false});
                              this.mostrarMisPedidos();
                              this.setState({filtrosMarginTop: true});

                          }}>Volver</div>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-center" 
                          style={{background: "#224372", color: "white", fontSize: "12px", textAlign: "center", cursor: "pointer", borderRadius: "16px", width: "100px", height: "33px"}}
                          onClick={() => {
                                this.enviarReclamo();
                                }}>Abrir Reclamo</div>
                    </div>
                  </div>
                  :null }
                  
                  {/* Pantalla de mis pedidos */}
                  {verPantalla === 1 ?
                  <div>
                          <Title 
                            classes=""
                            title='Mis Pedidos'
                            accordion={this.state.accordion}
                            alterAccordion={() => {this.setState({accordion: !this.state.accordion}); this.ocultarFiltros();}}
                          />
                          <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#loader" style={{display: 'none'}}>
                          </button>
                          <div className="modal fade" id="loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                              <div className="modal-content text-center">
                                <div className="modal-body">
                                  <Spinner />
                                </div>
                                Descargando PDF...
                                <div className="modal-footer" style={{display: 'none'}}>
                                  <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                  
                          <div id="filtro" className={`row ${this.state.verFiltros ? '':'overflow-hidden'}`} style={{display: this.state.verFiltros ? 'block' : 'none', marginTop: this.state.filtrosMarginTop ? '200px': ''}}>
                            <div className={"filter"} > 
                              <div className=" d-flex justify-content-between" style={{marginBottom: '10px'}}>
                                <div style={{width: '33%'}} >
                                  <div style={{marginBottom: '12px'}} >
                                    Desde
                                  </div>
                                  <div>
                                        <input id="fecha-desde"
                                            className="form-control filter-input"
                                            type="date"
                                            value={this.state.postPedidos.fechaDesde}
                                            onChange={e => {
                                                    let fechaHasta = document.getElementById('fecha-hasta')
                                                    fechaHasta.min = e.target.value
                                                    this.setState({
                                                      postPedidos: {
                                                        ...this.state.postPedidos, 
                                                        fechaDesde: e.target.value,
                                                        fechaHasta: fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                                      }
                                                    })
                                                    fechaHasta.value = fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                                    }} />
                                  </div>
                                </div>
                                 {this.publicaciones()}
                                <div style={{width: '33%'}} >
                                  <div style={{marginBottom: '12px'}} >
                                    Hasta
                                  </div>
                                  <div>
                                        <input id="fecha-hasta"
                                            className="form-control filter-input"
                                            type="date"
                                            onChange={e =>
                                                this.setState({
                                                    postPedidos: {
                                                        ...this.state.postPedidos,
                                                        fechaHasta: e.target.value
                                                    }
                                                })} />
                                  </div>
                                </div>
                                <div style={{width: '24%'}} >
                                  <div className="eraser" onClick={() => this.clearFilter()}>
                                    LIMPIAR
                                    <ReactSVG src={eraser} style={{width: '16px'}} />
                                  </div>
                                  <div style={{width: '100%' }} className="btn button-theme " onClick={() => this.filtrarPedidos(postPedidos)}> Filtrar </div>
                                </div>
                              </div>                      
                      
                              <div className="w-100 " style={{marginBottom: '10px'}}>
                                <AsyncSelect 
                                  cacheOptions
                                  loadOptions={this.reqPublicaciones}
                                  defaultOptions
                                  onChange={this.handleChange}
                                  value={this.state.publicacionSelectedOption}
                                />
                              </div>

                              
                              {/* {this.state.postPedidos.productoId ? */}
                              <div className="w-100 ">
                                

                                <AsyncSelect 
                                  key={this.state.postPedidos.productoId}
                                  value={this.state.selectedOption}
                                  cacheOptions
                                  loadOptions={this.ediciones}
                                  defaultOptions
                                  onChange={this.handleEdicionChange}
                                />


                              </div> {/* : null} */}
                            </div>
                          </div>
                          <div className="w-100 ">
                            <input className="w-100 form-control" type="text" placeholder="Buscar" value={this.state.busquedaTextoMostrarEnInput} onChange={e => this.setState({
                              busqueda: e.target.value.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace(/\./g, ''),
                              busquedaTextoMostrarEnInput: e.target.value
                            })}/>
                            
                          </div>
                        {loading ? 
                        <Spinner style={{fontSize: '8px'}} />
                        :
                        <div className="cards" style={{paddingBottom: '70px'}} >
                          {pedidos.length > 0 ? pedidos.filter(a => JSON.stringify(Object.values(a)).normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((card, index) => {
                              return (
                                //CARDVIEW MIS PEDIDOS
                                 <div key={index} className="box" style={{ color: "#8E95A5" }} >
                                    {/*primer fila*/}
                                    <div className="d-flex justify-content-between " style={{marginBottom: '8px'}} >
                                      <div style={{color: '#EA3F3F', fontWeight: '500', fontSize: '14px'}} >
                                        {(card.publicacion)}
                                      </div>
                                     
                                      </div>

                                    {/*segunda fila*/}
                                    <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }} >
                                        {/*EDICION*/}
                                        <div className="desc-reclamo" >
                                        ED:
                                        <span className="ml-1" >
                                            {card.edicion.replace("Ed. ", "").replace("Ed.", "")}
                                        </span>
                                          </div>

                                        {/*nro ejemplar*/}
                                        <div className="desc-reclamo"> 
                                        Nº EJEM:
                                        <span className="ml-1" >
                                            {card.cantidadPedida}
                                        </span>
                                        </div>
                                      </div>

                                    {/*tercera fila*/}
                                    {card.tipoPedido !== "Pedido Futuro" ?
                                       <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }}>
                                              <div className="desc-reclamo">
                                                  Nº PED:
                                                <span className="ml-1" >
                                                      {card.pedidoId}
                                                  </span>
                                              </div>
                                              <div className="d-flex" style={{ color: '#343435', fontSize: '13px', fontWeight: '500', marginTop: '-2px' }} >
                                                  <div className="desc-reclamo">
                                                      FECHA:&nbsp;
                                                        <span className="m-auto">
                                                          {DateApiFormatter(card.fechaPedido)}
                                                        </span>
                                                  </div>
                                              </div>
                                          </div>
                                       : null}

                                      {/*cuarta fila*/}
                                      {card.tipoPedido !== "Pedido Futuro" ?
                                          <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }}>
                                              <div className="desc-reclamo">
                                                  TIPO:
                                                  <span className="ml-1" >
                                                      {card.tipoPedido.replace("Pedido ", "")}
                                                      {card.esCanje === 1 ?
                                                          <span >
                                                              &nbsp; - Canje por Puntos
                                                          </span>
                                                          : <span >
                                                              &nbsp; - Compra
                                                          </span>}
                                                  </span>
                                              </div>
                                          </div>
                                          : null}


                                      {/*quinta fila*/}
                                      {card.retiroPresencialActivo  ?
                                      <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }}>
                                            <div className="desc-reclamo">
                                                TIPO ENTREGA:
                                                <span className="ml-1" >
                                                    {card.tipoDeEntregaDescripcion}
                                                </span>
                                            </div>
                                      </div>
                                    : null}
                                    {/*sexta fila*/}
                                    <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }}>
                                          {card.mostrarEstadoEnMisPedidos ?
                                              <div className="desc-reclamo">
                                                  ESTADO:
                                                    <span className="ml-1" >
                                                      { this.state.pedidosAnulados.find(x =>x.pedidoId === card.pedidoId) ? 'Anulado' : card.estado }
                                                  </span>
                                              </div>
                                          :null }    
                                      </div>

                                    {/*septima fila*/}                                      
                                      {card.reclamoId !== null || this.state.pedidosReclamados.find(x => x.pedidoId === card.pedidoId) ?
                                       <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px' }}>
                                              <div className="desc-reclamo">
                                                  Nº RECLAMO:
                                                            <span className="ml-1" >
                                                      {card.reclamoId !== null?
                                                           
                                                          card.reclamoId
                                                            : this.state.pedidosReclamados.find(x => x.pedidoId === card.pedidoId)?.nroreclamo
                                                      }
                                                  </span>
                                              </div>
                                               <span className="ml-1" >
                                                    <span style={{background: "#EA3F3F",
                                                            color: "white",
                                                            fontSize: "12px",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            borderRadius: "16px",
                                                            width: "130px",
                                                            height: "33px",
                                                            padding: "4px"}} onClick={() => {

                                                            ReactGA.event({
                                                                category: 'Tienda/Reclamos',
                                                                action: 'Ver Reclamo'
                                                            });

                                                            var reclamoId = null;
                                                            if(card.reclamoId !== null)
                                                            {
                                                                reclamoId = card.reclamoId;
                                                            }
                                                            else{
                                                                reclamoId = this.state.pedidosReclamados.find(x => x.pedidoId === card.pedidoId)?.nroreclamo
                                                            }

                                                                this.mostrarDetalleReclamo(reclamoId);
                                                            }}>Ver Reclamo</span>
                                              </span>
                                          </div>
                                       :
                                       <div className="d-flex justify-content-between align-items-center w-400" style={{ marginBottom: '4px', marginTop:'8px' }}>
                                              <div>
                                                  {card.anularHabilitado && !this.state.pedidosAnulados.find(x => x.pedidoId === card.pedidoId) ?
                                                  <span style={{
                                                              background: "#EA3F3F",
                                                              color: "white",
                                                              fontSize: "12px",
                                                              textAlign: "center",
                                                              cursor: "pointer",
                                                              borderRadius: "16px",
                                                              width: "130px",
                                                              height: "33px",
                                                              padding: "4px"
                                                          }} onClick={() => {

                                                            Swal.fire({
                                                              title: '¿Desea anular este pedido?',
                                                              // text: msj,
                                                              icon: 'warning',
                                                              showCancelButton: true,
                                                              confirmButtonColor: '#3085d6',
                                                              cancelButtonColor: '#d33',
                                                              cancelButtonText: 'Cancelar',
                                                              confirmButtonText: 'Anular Pedido',
                                                              customClass: 'swalIconCustom'
                                                              }).then((result) => {
                                                                  if (result.value) {
                                                                      this.anularPedido(card.pedidoId)
                                                                  }
                                                              })
                                                              
                                                          }}>
                                                      Anular Pedido
                                                  </span>
                                                  :null }
                                              </div>
                                               <span className="ml-1" >
                                                  <span onClick={() => {
                                                          if (!card.puedeGenerarReclamo === false) {
                                                              this.setState({opcionOtros: null})
                                                              this.mostrarMotivosReclamo(card);
                                                          }
                                                          else {
                                                              var title = "";
                                                              if( card.estadoId === 6 /*Anulado*/|| this.state.pedidosAnulados.find(x => x.pedidoId === card.pedidoId) )
                                                              {
                                                                title = `Pedido Anulado. No se pueden abrir reclamos`;

                                                              }
                                                              else {
                                                                  var fechaSalida = new Date(card.fechaSalida);
                                                                  var hoy = new Date();
                                                                  //No es pedido Preventa
                                                                  title = `No se pueden abrir reclamos`;
                                                                  if (card.configuracionDiasParaLevantarReclamos > 0 && card.tipoDeEntrega === 0) {
                                                                      title += ` antes de transcurridos los ${card.configuracionDiasParaLevantarReclamos} días hábiles de haber cargado el Pedido!`;
                                                                  }
                                                                  //es pedido Preventa
                                                                  if (fechaSalida > hoy) {
                                                                      title = `PEDIDO PREVENTA: No se pueden abrir reclamos antes de`;
                                                                      if (card.configuracionDiasParaLevantarReclamos > 0 && card.tipoDeEntrega === 0) {
                                                                          title += `transcurrido los ${card.configuracionDiasParaLevantarReclamos} días hábiles de`;
                                                                      }
                                                                      title += ` la fecha de salida del producto!`;
                                                                  }
                                                                  if(card.tipoDeEntrega === 1 && card.estadoId === 1/*Solicitado*/)
                                                                  {
                                                                      title += ' hasta que se entregue el pedido'
                                                                  }
                                                                  if(card.tipoDeEntrega === 1 && card.estadoId=== 2 /*En Gestion*/)
                                                                  {
                                                                      title += ' hasta que se entregue el pedido'
                                                                  }
                                                                  if(card.tipoDeEntrega === 1 && card.estadoId === 4 /*Enviado a la Distribuidora*/)
                                                                  {
                                                                      title += ' hasta que se entregue el pedido'
                                                                  }
                                                                  if(card.tipoDeEntrega === 1 && card.estadoId === 7 /*Recibido por la Distribuidora*/)
                                                                  {
                                                                      title += ' hasta que se entregue el pedido'
                                                                  }
                                                                  if(card.tipoDeEntrega === 1 && card.estadoId === 3/*Cerrado*/)
                                                                  {
                                                                      title += ' el pedido está cerrado'
                                                                  }
                                                              }
                                                              
                                                              MySwal.fire({
                                                                  title: title,
                                                                  text: '',
                                                                  icon: 'error',
                                                                  confirmButtonText: 'Aceptar',
                                                                  customClass: 'swalIconCustom'
                                                              })
                                                          }

                                                          this.setState({ pedidoId: card.pedidoId, puedeGenerarReclamo: card.puedeGenerarReclamo })
                                                      }
                                                    }
                                                      style={{
                                                          background: "#224372",
                                                          color: "white",
                                                          fontSize: "12px",
                                                          textAlign: "center",
                                                          cursor: "pointer",
                                                          borderRadius: "16px",
                                                          width: "130px",
                                                          height: "33px",
                                                          padding: "4px"
                                                      }}
                                                  >Abrir Reclamo
                                                    </span>
                                               </span>
                                          </div>
                                     }
                                  </div>
                              )
                          }) :
                          <div className="not-found" >
                            No se encontraron pedidos a tienda para la fecha entre {this.state.fechaDesdeFiltrada} y {this.state.fechaHastaFiltrada}
                          </div>}
                          <div style={{ paddingBottom: '25px' }}></div>
                          <div className="d-flex justify-content-center" style={{position: "fixed",bottom: "29px", left: "0", right: "0"}}>
                            <div className="agregar-pedido" onClick={() => this.setState({AgregarPedidoOpen: true})}>+</div>
                          </div>
                        </div>}
                  </div>
                  :null }


                  <Modal
                        open={this.state.open}
                        onClose={handleClose}>
                        
                            <div style={{
                                  backgroundColor: '#EA3F3F',
                                  color: 'white',
                                  padding: '20px',
                                  maxWidth: '400px',
                                  width: '85%',
                                  height:'150px',
                                  position: 'fixed',
                                  top: '50%',
                                  left: '50%',
                                  transform: 'translate(-50%, -50%)',
                                  fontSize: '15px',
                                  fontFamily: 'roboto',
                                  borderRadius: '5px'
                                  }}>
                                
                                <div align="center">
                                    {this.state.mensajeErrorMotivos}
                                </div>
                                <div align="right" style={{marginTop: '40px'}}>
                                    <button style={{
                                              backgroundColor: '#EA3F3F',
                                              borderWidth: '0px',
                                              color: 'white',
                                              fontSize: '12px'}}
                                      type="button" onClick={handleClose}>
                                      Aceptar
                                    </button>
                                </div>
                            </div>

                  </Modal>

                </div>)
    }
}