import * as React from "react";
import List from "@material-ui/core/List";
import MenuItem from "./MenuItem";
import { validarVencimientoToken } from "../localStorageManager";

export default class MenuQuston extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [],
    };
  }

  componentDidMount = () => {
    // Armar menu hamburguesa
    if (this.props.path.includes("/Tienda")) {
      this.setState({ links: this.props.linksTienda });
      this.getCategoriaProducto();
    }
  };

  getCategoriaProducto = () => {
    // Accede a los datos desde el localStorage
    const datosMenu = JSON.parse(localStorage.getItem("menu-ProdMasVendido"));

    const aux = this.props.linksTienda.map((card, index) => {
      if (card.collapse) {
        return { ...card, items: datosMenu };
      } else {
        return { ...card };
      }
    });
    this.setState({ links: aux });
  };

  render() {
    //RENDERIZA EL MENÚ LATERAL EN CASO DE QUE EXISTAN SUBLINKS
    //const { match, location, history } = this.props;

    return (
      <div
        id="scrollbarMenuProducto"
        className="container"
        style={{
          paddingRight: "0px",
          zIndex: 2,
          color: "#8E95A5",
          fontFamily: "Roboto",
          fontSize: "22px",
        }}
      >
        <List
          component="nav"
          style={{
            maxHeight: "100vh",
            overflow: "auto",
            paddingBottom: "150px",
          }}
        >
          {window.location.href.includes("Tienda") &&
            this.state.links.map((item, index) => (
              <MenuItem {...item} key={index} hideMenu={this.props.hideMenu} />
            ))}
          {!window.location.href.includes("Tienda") &&
            this.props.links.map((item, index) => (
              <MenuItem {...item} key={index} hideMenu={this.props.hideMenu} />
            ))}
        </List>
      </div>
    );
  }
}
