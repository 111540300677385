import * as React from "react";
import backArrow from "../assets/backArrow.svg";
import { ReactSVG } from "react-svg";
import { Title } from "../components/title";
import pedidos from "../assets/pedidos.svg";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import eliminar from "../assets/eliminar.svg";
import urlServer from "../server";
import Swal from "sweetalert2";
import { validarVencimientoToken } from "../localStorageManager";
export default class CarritoAltaSuscripciones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      puntoventalinea: null,
      puntoventapaquete: null,
      puntoventavendedor: null,
      distribuidorsucursal: null,
      distribuidorsucursaltipo: null,
      verBackArrow: true
    };
  }


  componentDidMount = () => {
    this.getInfoPuntoVenta();
    this.scrollTo();
  };

  scrollTo() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

  getInfoPuntoVenta = () => {
    var puntoVentaId = JSON.parse(localStorage.infoToken).entidad_id;
    var url =
      urlServer + `/api/puntoVenta/obtenerdistribuidora/${puntoVentaId}`;
    fetch(url, {
      method: "POST",
      body: puntoVentaId,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        validarVencimientoToken(result.message);
        this.setState({
          puntoventalinea:
            result.numeroLinea === null ? "" : result.numeroLinea,
        });
        this.setState({ puntoventapaquete: result.descripcion });
        this.setState({
          puntoventavendedor: result.vendedor === null ? "" : result.vendedor,
        });
        this.setState({
          distribuidorsucursal: result.distribuidorSucursalDescripcion,
        });
        this.setState({ distribuidorsucursaltipo: result.tipo });
      })
      .catch((error) => {
        validarVencimientoToken(error.message);
        console.log(error);
      });
  };

  getCantidadCarrito = () => {
    var cantidadCarrito = 0;
    this.props.suscripcion.suscripcionProductosListado.map((prod, index) => {
      if (prod.tipo === "Diarios") {
        var aux =
          prod.domingo +
          prod.lunes +
          prod.martes +
          prod.miercoles +
          prod.jueves +
          prod.viernes +
          prod.sabado;
        if (prod.cantidad > 0 && aux > 0) {
          cantidadCarrito += 1;
        }
      } else {
        if (prod.cantidad > 0) {
          cantidadCarrito += 1;
        }
      }
    });

    return cantidadCarrito;
  };

  getDescripcionProducto = (prod) => {
    var auxDias = "";
    var descripcion = "";
    if (prod.tipo === "Diarios") {
      if (prod.lunes === 1) {
        auxDias += "LU-";
      }
      if (prod.martes === 1) {
        auxDias += "MA-";
      }
      if (prod.miercoles === 1) {
        auxDias += "MI-";
      }
      if (prod.jueves === 1) {
        auxDias += "JU-";
      }
      if (prod.viernes === 1) {
        auxDias += "VI-";
      }
      if (prod.sabado === 1) {
        auxDias += "SA-";
      }
      if (prod.domingo === 1) {
        auxDias += "DO-";
      }
      auxDias = auxDias.substring(0, auxDias.length - 1);
      descripcion = (
        <div>
          <div style={{ color: "red" }}>{prod.descripcion}</div>
          <div>{auxDias}</div>
        </div>
      );
      return descripcion;
    } else {
       descripcion = (
        <div>
          <div style={{ color: "red" }}>{prod.descripcion}</div>
          <div>{prod.tipo}</div>
        </div>
      );
      return descripcion;
    }
  };

  getDescripcionProductoParaConfirmar = () => {
    var productos = "<ul style='padding-left: 10px; margin-left: 0px;'>";
    this.props.suscripcion.suscripcionProductosListado
      .filter((x) => x.cantidad > 0)
      .map((prod, index) => {
        if (prod.tipo === "Diarios") {
          var aux = "";
          aux += prod.lunes > 0 ? "LU, " : "";
          aux += prod.martes > 0 ? "MA, " : "";
          aux += prod.miercoles > 0 ? "MI, " : "";
          aux += prod.jueves > 0 ? "JU, " : "";
          aux += prod.viernes > 0 ? "VI, " : "";
          aux += prod.sabado > 0 ? "SA, " : "";
          aux += prod.domingo > 0 ? "DO, " : "";
          aux = aux.substring(0, aux.length - 2);

          productos += "<li style='list-style-type: square; font-size: 14px'>" + prod.descripcion + " - " + aux + "</li>";
        } else {
          productos += "<li style='list-style-type: square; font-size: 14px'>" + prod.descripcion + " - " + prod.tipo + "</li>";
        }
      });

      productos += "</ul>";

    return productos;
  };

  definirBackGround = (prod) => {
    var color = "white";
    if (prod.cantidad === "empty") {
      color = "hsla(14, 100%, 53%, 0.5)";
    }

    return color;
  };

  confirmarSuscripcion = () => {
    var url = urlServer + "/api/suscripciones/alta";
    var suscripciones = this.props.suscripcion.suscripcionProductosListado
      .filter((x) => x.cantidad > 0)
      .map((prod, index) => {
        var sus = {
          productoId: prod.productoId,
          cantidadDeUnidadesNoDiarios:
            prod.tipo === "Diarios" ? 0 : prod.cantidad,
          cantidadLunes:
            prod.tipo === "Diarios" && prod.lunes === 1 ? prod.cantidad : 0,
          cantidadMartes:
            prod.tipo === "Diarios" && prod.martes === 1 ? prod.cantidad : 0,
          cantidadMiercoles:
            prod.tipo === "Diarios" && prod.miercoles === 1 ? prod.cantidad : 0,
          cantidadJueves:
            prod.tipo === "Diarios" && prod.jueves === 1 ? prod.cantidad : 0,
          cantidadViernes:
            prod.tipo === "Diarios" && prod.viernes === 1 ? prod.cantidad : 0,
          cantidadSabado:
            prod.tipo === "Diarios" && prod.sabado === 1 ? prod.cantidad : 0,
          cantidadDomingo:
            prod.tipo === "Diarios" && prod.domingo === 1 ? prod.cantidad : 0,
        };
        return sus;
      });
    var data = {
      puntoVentaId: null,
      usuarioId: null,
      tipoDocumento: this.props.suscripcion.tipoDocumento.value,
      nroDocumento: parseInt(this.props.suscripcion.nroDocumento),
      apellido: this.props.suscripcion.apellido.toUpperCase(),
      nombre: this.props.suscripcion.nombre.toUpperCase(),
      fechaNacimiento: this.props.suscripcion.fechaNacimiento,
      genero: this.props.suscripcion.genero,
      telefonoCelularFijo: this.props.suscripcion.celularFijo,
      telefonoNumero: this.props.suscripcion.telefonoNumero,
      email: this.props.suscripcion.email.toUpperCase(),
      horaContactoDesde: this.props.suscripcion.horaDesde,
      horaContactoHasta: this.props.suscripcion.horaHasta,
      provinciaId: this.props.suscripcion.provinciaId,
      partidoDepartamentoId: this.props.suscripcion.partidoId,
      localidadId: this.props.suscripcion.localidadId,
      codigoPostal: this.props.suscripcion.codigoPostal,
      countryBarrio: this.props.suscripcion.countryBarrio === null ? null : this.props.suscripcion.countryBarrio.toUpperCase(),
      calle: this.props.suscripcion.calle.toUpperCase(),
      calleNro: this.props.suscripcion.calleNro,
      piso: this.props.suscripcion.piso === null ? null : this.props.suscripcion.piso.toUpperCase(),
      departamento: this.props.suscripcion.departamento === null ? null : this.props.suscripcion.departamento.toUpperCase(),
      torre: this.props.suscripcion.torre === null ? null : this.props.suscripcion.torre.toUpperCase(),
      cuerpo: this.props.suscripcion.cuerpo === null ? null : this.props.suscripcion.cuerpo.toUpperCase(),
      calleLateral1: this.props.suscripcion.calleLateral1 === null ? null : this.props.suscripcion.calleLateral1.toUpperCase(),
      calleLateral2: this.props.suscripcion.calleLateral2 === null ? null : this.props.suscripcion.calleLateral2.toUpperCase(),
      suscripciones: suscripciones,
    };

      fetch(url, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.token,
          },
      })
          .then((response) => {
              return response.json();
          })
          .then((result) => {
              validarVencimientoToken(result.message);
              if (result[0].mensajeError) {
                  

                var mensaje = '<div style="text-align:left; padding: 0px;">';
                     
                  mensaje += result[0].mensajeError;
                     
                mensaje += '</div>';

                  Swal.fire({
                      icon: "error",
                      title: '<span>No se pudo realizar la solicitud:</span>',
                      html: mensaje,
                      padding: "2px",
                      confirmButtonColor: "#3085d6",
                      confirmButtonText: "Aceptar",
                      customClass: "swalIconCustom",
                  }).then(() => {
                      this.props.volverDeConfirmarCarrito();
                  });
                  
              } else {                  
                  Swal.fire({
                      icon: "success",
                      title: "Solicitud guardada con éxito!",
                      showConfirmButton: false,
                      timer: 1500,
                  }).then(() => {
                      this.props.volverDeConfirmarCarrito();
                  });
          }
      })
      .catch((error) => {
        validarVencimientoToken(error.message);
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        {/* {this.state.verBackArrow ?? */}
          <div
            id="backarrow"
            className="position-fixed back-arrow-box"
            onClick={() => {
              this.props.volverDeCarrito();
            }}
          >
            <ReactSVG src={backArrow} />
          </div>
        {/* } */}
        <div className="container text-left">
          <div className="d-flex justify-content-between">
            <div>
              <Title classes="" title="Productos agregados" />
            </div>
            <div className="position-relative" style={{ marginTop: "33px" }}>
              <ReactSVG
                src={pedidos}
                style={{ width: "27px", height: "27px", color: "#8E95A5" }}
              />
              <div
                className="position-absolute d-flex justify-content-center align-items-center f-11"
                style={{
                  right: "-8px",
                  top: "-8px",
                  background: "#EA3F3F",
                  color: "white",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                }}
              >
                {this.getCantidadCarrito()}
              </div>
            </div>
          </div>
          <div style={{ paddingBottom: "130px" }}>
            {this.props.suscripcion.suscripcionProductosListado
              .filter(
                (x) =>
                  (x.cantidad > 0 ||
                    x.cantidad === "empty" ||
                    x.cantidad === "cero") &&
                  ((x.tipo === "Diarios" &&
                    x.domingo +
                      x.lunes +
                      x.martes +
                      x.miercoles +
                      x.jueves +
                      x.viernes +
                      x.sabado >
                      0) ||
                    x.tipo !== "Diarios")
              )
              .map((prod, index) => {
                return (
                  <div key={index} className="suscripciones-carrito-card mb-4">
                    <div className="d-flex justify-content-between align-items-center pt-3">
                      <div
                        className="f-13-5 fw-400"
                        style={{ color: "#343435", maxWidth: "60%" }}
                      >
                        {this.getDescripcionProducto(prod)}
                      </div>
                      <div>
                        {/* botones */}
                        <div
                          className="d-flex justify-content-between align-items-center "
                          style={{ width: "140px" }}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              cursor: "pointer",
                              background: "#F4F4F4",
                              width: "26px",
                              height: "26px",
                              borderRadius: "50%",
                            }}
                            onClick={() => {
                              if (prod.cantidad > 0) {
                                prod.cantidad--;
                                if (
                                  prod.cantidad === 0 &&
                                  prod.tipo === "Diarios"
                                ) {
                                  prod.domingo = 0;
                                  prod.lunes = 0;
                                  prod.martes = 0;
                                  prod.miercoles = 0;
                                  prod.jueves = 0;
                                  prod.viernes = 0;
                                  prod.sabado = 0;
                                }
                                let { suscripcionProductosListado } =
                                  this.props.suscripcion;
                                let ind = suscripcionProductosListado.findIndex(
                                  (e) => e.productoId === prod.productoId
                                );
                                suscripcionProductosListado[ind] = prod;
                                this.setState({
                                  suscripcionProductosListado,
                                });
                              }
                            }}
                          >
                            <ReactSVG
                              src={restar}
                              style={{ color: "#8E95A5", width: "11px" }}
                            />
                          </div>
                          &nbsp; &nbsp;
                          <div className="f-13-5 fw-400">
                            <input
                              style={{
                                backgroundColor: this.definirBackGround(prod),
                                border: "#343435 solid 1px",
                              }}
                              key={index}
                              type="number"
                              className="input-of-cantidades text-center"
                              maxLength="3"
                              min="0"
                              max="999"
                              id={prod.productoId}
                              value={
                                prod.cantidad === "cero" ? 0 : prod.cantidad
                              }
                              autoComplete="off"
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              onInput={(e) =>
                                (e.target.value = e.target.value.slice(0, 4))
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  e.currentTarget.blur();
                                }
                              }}
                              onBlur={(e) => {
                                let { suscripcionProductosListado } =
                                  this.props.suscripcion;
                                let regex = /[^0-9]/g;
                                if (regex.test(e.target.value)) {
                                  e.target.value = e.target.value.replace(
                                    regex,
                                    ""
                                  );
                                }
                                prod.cantidad =
                                  e.target.value === "" ||
                                  prod.cantidad === "cero" ||
                                  prod.cantidad === "empty"
                                    ? 0
                                    : parseInt(e.target.value);
                                if (
                                  prod.cantidad === 0 &&
                                  prod.tipo === "Diarios"
                                ) {
                                  prod.lunes = 0;
                                  prod.martes = 0;
                                  prod.miercoles = 0;
                                  prod.jueves = 0;
                                  prod.viernes = 0;
                                  prod.sabado = 0;
                                  prod.domingo = 0;
                                }
                                let ind = suscripcionProductosListado.findIndex(
                                  (e) => e.productoId === prod.productoId
                                );
                                suscripcionProductosListado[ind] = prod;
                                this.setState({
                                  suscripcionProductosListado,
                                });
                              }}
                              onChange={(e) => {
                                let regex = /[^0-9]/g;
                                if (regex.test(e.target.value)) {
                                  e.target.value = e.target.value.replace(
                                    regex,
                                    ""
                                  );
                                }
                                e.target.value =
                                  e.target.value === "0"
                                    ? e.target.value
                                    : e.target.value.replace(/^(0+)/g, "");
                                prod.cantidad =
                                  e.target.value === ""
                                    ? "empty"
                                    : e.target.value === "0"
                                    ? "cero"
                                    : parseInt(e.target.value);
                                if (
                                  prod.cantidad === 0 &&
                                  prod.tipo === "Diarios"
                                ) {
                                  prod.lunes = 0;
                                  prod.martes = 0;
                                  prod.miercoles = 0;
                                  prod.jueves = 0;
                                  prod.viernes = 0;
                                  prod.sabado = 0;
                                  prod.domingo = 0;
                                }
                                let { suscripcionProductosListado } =
                                  this.props.suscripcion;
                                let ind = suscripcionProductosListado.findIndex(
                                  (e) => e.productoId === prod.productoId
                                );
                                suscripcionProductosListado[ind] = prod;
                                this.setState({
                                  suscripcionProductosListado,
                                });
                              }}
                            />
                          </div>
                          &nbsp; &nbsp;
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              background: "#F4F4F4",
                              width: "26px",
                              height: "26px",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              prod.cantidad++;
                              let { suscripcionProductosListado } =
                                this.props.suscripcion;
                              let ind = suscripcionProductosListado.findIndex(
                                (e) => e.productoId === prod.productoId
                              );
                              suscripcionProductosListado[ind] = prod;
                              this.setState({
                                suscripcionProductosListado,
                              });
                            }}
                          >
                            <ReactSVG
                              src={sumar}
                              style={{ color: "#8E95A5", width: "11px" }}
                            />
                          </div>
                          <div>
                            {/* Quitar edicion del carrito */}
                            <ReactSVG
                              src={eliminar}
                              style={{
                                margin: "3px 0px 3px 3px",
                                width: "23px",
                                height: "23px",
                                color: "#224372",
                                background: "#C7E6F9",
                                borderRadius: "50%",
                              }}
                              onClick={() => {
                                prod.cantidad = 0;
                                if (
                                  prod.cantidad === 0 &&
                                  prod.tipo === "Diarios"
                                ) {
                                  prod.lunes = 0;
                                  prod.martes = 0;
                                  prod.miercoles = 0;
                                  prod.jueves = 0;
                                  prod.viernes = 0;
                                  prod.sabado = 0;
                                  prod.domingo = 0;
                                }
                                let { suscripcionProductosListado } =
                                  this.props.suscripcion;

                                var index =
                                  suscripcionProductosListado.findIndex(
                                    (e) => e.productoId === prod.productoId
                                  );
                                suscripcionProductosListado[index] = prod;
                                this.setState({
                                  suscripcionProductosListado,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {this.props.suscripcion.suscripcionProductosListado.filter(
            (x) => x.cantidad > 0
          ).length > 0 && (
            <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
              <div className="d-flex justify-content-center align-items-center"
                    style={{
                      background: "#224372",
                      color: "white",
                      fontSize: "12px",
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: "16px",
                      width: "100px",
                      height: "33px",
                    }}
                    onClick={() => {
                      this.props.volverDeCarrito();
                    }}>
                  Volver
              </div>
              &nbsp;&nbsp;&nbsp;
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  background: "#224372",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "16px",
                  width: "170px",
                  height: "33px",
                }}
                onClick={() => {
                  var productos = this.getDescripcionProductoParaConfirmar();
                  let msj = `<div style="text-align: left; padding: 0px; ">
                                    <div style="color: #d33; font-family: roboto">Datos del Vendedor</div>        
                                    <ul style="padding-left: 0px; margin-left: 0px;">
                                        ${
                                          this.state
                                            .distribuidorsucursaltipo ===
                                          "SDDRA"
                                            ? `<li><span style="font-weight: bold">Linea: </span>${this.state.puntoventalinea}</li>`
                                            : ""
                                        }
                                        <li><span style="font-weight: bold">Paquete: </span>${
                                          this.state.puntoventapaquete
                                        }</li>
                                        <li><span style="font-weight: bold">Vendedor: </span>${
                                          this.state.puntoventavendedor
                                        }</li>
                                        <li><span style="font-weight: bold">Distribuidora: </span>${
                                          this.state.distribuidorsucursal
                                        }</li>
                                    </ul>
                                    <div style="color: #d33; font-family: roboto">Direcci&oacute;n</div>
                                    <ul style="padding: 0px; margin: 0px;">
                                        <li><span style="font-weight: bold">Código Postal: </span>${
                                          this.props.suscripcion.codigoPostal
                                        } </li>
                                        <li><span style="font-weight: bold">Provincia: </span>${
                                          this.props.suscripcion
                                            .provinciaDescripcion
                                        } </li>
                                        <li><span style="font-weight: bold">Ciudad: </span>${
                                          this.props.suscripcion
                                            .localidadDescripcion
                                        } </li>
                                        <li><span style="font-weight: bold">Calle: </span>${
                                          this.props.suscripcion.calle
                                        } ${this.props.suscripcion.calleNro} ${
                    this.props.suscripcion.piso &&
                    this.props.suscripcion.piso !== ""
                      ? ", Piso " + this.props.suscripcion.piso
                      : ""
                  }</li>
                                    </ul>
                                    <br/>
                                    <div style="color: #d33; font-family: roboto">Datos del Suscriptor</div>
                                    <ul style="padding: 0px; margin: 0px;">
                                        <li><span style="font-weight: bold">Nombre y apellido: </span>${
                                          this.props.suscripcion.nombre +
                                          " " +
                                          this.props.suscripcion.apellido
                                        }</li>
                                        <li><span style="font-weight: bold">Celular: </span>${
                                          this.props.suscripcion.telefonoNumero
                                        }</li>
                                        <li><span style="font-weight: bold">Email: </span>${
                                          this.props.suscripcion.email
                                        }</li>
                                        <li><span style="font-weight: bold">Lista de suscripciones con su frecuencia:</span></li>  
                                        ${productos}
                                    </ul>
                                </div>.`;

                  Swal.fire({
                    title:
                      '<span style="color: #224372; font-family: roboto; ">Chequear Datos de la Solicitud</span>',
                    html: msj,
                    padding: "2px",
                    // icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Modificar",
                    customClass: "swalIconCustom",
                    //reverseButtons: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.confirmarSuscripcion();
                    }
                    
                  });
                }}
              >
                Confirmar Solicitud
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
