import * as React from 'react';
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import Spinner from '../components/spinner'
import urlServer from '../server'
import telefono from '../assets/telefono.svg'
import eraser from '../assets/eraser.svg'
import ReactGA from 'react-ga';
import { validarVencimientoToken } from "../localStorageManager";

const DateInitFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, '$3/$2/$1 $4:$5:$6').slice(0, -14)} else { return "" } };

const DateFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} else { return "" } };

export default class Novedades extends React.Component {
    constructor(props){
      super(props);
      this.state={
        render:[],
        results: [],
        fecha: new Date().toISOString(),
        altasrows: [],
        bajasrows: [],
        modificacionesrows: [],
        temprows: [],
        filtroHoy: '',
        fechaFiltrada: "",
        loading: false,
        rows: [],
        accordion: false,
        postNovedades: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          fecha: new Date().toISOString().slice(0,10)
        },
        minDate: new Date(),
        tab: 'todos'
      }
    }
        
    reqNovedades = (date) => {
      ReactGA.event({
        category: 'Suscripciones/Novedades',
        action: 'Listar Novedades'
      });

      var fecha = new Date().toISOString().slice(0, -14)
      if(date)
      {
        fecha = date;
      }

      this.setState({
        loading: true
      })

      this.setState({
        fechaFiltrada: date ? DateFormatter(date) : DateInitFormatter(new Date().toISOString())
      })
      const data = {
        fecha,
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id
      }
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }

      fetch(urlServer + "/api/suscripciones/Novedades/Listado", {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(data),
        headers,
      })
      .then(response => {
        if (response.status === 200) {
          return response.text()
        } else {
          const error = new Error(response.status)
          return error
        }
      })
      .catch(error => {
        console.log('error', error)
        this.setState({
          loading: false
        })
      })
      .then(result => {

        let altasrows = []
        let bajasrows = []
        let modificacionesrows = []
        let res = JSON.parse(result);
        validarVencimientoToken(res.message);
        const rows = JSON.parse(result).map(row => {
          if (row.TipoNovedadABC === "A") {
            altasrows = [
              ...altasrows,
              row
            ]
          }
          if (row.TipoNovedadABC === "B") {
            bajasrows = [
              ...bajasrows,
              row
            ]
          }
          if (row.TipoNovedadABC === "C") {
            modificacionesrows = [
              ...modificacionesrows,
              row
            ]
          }
          return row
        })
        this.setState({
          rows,
          temprows: rows,
          altasrows,
          bajasrows,
          modificacionesrows,
          loading: false
        })
      })
      .catch(error => {
        console.log('error', error)
        validarVencimientoToken(error.message);
        this.setState({
          loading: false
        })
      });
    }
    
    setLinks = this.props.setLinks

    settingTab = (e) => {
      const flag = e.className.indexOf('active') !== -1 ? true : false
      let tablinks = document.getElementsByClassName("tablinks");
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      const button = e
      if (!flag) {
        button.className += " active"
      }
    }

    imprimirPDF = async () => {

      ReactGA.event({
        category: 'Suscripciones/Novedades',
        action: 'Exportar Novedades'
      });

      document.getElementById('open-modal').click()
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }
      const dataBody = {
        fecha: this.state.fechaFiltrada, 
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
        reporteTipo: 1,
      }
      const respuesta = await fetch(urlServer + "/api/suscripciones/Novedades/Listado", {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(dataBody),
        headers
      }).then((response) => {
          return response.arrayBuffer();
      })
        .catch((error) => {
            document.getElementById('close-modal').click();
            console.log('error', error);
        })
        .then((result) => {
            document.getElementById('close-modal').click();

            var resultcontent = result;
            var byteArray = new Uint8Array(resultcontent);
            const newBlob = new Blob([byteArray], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            if (window.__POIsNativeMode) {
                link.href = `blobDownload/Novedades-Suscripcion-${dataBody.fecha.replace('/', '-').replace('/', '-') }.pdf/${byteArray}`;
            } else {
                link.href = data;
                link.download = `Novedades-Suscripcion-${dataBody.fecha}.pdf`;
                setTimeout(function () {
                    window.URL.revokeObjectURL(data);
                }, 100);
            }
            link.click();
        })
      .catch(error => {
        document.getElementById('close-modal').click();
        console.log('error', error);
        validarVencimientoToken(error.message);
      })

      return await respuesta
    }

    componentDidMount() {
      this.reqNovedades()
    }

    componentWillUnmount() {
      this.props.hideMenu(true)
    }

    clearFilter = () => {
        this.setState(this.state.datevalue);
        this.setState({
          postNovedades: {
            ...this.state.postHojaRuta, 
            fecha: new Date().toISOString().slice(0,10)
          }
        })
    }

    fechaformateada = () => {
        var aux = this.state.fechaFiltrada.replace("/", ".");
        aux = aux.replace("/", ".");
        return aux;
    }

    render(){
      const { rows, loading, tab, altasrows, bajasrows } = this.state
      
      return ( <div id="Novedades" className="backapp container text-left">
                <Title 
                  title='Novedades'
                  accordion={this.state.accordion}
                  alterAccordion={() => {this.setState({accordion: !this.state.accordion});}}
                />


                <div id="filtro" className={"row overflow-hidden"}>
                  <div className={"filter d-flex justify-content-between overflow-hidden" + (this.state.accordion ? '  mt-0' : ' ')} >
                    <div style={{width: '50%'}} >
                      <div style={{marginBottom: '12px'}} >
                        Fecha
                      </div>
                      <div>
                          <input id="fecha" className="form-control filter-input" type="date"
                              value={this.state.postNovedades.fecha}
                              min={this.state.minDate}
                              placeholder={"dd/mm/yyyy"}
                              onChange={e => {
                              this.setState({
                                postNovedades: {
                                  ...this.state.postHojaRuta, 
                                  fecha: e.target.value
                                }
                              })
                            }} />
                      </div>
                    </div>
                    <div style={{width: '24%'}} >
                      <div className="eraser" onClick={() => this.clearFilter()}>
                        LIMPIAR
                        <ReactSVG src={eraser} style={{width: '16px'}} />
                      </div>
                      <div style={{width: '100%' }} className="btn button-theme " onClick={() => {this.reqNovedades(this.state.postNovedades.fecha)}}> Filtrar </div>
                    </div>
                  </div>
                </div>

                <div className="fecha-hoja-ruta fontWeight: '500'">
                    {this.state.fechaFiltrada}
                </div>

                <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#pdf-loader" style={{display: 'none'}}>
                </button>
                <div className="modal fade" id="pdf-loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content text-center">
                      <div className="modal-body">
                        <Spinner />
                      </div>
                      Descargando PDF...
                      <div className="modal-footer" style={{display: 'none'}}>
                        <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs d-flex justify-content-between w-100" style={{borderBottom: '1px solid gray'}}>
                    <div className="tablinks col-4 text-center active" onClick={(e) => {
                      this.setState({tab: 'todos'})
                      this.settingTab(e.target)
                    }}>Todas</div>
                    <div id="altas" className="tablinks col-4 text-center" onClick={(e) => {
                      this.setState({tab: 'altas'})
                      this.settingTab(e.target)
                    }}>Altas</div>
                    <div className="tablinks col-4 text-center" onClick={(e) => {
                      this.setState({tab: 'bajas'})
                      this.settingTab(e.target)
                    }}>Bajas</div>
                    
                </div>
                
                <div className="printers" style={{margin: '25px 0px'}}>
                  <div className="d-flex" onClick={() => {this.imprimirPDF()}}>
                    <div className="printer">
                      <span>
                        
                      </span>
                    </div>
                    <div className="printer-text">
                      <span>
                        Reporte de novedades
                      </span>
                    </div>
                  </div>
                </div>
                {loading ? 
                <Spinner style={{fontSize: '8px'}} />
                :
                <div className="cards" >
                  {rows.length > 0 ? 
                  (tab === 'todos' ? rows: tab === 'altas' ? altasrows : bajasrows).map((card, index) => {
                      return(
                        <div key={index} className="box" style={{color: "#8E95A5"}} >
                          <div className="d-flex justify-content-between" >
                            <div className="f-16" style={{color: '#EA3F3F', fontFamily: 'Roboto', fontWeight: 'bold'}} >
                              {card.ProductoCombinacionDescripcion}
                            </div>
                            <div className="f-13-5"  style={{fontWeight: '500'}}>
                              {DateFormatter(card.FechaDeSalida).slice(0, -9)}
                            </div>
                          </div>
                          <div className="desc-novedades" style={{marginBottom: '5px'}}>	  
                            {card.CantidadLunes ?
                             <span className="novedadesvalor">
                              LU {card.CantidadLunes}
                            </span>
                            : ""}
                            {card.CantidadMartes ?
                             <span className="novedadesvalor">
                              MA {card.CantidadMartes}
                            </span>
                            : ""}
                            {card.CantidadMiercoles ?
                             <span className="novedadesvalor">
                              MI {card.CantidadMiercoles}
                            </span>
                            : ""}
                            {card.CantidadJueves ?
                            <span className="novedadesvalor">
                              JU {card.CantidadJueves}
                            </span>
                            : ""}
                            {card.CantidadViernes ?
                              <span className="novedadesvalor">
                              VI {card.CantidadViernes}
                            </span>
                            : ""}
                            {card.CantidadSabado ?
                              <span className="novedadesvalor">
                              SA {card.CantidadSabado}
                            </span>
                            : ""}
                            {card.CantidadDomingo ?
                              <span className="novedadesvalor">
                              DO {card.CantidadDomingo}
                            </span>
                            : ""}
                          </div>
                          <div className="d-flex justify-content-between" >
                            <div className="w-100 f-10">
                              <span className="f-13-5" style={{color: '#224372', fontWeight: '500'}} >
                                {card.Nombre.toUpperCase() + " " + card.Apellido.toUpperCase()}
                              </span>
                            </div>
                            {card.Telefono ?
                            <div className="telefono">
                              <a className="d-flex" href={"tel:" + card.suscriptorTelefono}>
                                <ReactSVG style={{marginRight: '5px', height: '10px', width: '10px'}} src={telefono} />
                                <span className="ml-1 novedadesvalor">
                                {card.Telefono}
                                </span>
                              </a>
                            </div>
                            : null}
                          </div>
                          <div className="desc-novedades">
                            DIR:
                            <span className="ml-1 novedadesvalor">
                              {card.DomicilioCalle} {" " + card.DomicilioAltura} {card.DomicilioPiso ? " P: " + card.DomicilioPiso + "º" : ""} {card.DomicilioDepartamentoLocal ? " Dto: " + card.DomicilioDepartamentoLocal : ""} <span className="f-9"> {card.DomicilioEntreCalle1 && card.DomicilioEntreCalle2 ? "(Entre " + card.DomicilioEntreCalle1 + " y " + card.DomicilioEntreCalle2 + ")" : ""}</span>
                            </span>
                          </div>
                          
                          <div className="d-flex" >
                             <span className="novedadesvalor">
                                {card.Tipo}
                              </span>
                          </div>
                        </div>)

                  }) 
                  : <div className="not-found" >
                    No se encontraron novedades para la fecha {this.state.fechaFiltrada}
                  </div>}
                </div>
                }
              </div>
        )
    }
}