import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { Title } from '../components/title'
import Spinner from '../components/spinner';
import urlServer from '../server'
import Scanner from '../components/Scanner';
import ReactGA from 'react-ga';
import pedidos from '../assets/pedidos.svg';
import { ReactSVG } from 'react-svg';
import CarritoPedidoDistribuidora from '../components/carritopedidodistribuidora';
import { validarVencimientoToken } from "../localStorageManager";

export default class Publicaciones extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            publicaciones: [],
            busqueda: '',
            loading: false,
            producto: null,
            redirect: false,
            scanning: false,
            timeoutSearchId: 0,
            AgregarPedidoOpen: false,
            isTienda: this.props.props.location.pathname.includes("Tienda"),
            nroElementosCarrito: 0,
            seleccionadasFlag: false

        }
    }

    timeoutSearch = (string) => {
        if(this.state.timeoutSearchId) {
            clearInterval(this.state.timeoutSearchId)
        }
        this.setState({
            timeoutSearchId: setTimeout(() => this.reqPublicaciones(string), 1000)
        })
    }

    reqPublicaciones = async (string) => {

        if(!this.state.isTienda)
        {
            ReactGA.event({
                category: 'Pedidos',
                action: 'Listar Publicaciones'
              });
        }

        this.setState({
            loading: true
        })
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        if(string) {
            const data = {
                palabraABuscar: string,
                filtrarProductoNoEditorial: true,
                ocultarClases:"3,4,5"

            }
            const url = urlServer + "/api/producto/buscarPublicaciones"
    
            const respuesta = await fetch(url, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            })
            .then(response => response.json())
            .then(result => {
                validarVencimientoToken(result.message);
                this.setState({
                    publicaciones: result,
                    loading: false
                })
            })
            .catch(error => {
                validarVencimientoToken(error.message);
                console.log('error', error)
                this.setState({
                    loading: false
                })
            });
            return respuesta
        } else {
            const data = {
                puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
                fechaDesde: new Date((new Date()).setDate((new Date()).getDate() - 90)).toISOString().slice(0,10),
                fechaHasta: new Date().toISOString().slice(0,10),
                pageIndex: 1,
                pageSize: 12,
            }
            const url = urlServer + "/api/producto/maspedidos/buscar"

            const respuesta = await fetch(url, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            })
            .then(response => response.json())
            .then(result => {
                validarVencimientoToken(result.message);
                this.setState({
                    publicaciones: result,
                    loading: false
                })
            })
            .catch(error => {
               
                console.log('error', error)
                validarVencimientoToken(error.message);
                this.setState({
                    loading: false
                })
            });
            return respuesta
        }
    }

    setEdiciones = (result) => {
      this.setState({
        loading: true
      })
      if (result.length !== 0) {
        result = result.map(edicion => {
          edicion.cantidad = 0
          return edicion
        })
        this.setState({
          ediciones: result,
        })
      }
      this.setState({
        loading: false
      })
      return result
    }

    closeModal = () => {
        if(this._isMounted) {
            this.setState({
              scanning: false
            })
        } 
    }

    publicaciones = () => {
        if(this.state.isTienda){
            return <Redirect push to={{
                pathname: '/Tienda/CargarPedido',
                state: {
                    producto: {"CC":true}
                }
            }} />
        }
        if(this.state.AgregarPedidoOpen) {
            return <Redirect push to={{
                pathname: '/Pedidos/CargarPedido',
                state: {
                producto: this.state.producto,
            }
            }} />
        }
    }

    onDetectedCode = async (ean) => {
        if(this._isMounted) {
            await this.setState({
                ean
            })
            const data = await this.ediciones(ean)

            if(data.length === 1) {
                await this.setState({producto: data[0]});
                this.setState({AgregarPedidoOpen: true})
            } else {
                this.setState({
                    publicaciones: data
                })
            }
        }
    }

    ediciones = async (ean) => {
        this.setState({
            loading: true
        })
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        const data = {
            ean: ean,
            columnaParaOrdenar: "DESCRIPCION DESC",
            puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
        }

        const url = urlServer + "/api/producto/ean/listar"

        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
        .then(response => response.json())
        .then(result => {
            validarVencimientoToken(result.message);
            let res = result
           
            this.setState({
                loading: false
            })
            return res
        })
        .catch(error => {
            console.log('error', error)
            validarVencimientoToken(error.message);
            this.setState({
                loading: false
            })
        });
        return respuesta
    }

    componentDidMount() {
        this._isMounted = true
        document.title = "Cargar Pedidos"
        if(!this.state.isTienda)
            this.reqPublicaciones('')
        this.carritoObtener();
    }

    componentWillUnmount() {
        this._isMounted = false
        this.setState({
            scanning: false,
        })
    }

    carritoObtener = () => {
        const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'
        var filtro = {
            usuarioId: null
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(filtro),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
           if (this._isMounted) {  // Solo actualiza si el componente sigue montado
            this.setState({ nroElementosCarrito: result.length });
        }
        }).catch((error) => {
            console.log('Error al obtener elementos del carrito, ', error);
            validarVencimientoToken(error.message);
        });
    }

    volverDeCarrito = () => {
        this.setState({seleccionadasFlag: false});
        this.props.hideMenu(false);
        this.carritoObtener();
    }

    render() {
        const { loading, publicaciones,redirect, seleccionadasFlag } = this.state

        return (
                <React.Fragment>
                    {seleccionadasFlag ? 
                        <CarritoPedidoDistribuidora volverDeCarrito = {this.volverDeCarrito} />
                    :
                    <div id='Publicaciones' className="container text-left">
                        <div className='d-flex justify-content-between'>
                            <Title
                                classes=""
                                title='Cargar Pedidos'
                            />
                            <div className="position-relative" style={{marginTop: '53px'}}
                            // quitar eventos del carrito
                            // onClick={() => {
                            //     this.setState({seleccionadasFlag: true});
                            //     this.props.hideMenu(true);

                            // }}
                            >
                                <ReactSVG src={pedidos} style={{width: '27px', height: '27px', color: '#8E95A5'}} />
                                <div className="position-absolute d-flex justify-content-center align-items-center f-11" 
                                     style={{right: '-8px', 
                                             top: '-8px', 
                                             background: '#EA3F3F', 
                                             color: 'white', 
                                             borderRadius: '50%', 
                                             width: '16px', 
                                             height: '16px'
                                     }}>
                                    { this.state.nroElementosCarrito }
                                </div>
                            </div>
                        </div>
                        
                        {this.state.scanning && <Scanner onDetectedCode={this.onDetectedCode} closeModal={this.closeModal} setEdiciones={this.setEdiciones} />}
                        <div className="mb-3">
                            Filtro por código de barras 
                            <div id="botonvisible" className="btn button-theme ml-3" onClick={async () => {
                            
                            ReactGA.event({
                                category: 'Pedidos',
                                action: 'Escanear Codigo de Barra'
                                });

                            await this.setState({
                                scanning: true
                            })
                            const button = document.getElementById('botonbarras')
                            button.click()
                            }}>
                            Escanear
                            </div>
                            <button id="botonbarras" type="button" className="btn btn-primary d-none" data-toggle="modal"  data-target="#codigodebarras">
                            Escanear
                            </button>
                        </div>
                        {redirect ? <Redirect push to={{
                            pathname: '/Pedidos'
                        }} /> : null}
                        <div className="w-100 ">
                            <input className="w-100 form-control" type="text" placeholder="Publicación"
                                value={this.state.busquedaTextoMostrarEnInput}
                                onChange={e => {
                                    this.setState({
                                        busqueda: e.target.value.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace(/\./g, ''),
                                        busquedaTextoMostrarEnInput: e.target.value
                                    })
                                    this.timeoutSearch(e.target.value.normalize('NFC').replace(/[^\w\sñ]/gi, c => c.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace(/\./g, '')))
                            }}/>
                             
                        </div>
                        <div className="text-center f-16 fw-400" style={{marginTop: '22px'}}>
                            Elija una publicación para cargar un pedido
                        </div>
                        {this.publicaciones()}
                        {loading ? 
                        <Spinner style={{fontSize: '8px'}} />
                        :
                        <div className="cards" style={{
                            paddingTop: '20px', // padding con seccion destacados
                            marginTop: '0px',
                            display: 'grid',
                            gridGap: '8px',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                            justifyItems: 'center'
                        }}>
                        {publicaciones.length > 0 && publicaciones.map((card, index) => {
                            
                            return  <div key={index} 
                                         className="card-estilo d-flex justify-content-center align-items-center" 
                                         onClick={async () => {await this.setState({producto: card}); this.setState({AgregarPedidoOpen: true})}}>
                                        <div className="d-flex justify-content-center align-items-center text-center" style={{color: 'white'}}>
                                            {card.descripcion}
                                        </div>
                                    </div>
                        })}
                        </div>}
                    </div>
                    }

                    { !seleccionadasFlag && this.state.nroElementosCarrito > 0 ? 
                    <div className="d-flex justify-content-center align-items-center barra-enviar">
                        <div className="d-flex justify-content-center align-items-center" 
                             style={{background: "#224372",
                                     color: "white",
                                     fontSize: "12px",
                                     textAlign: "center",
                                     cursor: "pointer",
                                     borderRadius: "16px",
                                     width: "105px",
                                     height: "33px"
                                    }} 
                             onClick={() => {
                                        this.setState({seleccionadasFlag: true});
                                        this.props.hideMenu(true);
                                    }}>
                            Ver Carrito
                        </div>
                    </div>
                    :null }

                </React.Fragment>
                )
    }
}